import { Text, Title, Icon } from "components";
import { Button } from "twComponents/Button";
import { useLocale } from "helpers/translations/src/useLocale";
import { InputSelect, InputSelectOption } from "components/Common/Field/Select";
import { ChangeEvent, useState } from "react";
import { isMobile } from "react-device-detect";
import { useGetCurrentBusinessUnit } from "hooks";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { Switch } from "twComponents/Switch";
import { Label } from "@radix-ui/react-label";
import { Tooltip } from "components";
import { cn } from "utils/misc";

type Props = {
  onSubscribe: (tier: SubscriptionTierIdUK | SubscriptionTierIdUS) => void;
  onContactUs: () => void;
  hasError: boolean;
};

export const SubscriptionTiers = ({ onSubscribe, onContactUs, hasError }: Props) => {
  const { terms } = useLocale();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>("monthly");
  const { countryCode } = useGetCurrentBusinessUnit();
  const isUS = countryCode.toUpperCase() === CountryISOCode.US;
  const [selectedTier, setSelectedTier] = useState<SubscriptionTierIdUK | SubscriptionTierIdUS>(
    isUS ? "us-tier50-monthly" : "uk-tier120-monthly",
  );

  const countrySubscriptionTiers = isUS
    ? subscriptionTiersUS[subscriptionPeriod]
    : subscriptionTiersUK[subscriptionPeriod];

  const subscriptionTierOptions: InputSelectOption[] = countrySubscriptionTiers.map(
    (
      tier:
        | typeof subscriptionTiersUK[typeof subscriptionPeriod][number]
        | typeof subscriptionTiersUS[typeof subscriptionPeriod][number],
    ) => {
      return {
        value: tier.id,
        label:
          tier.id === "enterprise" ? `${tier.animalRanges} animals` : `${tier.animalRanges} animals, ${tier.price}`,
        key: tier.id,
      };
    },
  );

  const onContactUsUS = () => {
    window.location.href = "mailto:supportusa@breedr.co";
  };

  const handleChangeSubscriptionInterval = (checked: boolean) => {
    if (isUS) {
      setSubscriptionPeriod(checked ? "yearly" : "monthly");
      setSelectedTier(checked ? "us-tier300-yearly" : "us-tier50-monthly");
    } else {
      setSubscriptionPeriod(checked ? "yearly" : "monthly");
      setSelectedTier(checked ? "uk-tier120-yearly" : "uk-tier120-monthly");
    }
  };

  const trialPeriod = isUS ? "7" : "30";

  return (
    <>
      <div className="bg-gray-100 rounded-lg p-4">
        <div className="bg-gray-100 rounded-lg">
          <Title tertiary>Choose Your Subscription</Title>
          <Text smaller className="pt-2">{`Please choose the tier that best suits your ${terms.farm}. ${
            isUS ? "" : "(prices exclude VAT)"
          }`}</Text>
        </div>
        <Text smaller className="py-2 font-semibold">
          How would you like to pay?
        </Text>
        <div className="flex items-center space-x-2 py-2">
          <Label
            htmlFor="subscription-period-option"
            className={cn(subscriptionPeriod === "monthly" ? "font-semibold" : "inherit", "text-sm min-w-[60px]")}
          >
            Monthly
          </Label>
          <Switch id="subscription-period-option" onCheckedChange={handleChangeSubscriptionInterval} />
          <Label
            htmlFor="subscription-period-option"
            className={cn(subscriptionPeriod === "yearly" ? "font-semibold" : "inherit", "text-sm min-w-[45px]")}
          >
            Yearly
          </Label>
          <span
            className={cn(
              "bg-green-300 py-1 px-2 rounded-full text-xs font-medium",
              subscriptionPeriod === "yearly" ? "opacity-100" : "opacity-50",
            )}
          >
            {`${isUS ? 17 : 15}% off`}
          </span>
        </div>

        <>
          <div className="flex flex-col xl:flex-row my-2">
            <div className="min-w-[300px]">
              <InputSelect
                name="tier"
                placeholder="Choose your tier"
                value={selectedTier}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  setSelectedTier(event.target.value as SubscriptionTierIdUK | SubscriptionTierIdUS);
                }}
                options={subscriptionTierOptions}
              />
            </div>
            <div className="w-5 h-2" />
            {selectedTier === "enterprise" ? (
              <Button variant="outline" onClick={isUS ? onContactUsUS : onContactUs} size={isMobile ? "lg" : "sm"}>
                Contact us
              </Button>
            ) : (
              <Button onClick={() => onSubscribe(selectedTier)} disabled={hasError} size={isMobile ? "lg" : "sm"}>
                Subscribe
              </Button>
            )}
          </div>
          <div className="flex pt-2">
            <Tooltip
              title={`Choose a tier and enter your credit card to activate the ${trialPeriod} day trial.`}
              placement="bottom"
            >
              <Icon name="info" size="tiny" className="mr-2" />
            </Tooltip>
            <Text smallest>{trialPeriod}-day free trial included for first time subscribers</Text>
          </div>
        </>
      </div>
    </>
  );
};

export type SubscriptionTierIdUK = typeof subscriptionTiersUK["yearly" | "monthly"][number]["id"];
export type SubscriptionTierIdUS = typeof subscriptionTiersUS["yearly" | "monthly"][number]["id"];
export type SubscriptionPeriod = keyof typeof subscriptionTiersUK | keyof typeof subscriptionTiersUS;

const subscriptionTiersUK = {
  monthly: [
    { animalRanges: "Up to 120", price: "£16.49 / month", id: "uk-tier120-monthly" },
    { animalRanges: "Up to 220", price: "£23.49 / month", id: "uk-tier220-monthly" },
    { animalRanges: "Up to 330", price: "£29.49 / month", id: "uk-tier330-monthly" },
    { animalRanges: "Up to 400", price: "£33.99 / month", id: "uk-tier400-monthly" },
    { animalRanges: "Up to 1000", price: "£37.99 / month", id: "uk-tier1000-monthly" },
    { animalRanges: "Up to 2000", price: "£41.99 / month", id: "uk-tier2000-monthly" },
    { animalRanges: "2000+", id: "enterprise" },
  ],
  yearly: [
    { animalRanges: "Up to 120", price: "£168.20 / year", id: "uk-tier120-yearly" },
    { animalRanges: "Up to 220", price: "£239.60 / year", id: "uk-tier220-yearly" },
    { animalRanges: "Up to 330", price: "£300.80 / year", id: "uk-tier330-yearly" },
    { animalRanges: "Up to 400", price: "£346.70 / year", id: "uk-tier400-yearly" },
    { animalRanges: "Up to 1000", price: "£387.50 / year", id: "uk-tier1000-yearly" },
    { animalRanges: "Up to 2000", price: "£428.30 / year", id: "uk-tier2000-yearly" },
    { animalRanges: "2000+", id: "enterprise" },
  ],
} as const;

const subscriptionTiersUS = {
  monthly: [
    { animalRanges: "Up to 50", price: "$2.00 / month", id: "us-tier50-monthly" },
    { animalRanges: "Up to 300", price: "$35.00 / month", id: "us-tier300-monthly" },
    { animalRanges: "Up to 1000", price: "$95.00 / month", id: "us-tier1000-monthly" },
    { animalRanges: "Up to 2000", price: "$235.00 / month", id: "us-tier2000-monthly" },
    { animalRanges: "2000+", id: "enterprise" },
  ],
  yearly: [
    { animalRanges: "Up to 300", price: "$348.00 / year", id: "us-tier300-yearly" },
    { animalRanges: "Up to 1000", price: "$948.00 / year", id: "us-tier1000-yearly" },
    { animalRanges: "Up to 2000", price: "$2,340.00 / year", id: "us-tier2000-yearly" },
    { animalRanges: "2000+", id: "enterprise" },
  ],
} as const;
