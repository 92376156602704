import { gql } from "@apollo/client";
import { stockItemFragment, lineItemFragment } from "./fragment";

export const GET_STOCK_MEDICINE_ITEM = gql`
  query GetStockItem($id: UUID!, $businessUnitId: Int!) {
    stockItem(id: $id, businessUnitId: $businessUnitId) {
      ...stockItem
    }
  }
  ${stockItemFragment}
`;

export const GET_MEDICINE_ITEMS = gql`
  query LineItems($businessUnitId: Int!) {
    lineItems(businessUnitId: $businessUnitId) {
      ...lineItem
    }
  }
  ${lineItemFragment}
`;

export const GET_MEDICINE_ACTIVITIES = gql`
  query GetMedicineActivities(
    $businessUnitId: Int!
    $stockItemId: UUID!
    $batchNumber: String
    $first: Int
    $after: String
  ) {
    itemTransactions(
      businessUnitId: $businessUnitId
      stockItemId: $stockItemId
      batchNumber: $batchNumber
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          date
          batchNumber
          valueDescription
          shortDescription
          longDescription
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_INVENTORY_INFO = gql`
  query GetInventoryInfo($businessUnitId: Int!) {
    inventoryInfo(businessUnitId: $businessUnitId) {
      id
      activeIngredients
      batchNumber
      name
      stockItemId
      totalOriginalVolume
      totalRemainingVolume
      volumeUnits
      withdrawalNotes
      isArchived
    }
  }
`;
