import { FC } from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_GROUPS_COUNT } from "groups/api/queries";
import { getAnimalViewBreadCrumbs, getShortBreedrId } from "helpers/myLivestock";
import { getBUFromLocalStorage } from "helpers/storage";
import { useGetAnimalIdPreferences, useHasFeature, useStateSpecies } from "hooks";
import {
  ANIMALS,
  ANIMAL_VIEW,
  ANIMAL_VIEW_PERFORMANCE,
  ANIMAL_VIEW_HISTORY,
  ANIMAL_VIEW_OFFSPRING,
  ANIMAL_FAMILY_TREE,
  BU_ID,
  ANIMAL_GENETICS,
} from "constants/Routes";
import { AnimalInfoBlock, AnimalOffspring, AnimalPerformanceBlock as PerformanceBlock } from "components/MyLivestock";
import AnimalChart from "pages/MyLivestock/Animals/AnimalChart";
import AnimalChartHistoryBlock from "pages/MyLivestock/Animals/AnimalChartHistoryBlock";
import { Breadcrumbs, Flex, LoadingOverlay, Header, PageHeader, PageContent, Spacer, Tabs } from "components";
import styles from "./AnimalView.module.scss";
import { IdentifierEnumType, useAnimalHistoryLazyQuery, useGetAnimalByIdQuery } from "generated/graphql";
import { GetAnimalByIdQuery } from "generated/graphql";
import { convertSnakeToCamelCase, identifierNameLookup } from "components/Settings/IdPreferencesForm/helpers";
import { useLocale } from "helpers/translations/src";
import { AnimalFamilyTree } from "animals/AnimalFamilyTree";
import { SubTab } from "components/Common/Tabs";
import { Genetics } from "animals/Genetics";
import { AnimalHistory } from "animals/AnimalsHistory";

export type GQLAnimal = GetAnimalByIdQuery["animal"];

interface RouteParams {
  fromPage: string;
  id: string;
  tab: string;
}

type AnimalViewProps = RouteComponentProps<RouteParams>;

interface GroupsCountProps {
  groupsExtended: {
    groupsCount: number;
  };
}

function getCurrentTabId(tabName: string): number {
  switch (tabName) {
    case "history":
      return 1;
    case "offspring":
      return 2;
    case "genetics":
      return 3;
    case "family-tree":
      return 4;
    default:
      return 0;
  }
}

const AnimalView: FC<AnimalViewProps> = ({ match }) => {
  const { activeSpecies } = useStateSpecies();
  const [getAnimalHistory] = useAnimalHistoryLazyQuery();

  const isUseActivityChartQuery: boolean = useHasFeature("USE_ACTIVITY_CHART_QUERY"); /////FOGGLE CAN PROBABLY GO
  const familyTreeEnabled = useHasFeature("SHOW_FAMILY_TREE");
  const useGenetics = useHasFeature("GENETICS");

  const { activeIdPreferences, primaryId } = useGetAnimalIdPreferences();
  const { terms } = useLocale();

  const {
    params: { fromPage, id, tab },
    url,
  } = match;

  const {
    data: animalData,
    loading,
    error,
  } = useGetAnimalByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { data: groupsExtendedData } = useQuery(GET_GROUPS_COUNT, {
    variables: {
      businessUnitId: getBUFromLocalStorage(),
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const { groupsExtended }: GroupsCountProps = {
    ...groupsExtendedData,
  };
  const { groupsCount: numberOfGroups } = {
    ...groupsExtended,
  };
  const { animal } = {
    ...animalData,
  };

  const isArchived = !!animal?.deadAt || !!animal?.dateLeftFarm;

  const ANIMAL_VIEW_SUBTABS = [
    {
      key: 0,
      name: "Performance",
      route: `${ANIMAL_VIEW_PERFORMANCE.replace(":id", id).replace(":fromPage", fromPage)}`,
    },
    {
      key: 1,
      name: "History",
      route: `${ANIMAL_VIEW_HISTORY.replace(":id", id).replace(":fromPage", fromPage)}`,
    },
    {
      key: 2,
      name: "Offspring",
      route: `${ANIMAL_VIEW_OFFSPRING.replace(":id", id).replace(":fromPage", fromPage)}`,
    },
    useGenetics
      ? {
          key: 3,
          name: "Genetics",
          route: `${ANIMAL_GENETICS.replace(":id", id).replace(":fromPage", fromPage)}`,
        }
      : null,
    familyTreeEnabled
      ? {
          key: 4,
          name: "Pedigree",
          route: `${ANIMAL_FAMILY_TREE.replace(":id", id).replace(":fromPage", fromPage)}`,
        }
      : null,
  ].filter(Boolean) as SubTab[];

  if (error) return <Redirect to={ANIMALS} />;

  if (!animal) {
    if (loading) return <LoadingOverlay customStyles={styles.loadingOverlay} />;

    return <Redirect to={ANIMALS} />;
  }

  const primaryIdValue =
    primaryId === IdentifierEnumType.BreedrId
      ? getShortBreedrId(animal["breedrId"])
      : animal[convertSnakeToCamelCase(primaryId)];
  const primaryIdLabel = identifierNameLookup(terms, convertSnakeToCamelCase(primaryId));

  if (activeSpecies) {
    const isAnimalDifferentSpecies = animal.animalType?.id !== activeSpecies.id;
    if (isAnimalDifferentSpecies) return <Redirect to={ANIMALS} />;
  }

  const handleMouseOver = () => {
    getAnimalHistory({
      variables: {
        first: 5,
        animal: animal.id,
      },
    });
  };

  return (
    <>
      <PageHeader>
        <Breadcrumbs rootPaths={getAnimalViewBreadCrumbs(url)} currentPath="Animal Information" />

        <Header backButton title={`${primaryIdValue ? primaryIdValue : `No ${primaryIdLabel}`}`} />

        <AnimalInfoBlock
          animal={animal}
          canAddToGroup={numberOfGroups > 0}
          isArchived={isArchived}
          activeIdPreferences={activeIdPreferences}
          terms={terms}
        />

        <Tabs tabs={ANIMAL_VIEW_SUBTABS} selectedTab={getCurrentTabId(tab)} onMouseOver={handleMouseOver} />
      </PageHeader>

      <PageContent>
        {isUseActivityChartQuery ? (
          <AnimalChartHistoryBlock />
        ) : (
          <Flex container className={styles.animal_view__container}>
            <Flex item itemGutter xs="fill">
              <Spacer baselineHeight={3} />

              <Switch>
                <Route
                  exact
                  path={`${BU_ID}${ANIMAL_VIEW_PERFORMANCE}`}
                  render={(): JSX.Element => (
                    <>
                      <PerformanceBlock animal={animal} isArchived={isArchived} />
                      <Spacer baselineHeight={3} />
                      <AnimalChart isArchived={isArchived} />
                    </>
                  )}
                />
                <Route path={`${BU_ID}${ANIMAL_VIEW_HISTORY}`} render={() => <AnimalHistory animalId={animal.id} />} />
                <Route
                  path={`${BU_ID}${ANIMAL_VIEW_OFFSPRING}`}
                  render={(): JSX.Element => <AnimalOffspring animalId={animal.id} />}
                />
                {useGenetics ? (
                  <Route
                    path={`${BU_ID}${ANIMAL_GENETICS}`}
                    render={(): JSX.Element => <Genetics animalId={animal.id} />}
                  />
                ) : null}
                {familyTreeEnabled ? (
                  <Route
                    path={`${BU_ID}${ANIMAL_FAMILY_TREE}`}
                    render={(): JSX.Element => <AnimalFamilyTree animalId={animal.id} />}
                  />
                ) : null}
                <Redirect exact from={`${BU_ID}${ANIMAL_VIEW}`} to={`${BU_ID}${ANIMAL_VIEW_PERFORMANCE}`} />
              </Switch>

              <Spacer baselineHeight={3} />
            </Flex>
          </Flex>
        )}
      </PageContent>
    </>
  );
};

export default AnimalView;
