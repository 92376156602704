import { useContactsQuery } from "generated/graphql";
import { getBUFromLocalStorage } from "helpers/storage";
import { removeNothings } from "helpers/general";
import { InputSelectOption } from "components/Common/Field/Select";

export const useContacts = (contactType?: string) => {
  const businessUnitIdInt = getBUFromLocalStorage();

  const { data: contactsData, ...rest } = useContactsQuery({
    variables: { businessUnit: businessUnitIdInt, contactType },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const contacts = removeNothings(contactsData?.contacts || []);

  const contactOptions: InputSelectOption[] = contacts.map((contact) => {
    return {
      value: contact.id,
      label: contact.organisationName || contact.name || "Unknown contact name",
      key: contact.id,
    };
  });

  const findContact = (contactId: string | undefined) => {
    if (!contactId) {
      return null;
    }

    return contacts.find((contact) => contact.id === contactId) || null;
  };

  return { findContact, contacts, contactOptions, ...rest };
};
