import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { GetAnimalOffspring_animalOffspring_animals } from "api/MyLivestock/Animal/types/GetAnimalOffspring";
import { TABLE_PAGINATION_DEFAULT_OPTIONS } from "components/Common/Table/TablePagination";
import { getOffspringColumns } from "./helpers";
import { useAnimalOffspring, useFormatDate, useGetCurrentBusinessUnit } from "hooks";
import { Flex, LoadingOverlay, Pill, Spacer, Table, TablePaginationTarget, Title } from "components";
import { EmptyStatesOffspringTab } from "components/EmptyStates/OffspringTab";
import { EventName, logAnalyticsEvent } from "utils/analytics";

interface AnimalOffspringProps {
  animalId: string;
}

export const AnimalOffspring: React.FC<AnimalOffspringProps> = ({ animalId }) => {
  const { loading, offFarmAnimals, onFarmAnimals, summary } = useAnimalOffspring(animalId);
  const { id: businessUnitId, weightUnits } = useGetCurrentBusinessUnit();
  const { getFormatDate } = useFormatDate();
  const history = useHistory();

  const handleAnimalClick = (animal: GetAnimalOffspring_animalOffspring_animals): void => {
    return history.push(`/system/livestock/animal/${animal.id}`);
  };

  const onFarmTableId = "animalOffspringOnFarmTable";
  const offFarmTableId = "animalOffspringOffFarmTable";

  const memoizedOnFarmAnimals = useMemo(() => onFarmAnimals, [onFarmAnimals]);
  const memoizedOffFarmAnimals = useMemo(() => offFarmAnimals, [offFarmAnimals]);
  const columnsOffFarm = useMemo(
    () => getOffspringColumns(weightUnits, getFormatDate),
    [offFarmAnimals],
  ) as Column<GetAnimalOffspring_animalOffspring_animals>[];
  const columnsOnFarm = useMemo(
    () => getOffspringColumns(weightUnits, getFormatDate, handleAnimalClick),
    [onFarmAnimals],
  ) as Column<GetAnimalOffspring_animalOffspring_animals>[];

  useEffect(() => {
    logAnalyticsEvent(EventName.ViewAnimalOffspring, {
      animalId,
      businessUnitId,
    });
  }, []);

  return (
    <>
      {loading ? <LoadingOverlay /> : null}

      {!loading ? (
        <>
          {summary?.animalsCount ? <Title secondary>{summary?.animalsCount} Offspring</Title> : null}
          <Spacer baselineHeight={1} />

          {!summary?.animalsCount ? <EmptyStatesOffspringTab /> : null}

          {!!summary?.animalsCount ? (
            <>
              <Flex container>
                <Flex item container>
                  {!!summary?.maleCount ? (
                    <Flex item>
                      <Pill
                        colour="navy"
                        caption={`${summary?.maleCount} male${summary?.maleCount !== 1 ? "s" : ""}`}
                        colourInverted
                        icon="animalSexMale"
                      />
                    </Flex>
                  ) : null}

                  {!!summary?.femaleCount ? (
                    <Flex item itemGutter={!!summary?.maleCount}>
                      <Pill
                        colour="navy"
                        caption={`${summary?.femaleCount} female${summary?.femaleCount !== 1 ? "s" : ""}`}
                        colourInverted
                        icon="animalSexFemale"
                      />
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>

              {memoizedOnFarmAnimals.length > 0 ? (
                <>
                  <Spacer baselineHeight={3} />

                  <Flex container>
                    <Flex item>
                      <Title tertiary>My farm</Title>
                    </Flex>
                    <Flex item itemGrow />
                    <Flex item>
                      <TablePaginationTarget tableId={onFarmTableId} />
                    </Flex>
                  </Flex>
                  <Spacer baselineHeight={1} />
                  <Table<GetAnimalOffspring_animalOffspring_animals>
                    columns={columnsOnFarm}
                    data={memoizedOnFarmAnimals as GetAnimalOffspring_animalOffspring_animals[]}
                    initialPageSize={TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[0]}
                    pagination
                    tableId={onFarmTableId}
                  />
                </>
              ) : null}

              {memoizedOffFarmAnimals.length > 0 ? (
                <>
                  <Spacer baselineHeight={4} />

                  <Flex container>
                    <Flex item>
                      <Title tertiary>Other farms</Title>
                    </Flex>
                    <Flex item itemGrow />
                    <Flex item>
                      <TablePaginationTarget tableId={offFarmTableId} />
                    </Flex>
                  </Flex>
                  <Spacer baselineHeight={1} />
                  <Table<GetAnimalOffspring_animalOffspring_animals>
                    columns={columnsOffFarm}
                    data={memoizedOffFarmAnimals as GetAnimalOffspring_animalOffspring_animals[]}
                    initialPageSize={TABLE_PAGINATION_DEFAULT_OPTIONS.SIZES[0]}
                    pagination
                    tableId={offFarmTableId}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
