import { useHistory } from "react-router-dom";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { AnimalFragment, AnimalStatus, useFinancialTransactionAnimalsLazyQuery } from "generated/graphql";
import { AnimalsList } from "animals/AnimalsList";
import { Table } from "twComponents/Table";
import { useAnimalsColumns } from "hooks/useAnimalsColumns";
import { EmptyState } from "components/EmptyStates/Default";
import { useFormContext } from "react-hook-form";
import { AnimalTableActions } from "../AnimalTableActions";
import { useLazyPaginatedQuery } from "hooks/useLazyPaginatedQuery";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { removeNothings } from "helpers/general/all";
import { useState } from "react";

interface Props {
  transactionId: string | undefined;
  isEditMode: boolean;
  isNewTransaction: boolean;
}

export type TableType = "transactionAnimalsTable" | "allAnimalsTable";

export const TransactionAnimalsTable = ({ transactionId, isNewTransaction, isEditMode }: Props) => {
  const history = useHistory();
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("TransactionAnimalsTable must be used inside a <Form /> or <FormProvider />");
  }

  const [tableType, setTableType] = useState<TableType>("transactionAnimalsTable");

  const [getTransactionAnimals, { loading: isLoadingTransactionAnimals, data: transactionAnimalsData }] =
    useFinancialTransactionAnimalsLazyQuery();

  const totalNumberOfItems = transactionAnimalsData?.financialTransaction?.animalsCount || 0;

  const { paginationProps, queryVariables, setQueryVariables } = useLazyPaginatedQuery({
    lazyQuery: getTransactionAnimals,
    initialQueryVariables: transactionId ? { businessUnit: +businessUnitId, id: transactionId } : undefined,
    tableId: "financial_transaction_animals_table",
    totalNumberOfItems,
    pageInfo: transactionAnimalsData?.financialTransaction?.transactionAnimals?.pageInfo,
    loading: isLoadingTransactionAnimals,
  });

  const { columns, visibilityRules } = useAnimalsColumns({ isArchive: false });

  const handleRowClick = (animal: AnimalFragment) => {
    history.push(`/system/livestock/animal/${animal.id}`);
  };

  const transactionAnimals = removeNothings(
    transactionAnimalsData?.financialTransaction?.transactionAnimals?.edges.map((edge) => edge?.node?.animal) || [],
  );

  const animalsCount = methods.watch("animalsCount") || 0;

  return (
    <div>
      <div className="flex justify-between items-center px-2">
        <div>
          {methods.formState.errors.animalsCount?.message ? (
            <p className="text-status-warning text-xs">{methods.formState?.errors?.animalsCount?.message}</p>
          ) : null}
        </div>
        <AnimalTableActions
          queryVariables={queryVariables}
          setQueryVariables={setQueryVariables}
          isNewTransaction={isNewTransaction}
          isEditMode={isEditMode}
          setAnimalsTableType={setTableType}
          animalsTableType={tableType}
        />
      </div>
      {tableType === "allAnimalsTable" ? (
        <AnimalsList
          tableId={"financial_transaction_all_animals_table"}
          showSelectColumn
          tableFilterIdentifier="animalsList"
          showAnimalActionButtons={false}
          showIndividualAnimalActionButtons={false}
          animalStatus={AnimalStatus.Any}
          resetSelectedAnimalsOnUnmount={false}
          animalTypeIds={[2, 3]} // Get both sheep and cattle
        />
      ) : (
        <div className="px-2 mt-2">
          <Table
            tableId="financial_transaction_animals_table"
            options={{ data: transactionAnimals, columns, getRowId: (row) => row.id, enableSorting: false }}
            columnVisibility={visibilityRules}
            loading={isLoadingTransactionAnimals}
            showSelectColumn={isEditMode && animalsCount > 0}
            onRowClick={isEditMode ? undefined : handleRowClick}
            paginationProps={paginationProps}
            emptyStateComponent={
              <EmptyState
                title="You haven't added any animals yet."
                description="Add animals by clicking 'Add animals' above."
              />
            }
          />
        </div>
      )}
    </div>
  );
};
