import { InputSelectOption } from "components/Common/Field/Select";
import { useGetGroupsListQuery, GetGroupsListQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { getBUFromLocalStorage } from "helpers/storage";
import { useMemo } from "react";
import { useStateSpecies } from "./useStateSpecies";
import { sortBy } from "lodash";

interface Input {
  forAllSpecies?: boolean;
}

export type GroupType = NonNullable<
  NonNullable<NonNullable<NonNullable<GetGroupsListQuery>["groupsExtended"]>["groups"]>[0]
>;

export const useGetGroups = ({ forAllSpecies }: Input = {}) => {
  const { activeSpecies } = useStateSpecies();

  const { data, ...rest } = useGetGroupsListQuery({
    variables: {
      businessUnitId: getBUFromLocalStorage(),
      animalTypeIds: !forAllSpecies && activeSpecies ? [+activeSpecies.id] : null,
    },
    fetchPolicy: "cache-and-network",
  });

  const groups = useMemo(() => removeNothings(data?.groupsExtended?.groups || []), [data?.groupsExtended?.groups]);

  const groupOptions: InputSelectOption[] = useMemo(() => {
    const options = groups.map((group) => {
      return {
        value: group.id,
        label: group.name,
        key: group.id,
      };
    });

    return sortBy(options, [(option): string => option.label]);
  }, [groups]);

  return {
    groupOptions,
    groups,
    data,
    ...rest,
  };
};
