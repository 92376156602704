// React
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
// Utils
import { FIELD_VIEW } from "constants/Routes";
import { TABLE_IDS } from "constants/Interface";
// Hooks
import { useGetCurrentBusinessUnit } from "hooks";
import { useLocale } from "helpers/translations/src/useLocale";
// Components
import { Flex } from "components/Structure/Flex";
import { Table } from "components/Common/Table";
import { Tooltip } from "components/Common/Tooltip";
import { Column } from "react-table";
// Types
import { GetField_field } from "api/MyLivestock/Fields/types/GetField";
import { FieldsQuery } from "generated/graphql";
import { Icon } from "components/Common/Icon";
import { IconLabel } from "components/Common/IconLabel";
// Resources
import styles from "./styles.module.scss";
import { Button } from "components/Common/Button";
import { getTableSettingsFromLocalStorage } from "helpers/storage";
import { ColumnOptionsModal } from "components/Common/Table/ColumnOptionsModal";
import { TablePaginationTarget } from "components";
import { capitaliseFirstLetter } from "helpers/translations/src";
import { BaseInput } from "components/Common/Field/BaseInput";

const COLUMN_IDS = {
  NAME: "name",
  TYPE: "fieldType",
  ANIMALS_NUMBER: "animalsNumber",
  MALES_NUMBER: "malesNumber",
  FEMALES_NUMBER: "femalesNumber",
  UNIT_NAME: "unitName",
  CPH: "cph",
  DENSITY: "density",
  AVG_ESTIMATED_WEIGHT: "avgEstimatedWeight",
  AVG_AGE: "avgAge",
  AVG_DLWG: "avgDlwg",
  DESCRIPTION: "description",
  ALERTS: "alerts",
};

interface FieldsTableProps {
  fields: Array<NonNullable<FieldsQuery["fields"]>[number]>;
  loading?: boolean;
}

const filterFieldsData = (
  data: Array<NonNullable<FieldsQuery["fields"]>[number]>,
  searchTerm: string,
): Array<NonNullable<FieldsQuery["fields"]>[number]> => {
  const isMatch = (value: string): boolean => value?.toLowerCase().includes(searchTerm.toLowerCase());

  return data.filter((field) => isMatch(field.name));
};

export const FieldsTable: React.FC<FieldsTableProps> = ({ fields, loading }) => {
  const { weightUnits, countryCode } = useGetCurrentBusinessUnit();
  const history = useHistory();
  const { terms } = useLocale();
  const tableId = TABLE_IDS.FIELDS;
  const [columnOptionsModalOpen, setColumnOptionsModalOpen] = useState(false);
  const localStorageTableSettings = getTableSettingsFromLocalStorage(tableId) || [];
  const currentLocalStorageColumns = localStorageTableSettings?.columns || [];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const filteredFields: Array<NonNullable<FieldsQuery["fields"]>[number]> = useMemo(
    () => filterFieldsData(fields, searchTerm),
    [fields, searchTerm],
  );
  const tableData: Array<NonNullable<FieldsQuery["fields"]>[number]> = useMemo(() => filteredFields, [filteredFields]);

  const handleClickCell = useCallback(
    (field: GetField_field) => {
      history.push(`${FIELD_VIEW}/${field?.id}`);
    },
    [history],
  );

  const columns: Column<NonNullable<FieldsQuery["fields"]>[number]>[] = useMemo(
    () => [
      {
        id: COLUMN_IDS.NAME,
        Header: "Location Name",
        accessor: "name",
        onCellClick: handleClickCell,
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.TYPE,
        Header: "Type",
        accessor: "isYard",
        Cell: ({ row }): React.ReactElement => {
          const typeValue = row.values[COLUMN_IDS.TYPE];
          const isOrganic = row.original.isOrganic;
          return (
            <div className={styles.fieldTypeCell}>
              <div>
                <IconLabel
                  id={`field_type_icon${row.index}`}
                  colour={typeValue ? "yellow" : "green"}
                  icon={typeValue ? "yard" : "field"}
                  label={capitaliseFirstLetter(typeValue ? terms.pen : terms.field)}
                />
              </div>
              {isOrganic ? (
                <div>
                  <Tooltip
                    title={"Is Organic"}
                    classes={{
                      tooltip: styles.tooltip,
                      popper: styles.tooltipPopper,
                    }}
                    placement="bottom"
                  >
                    <Icon className={styles.field_type__organic_icon} colour="green_dark" name="organic" size="small" />
                  </Tooltip>
                </div>
              ) : null}
            </div>
          );
        },
        onCellClick: handleClickCell,
        minWidth: 120,
        width: 130,
      },
      {
        id: COLUMN_IDS.ANIMALS_NUMBER,
        Header: "No. of Livestock",
        accessor: ({ summaryMetrics }): number | null => {
          if (!summaryMetrics) {
            return null;
          }
          let totalAnimals = 0;
          for (const item of summaryMetrics) {
            if (item && item.animalsCount) {
              totalAnimals += item.animalsCount;
            }
          }
          return totalAnimals;
        },
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.MALES_NUMBER,
        Header: "Male",
        accessor: ({ summaryMetrics }): number | null => {
          if (!summaryMetrics) {
            return null;
          }
          let totalMaleCount = 0;
          for (const item of summaryMetrics) {
            if (item && item.maleCount) {
              totalMaleCount += item.maleCount;
            }
          }
          return totalMaleCount;
        },
        Cell: ({ row }): React.ReactElement => {
          return (
            <IconLabel
              id={`animal_sex_label__${row.index}`}
              colour={"blue_light"}
              icon={"animalSexBull"}
              label={row.values[COLUMN_IDS.MALES_NUMBER]}
            />
          );
        },
        onCellClick: handleClickCell,
        minWidth: 60,
        width: 70,
      },
      {
        id: COLUMN_IDS.FEMALES_NUMBER,
        Header: "Female",
        accessor: ({ summaryMetrics }): number | null => {
          if (!summaryMetrics) {
            return null;
          }
          let totalFemaleCount = 0;
          for (const item of summaryMetrics) {
            if (item && item.femaleCount) {
              totalFemaleCount += item.femaleCount;
            }
          }
          return totalFemaleCount;
        },
        Cell: ({ row }): React.ReactElement => {
          return (
            <IconLabel
              id={`animal_sex_label__${row.index}`}
              colour={"red_light"}
              icon={"animalSexFemale"}
              label={row.values[COLUMN_IDS.FEMALES_NUMBER]}
            />
          );
        },
        onCellClick: handleClickCell,
        minWidth: 60,
        width: 80,
      },
      {
        id: COLUMN_IDS.UNIT_NAME,
        Header: "Unit Name",
        accessor: ({ location }): string | null | undefined => (location ? location.unitName : undefined),
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.CPH,
        Header: terms.farmId,
        accessor: ({ location }): string | null | undefined => (location ? location.locationId : undefined),
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.DENSITY,
        Header: "Density",
        accessor: ({ fieldDensity, sizeUnit }): string | null =>
          `${fieldDensity ? Number(fieldDensity).toFixed(1) : 0} ${sizeUnit}`,
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.AVG_ESTIMATED_WEIGHT,
        Header: "Avg. Est. Weight",
        accessor: ({ avgEstimatedWeight }): string | null =>
          (avgEstimatedWeight ? Number(avgEstimatedWeight).toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.AVG_AGE,
        Header: "Avg. Age",
        accessor: ({ avgAge }): string | null => (avgAge ? avgAge : 0) + " months",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.AVG_DLWG,
        Header: capitaliseFirstLetter(terms.avgDlwg),
        accessor: ({ avgDlwg }): string | null => (avgDlwg ? Number(avgDlwg).toFixed(1) : 0) + weightUnits || "\u2014",
        onCellClick: handleClickCell,
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.DESCRIPTION,
        Header: "Description",
        accessor: "description",
        onCellClick: handleClickCell,
        Cell: ({ row }): React.ReactElement | null => {
          const description = row.values[COLUMN_IDS.DESCRIPTION];
          const descriptionRef = useRef<HTMLDivElement>(null);
          const [showTooltip, setShowTooltip] = useState(false);
          useEffect(() => {
            setShowTooltip(
              !!descriptionRef.current && descriptionRef.current.scrollWidth > descriptionRef.current.clientWidth,
            );
          }, [descriptionRef]);
          if (!description) return <span>-</span>;
          return (
            <Tooltip
              title={description}
              classes={{
                tooltip: styles.tooltip,
                popper: styles.tooltipPopper,
              }}
              placement="bottom"
              disableHoverListener={!showTooltip}
            >
              <div id={`description__${row.index}`} className={styles.description} ref={descriptionRef}>
                {description}
              </div>
            </Tooltip>
          );
        },
        minWidth: 100,
        width: 130,
      },
      {
        id: COLUMN_IDS.ALERTS,
        Header: "Alerts",
        accessor: ({ animalAlertsCount }): number => animalAlertsCount || 0,
        Cell: ({ row }): React.ReactElement => {
          const alertsCount = row.values[COLUMN_IDS.ALERTS];
          return (
            <IconLabel
              id={`field_alert_icon__${row.index}`}
              colour={alertsCount > 0 ? "warning" : "grey_light"}
              icon={alertsCount > 0 ? "alertsHigh" : "alertsNone"}
              label={alertsCount}
            />
          );
        },
        minWidth: 100,
        width: 100,
      },
    ],
    [handleClickCell, terms, weightUnits],
  );

  const reorderColumns = (
    columns: Column<NonNullable<FieldsQuery["fields"]>[number]>[],
    columnOrder: { id: string; index: number; checked: boolean }[],
  ): Column<NonNullable<FieldsQuery["fields"]>[number]>[] => {
    const sortedColumnOrder = columnOrder.sort((a, b) => a.index - b.index);
    const columnMap: { [id: string]: Column<NonNullable<FieldsQuery["fields"]>[number]> } = {};
    columns.forEach((column) => {
      columnMap[column.id || 0] = column;
    });
    const reorderedColumns: Column<NonNullable<FieldsQuery["fields"]>[number]>[] = [];
    sortedColumnOrder.forEach((column) => {
      if (column.checked) {
        const columnObject = columnMap[column.id];
        if (columnObject) {
          reorderedColumns.push(columnObject);
        }
      }
    });
    return reorderedColumns;
  };

  const defaultColumns =
    currentLocalStorageColumns.length > 0 ? reorderColumns(columns, currentLocalStorageColumns) : columns;
  const [customColumns, setCustomColumns] =
    useState<Column<NonNullable<FieldsQuery["fields"]>[number]>[]>(defaultColumns);
  const [customColumnTrigger, setCustomColumnTrigger] = useState<number>(0);
  useEffect(() => {
    setCustomColumns(
      currentLocalStorageColumns.length > 0 ? reorderColumns(columns, currentLocalStorageColumns) : columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumnTrigger]);

  const hiddenColumnsByCountry = {
    us: ["avgEstimatedWeight"],
  };

  const hiddenColumns = countryCode && hiddenColumnsByCountry[countryCode] ? hiddenColumnsByCountry[countryCode] : [];

  const defaultColumnIds = Object.values(COLUMN_IDS).filter((id) => !hiddenColumns.includes(id));

  return (
    <>
      <Flex container>
        <Flex item itemGutter xs={12} m={6} l={3} className={styles.search_field_input}>
          <BaseInput
            name="fields_search"
            onChange={handleSearchInputChange}
            placeholder="Search by location name"
            type="search"
            value={searchTerm}
          />
        </Flex>
        <div className="flex justify-end flex-1 flex-wrap mb-2">
          <Flex item itemGutter>
            <TablePaginationTarget tableId={tableId} />
          </Flex>
          <Flex item itemGutter>
            <Button
              caption="Columns"
              onClick={() => setColumnOptionsModalOpen(true)}
              colour="grey"
              variant="hollow"
              icon={{
                position: "right",
                props: {
                  name: "column",
                  size: "small",
                },
              }}
            />
          </Flex>
        </div>
      </Flex>
      <Flex container containerDirection="column">
        <Flex item itemGutter>
          <Table
            loading={loading}
            columns={customColumns}
            hiddenColumns={hiddenColumns}
            data={tableData}
            tableId={tableId}
            pagination
            initialPageSize={10}
          />
        </Flex>
      </Flex>
      <ColumnOptionsModal
        columns={columns}
        hiddenColumns={hiddenColumns}
        isShowModal={columnOptionsModalOpen}
        onClose={() => setColumnOptionsModalOpen(false)}
        setCustomColumnnTrigger={setCustomColumnTrigger}
        defaultColumnIds={defaultColumnIds}
        tableId={tableId}
      />
    </>
  );
};
