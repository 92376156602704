import { DeliveryDirectionEnum, DeliveryFragment } from "generated/graphql";
import { useGetCurrentBusinessUnit } from "hooks";
import { formatDate, getInternationalDateFormat } from "feShared/utils/dates";
import { DateTime } from "luxon";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { useCallback } from "react";
import { useContacts } from "settings/hooks/useContacts";

export const useDeliveriesLabels = () => {
  const { countryCode, countryIsoCodeUpperCase } = useGetCurrentBusinessUnit();

  const { shortDateFormat } = getInternationalDateFormat(countryCode);
  const { findContact, loading: loadingContacts } = useContacts();

  const getLocationsLabel = (delivery: DeliveryFragment | undefined) => {
    if (!delivery) {
      return "—";
    }

    const moveOnLocations =
      delivery?.sourceSummary?.locations && delivery?.sourceSummary?.locations?.length > 0
        ? delivery?.sourceSummary?.locations
        : delivery?.defaultSource
        ? [delivery?.defaultSource]
        : [];

    const moveOffLocations =
      delivery?.destinationSummary?.locations && delivery?.destinationSummary?.locations?.length > 0
        ? delivery?.destinationSummary?.locations
        : delivery?.defaultDestination
        ? [delivery?.defaultDestination]
        : [];

    const locations = delivery?.direction === DeliveryDirectionEnum.Inward ? moveOnLocations : moveOffLocations;

    if (locations.length === 0) {
      return "—";
    }

    if (locations.length === 1) {
      const contactId = locations?.[0]?.contact?.id;

      if (contactId) {
        const contact = findContact(contactId);
        return contact?.organisationName || contact?.name || "—";
      } else {
        const partnerAddressName = locations[0]?.address?.name || locations[0]?.details?.name;
        return partnerAddressName || "—";
      }
    }

    if (locations.length > 1) {
      return "Multiple";
    }

    return "—";
  };

  const getDeliveryDateLabel = (delivery: DeliveryFragment | undefined) => {
    if (!delivery) {
      return "—";
    }
    const isMoveOff = delivery.direction === DeliveryDirectionEnum.Outward;

    const deliveryDate = isMoveOff
      ? formatDate(delivery?.sentAt, shortDateFormat) || "—"
      : formatDate(delivery?.arrivedAt || delivery?.sentAt, shortDateFormat) || "—";

    return deliveryDate;
  };

  const getDeliveryNameLabel = useCallback(
    (delivery: DeliveryFragment | undefined) => {
      if (!delivery?.id) {
        return DateTime.local().toFormat(
          countryIsoCodeUpperCase === CountryISOCode.US ? "MM/dd/yyyy HH:mm:ss" : "dd/MM/yyyy HH:mm:ss",
        );
      }

      const movedAt = delivery?.sentAt || delivery?.arrivedAt;

      const deliveryDateLabel = movedAt
        ? DateTime.fromISO(movedAt).toFormat(
            countryIsoCodeUpperCase === CountryISOCode.US ? "MM/dd/yyyy HH:mm:ss" : "dd/MM/yyyy HH:mm:ss",
          )
        : undefined;

      return delivery?.name ? delivery.name : deliveryDateLabel || "—";
    },
    [countryIsoCodeUpperCase],
  );

  return { getLocationsLabel, loadingContacts, getDeliveryDateLabel, getDeliveryNameLabel };
};
