import { Button, FieldSelect, InputCheckbox, Paper, Spacer } from "components";
import { CategoryFilters } from "./CategoryFilters";
import { useGetCategoryListings, useGetCurrentBusinessUnit, useListingFilters } from "hooks";
import { ListingFiltersInstance } from "trading/hooks/useListingFilters";
import React, { ChangeEvent, useEffect } from "react";
import styles from "./ListingFilters.module.scss";
import { stringifyJsonSafely } from "helpers/general";
import { EventName } from "utils/events";
import { logAnalyticsEvent } from "utils/analytics";

interface ListingFiltersProps {
  instance: ListingFiltersInstance;
}

export const ListingFilters: React.FC<ListingFiltersProps> = ({ instance }) => {
  const { set, isDefault, reset: handleReset, values: filters } = useListingFilters(instance);
  const distanceOptions = useDistanceOptions();
  const { animalTypes, categoriesFalse, categoriesTrue } = useGetCategoryListings();
  useEffect(() => {
    set({ category: { ...filters.category, checkBoxes: categoriesFalse } });
  }, []);

  const handleChangeCheckbox = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
    set({
      [name]: checked,
    });
  };

  const handleChangeAllCategoryCheckbox = ({ target: { checked } }: ChangeEvent<HTMLInputElement>): void => {
    if (checked) {
      const categoryIds = Object.keys(categoriesTrue);
      logAnalyticsEvent(EventName.ListingFilterCategory, {
        categoriesOfSale: stringifyJsonSafely(categoryIds),
      });
    }
    set({
      category: {
        allUnchecked: !checked,
        showAll: checked,
        checkBoxCount: 0,
        checkBoxes: checked ? categoriesTrue : categoriesFalse,
      },
    });
  };

  const handleChangeCategoryCheckbox = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
    if (filters.category.showAll) return;

    let checkBoxCount = filters.category.checkBoxCount;
    checked ? (checkBoxCount += 1) : (checkBoxCount -= 1);
    const allUnchecked = checkBoxCount === 0;

    const checkBoxes = { ...filters.category.checkBoxes, [name]: checked };

    const categoriesOfSale = Object.entries(checkBoxes).reduce((acc: string[], cur) => {
      const [categoryId, isChecked] = cur;
      if (isChecked) {
        acc.push(categoryId);
      }
      return acc;
    }, []);

    if (categoriesOfSale.length > 0) {
      logAnalyticsEvent(EventName.ListingFilterCategory, {
        categoriesOfSale: stringifyJsonSafely(categoriesOfSale),
      });
    }

    set({
      category: {
        ...filters.category,
        allUnchecked,
        checkBoxCount,
        checkBoxes,
      },
    });
  };

  const handleChangeDistance = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement>): void => {
    set({
      [name]: value,
    });
  };

  const handleChangeSpecies = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement>): void => {
    set({
      [name]: value,
      category: { ...filters.category, allUnchecked: true, checkBoxes: categoriesFalse },
    });
  };

  const speciesSelectOptions = animalTypes.reduce(
    (acc, animalType) => {
      acc.push({
        label: animalType.name,
        key: +animalType.id,
        value: animalType.name,
      });
      return acc;
    },
    [{ key: 0, label: "All Species", value: "All Species" }],
  );

  const speciesCategoryOptions =
    filters.speciesSelect !== "All Species"
      ? animalTypes.filter((animalType) => animalType.name === filters.speciesSelect)
      : animalTypes;

  return (
    <Paper className={styles.listing_filters}>
      <Spacer baselineHeight={2} />

      {instance === "buyer" ? (
        <FieldSelect
          label="Distance"
          inputProps={{
            labelKey: "label",
            name: "distance",
            onChange: handleChangeDistance,
            options: distanceOptions,
            value: filters.distance,
            valueKey: "value",
          }}
        />
      ) : null}

      <FieldSelect
        label="Species"
        inputProps={{
          onChange: handleChangeSpecies,
          name: "speciesSelect",
          options: speciesSelectOptions,
          value: filters.speciesSelect,
        }}
      />
      <Spacer baselineHeight={2} />

      {instance === "buyer" && animalTypes.length >= 0 ? (
        <>
          <Spacer border="top" baselineHeight={2} />
          <InputCheckbox
            key="Input"
            name="showAll"
            label="All Categories"
            onChange={handleChangeAllCategoryCheckbox}
            checked={filters.category.showAll}
          />
          <Spacer baselineHeight={1} />
          <CategoryFilters
            animalTypes={speciesCategoryOptions}
            filters={filters}
            handleChangeCategoryCheckbox={handleChangeCategoryCheckbox}
          />
        </>
      ) : null}

      <Spacer border={instance === "buyer" ? "top" : undefined} baselineHeight={2} />

      <InputCheckbox
        checked={filters.hideAgreedSoldExpired}
        label="Hide Agreed/Sold/Expired"
        name="hideAgreedSoldExpired"
        onChange={handleChangeCheckbox}
      />

      <Spacer baselineHeight={2} />

      <Button caption="Reset filters" disabled={isDefault} onClick={handleReset} variant="ghost" />

      <Spacer baselineHeight={2} />
    </Paper>
  );
};

type DistanceOption = { label: string; value: number; eventName };

function useDistanceOptions() {
  const businessUnit = useGetCurrentBusinessUnit();

  return React.useMemo(() => {
    const DEFAULT_DISTANCE_OPTIONS: Array<DistanceOption> = [
      {
        label: "Any",
        value: Infinity,
        eventName: "listing-filter-any-distance",
      },
      {
        label: "50",
        value: 50,
        eventName: "listing-filter-small-distance",
      },
      {
        label: "100",
        value: 100,
        eventName: "listing-filter-med-distance",
      },
      {
        label: "200",
        value: 200,
        eventName: "listing-filter-large-distance",
      },
    ];

    if (businessUnit == null) {
      return DEFAULT_DISTANCE_OPTIONS;
    }

    const unitOfMeasurement = businessUnit?.unitsOfMeasurement?.find(
      (unit) => unit?.measurementType === "DISTANCE_LARGE",
    );

    if (unitOfMeasurement == null) {
      return DEFAULT_DISTANCE_OPTIONS;
    }

    const distanceOptionsWithLabel = [
      DEFAULT_DISTANCE_OPTIONS[0],
      ...DEFAULT_DISTANCE_OPTIONS.slice(1, 4).map((option) => ({
        ...option,
        label: `${option.label} ${unitOfMeasurement.code.toLocaleLowerCase()}`,
      })),
    ];

    return distanceOptionsWithLabel;
  }, [businessUnit]);
}
