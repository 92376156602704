import { AnimalHistoryQueryVariables } from "generated/graphql";
import { Button } from "twComponents/Button";
import { X } from "lucide-react";

export const RemoveFiltersButton = ({
  animalHistoryQueryVariables,
  setAnimalHistoryQueryVariables,
}: {
  animalHistoryQueryVariables: AnimalHistoryQueryVariables;
  setAnimalHistoryQueryVariables: React.Dispatch<React.SetStateAction<AnimalHistoryQueryVariables>>;
}) => {
  const handleRemoveFilters = () => {
    setAnimalHistoryQueryVariables({
      ...animalHistoryQueryVariables,
      activityTypes: undefined,
      afterDatetime: undefined,
      beforeDatetime: undefined,
    });
  };

  if (
    (Array.isArray(animalHistoryQueryVariables.activityTypes) &&
      animalHistoryQueryVariables.activityTypes.length > 0) ||
    animalHistoryQueryVariables.afterDatetime ||
    animalHistoryQueryVariables.beforeDatetime
  ) {
    return (
      <Button onClick={handleRemoveFilters} variant="tertiary" size="md">
        Remove Filters
        <X size={16} strokeWidth={4} className="ml-2" />
      </Button>
    );
  }

  return null;
};
