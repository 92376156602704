import { FC } from "react";
import { useParams } from "react-router-dom";
import { BU_TYPES } from "trading/constants";
import { getBUFromLocalStorage, getBUTypeFromLocalStorage, getTokenFromLocalStorage } from "helpers/storage";
import { useGetBusinessUnits, useHasFeature } from "hooks";
import { DashboardRoutes } from "./routes";
import DashboardWatcher from "pages/Dashboard/DashboardWatcher";
import { ReportsMenu, SettingsMenu } from "pages";
import IntercomComponent from "pages/Intercom/IntercomComponent";
import { DashboardHeader, DashboardMenu } from "containers/dashboard";
import { Root, Page } from "components";
import LoadingOverlay from "components/Common/LoadingOverlay";
import { FarmerReportsMenu } from "reports/components/FarmerReportsMenu";
import { Paywall } from "subscriptions/components/Paywall";
import { AsyncJobStatusMenuExport } from "animals/AsyncJobStatusMenuExport";
import { BetaBanner } from "deliveries/components/BetaBanner";

export const Dashboard: FC = () => {
  const { sectionName, buId } = useParams<DashboardPageParams>();

  const { data: businessUnits } = useGetBusinessUnits();
  const token = getTokenFromLocalStorage();
  const selectedBU = getBUFromLocalStorage();
  const selectedBUType = getBUTypeFromLocalStorage();
  const isLoading = Boolean(selectedBU && businessUnits && businessUnits.length > 0);
  const isSettingsMode: boolean = sectionName === "settings";
  const isReportsMode: boolean = sectionName === "reports";
  const isFarmerReportsMode = sectionName === "reports-new";
  const isShowOfflineReportPage: boolean = useHasFeature("SHOW_OFFLINE_PAGE_FOR_REPORTS");
  let pageMenu: false | JSX.Element = false;
  pageMenu = isReportsMode
    ? !isShowOfflineReportPage && <ReportsMenu />
    : isSettingsMode && <SettingsMenu farmer={selectedBUType !== BU_TYPES.processor} />;
  if (isFarmerReportsMode) {
    pageMenu = <FarmerReportsMenu />;
  }

  return (
    <>
      {(isLoading && !(buId && +buId !== selectedBU)) || !token ? (
        <Root menu={<DashboardMenu />}>
          <BetaBanner>
            <Page menu={pageMenu}>
              <Paywall />
              <DashboardHeader />
              <DashboardRoutes />
              <AsyncJobStatusMenuExport />
            </Page>
            <IntercomComponent />
          </BetaBanner>
        </Root>
      ) : (
        <LoadingOverlay />
      )}
      <DashboardWatcher />
    </>
  );
};
