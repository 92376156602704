import { isKilos, isLitres } from "../utils";
import { getBatchUnit } from "../getBatchUnit";
import { InventoryInfoType } from "generated/graphql";

/**
 * @description returns the default unit for unit field based on known exceptions
 * @param batch the batch object
 */
export const getDefaultUnit = (batch: InventoryInfoType): string => {
  const unit = getBatchUnit(batch);

  if (isLitres(unit)) return "ml";
  if (isKilos(unit)) return "g";

  return unit;
};
