import { useDeleteActivityMutation } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { Button } from "twComponents/Button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "twComponents/AlertDialog";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";

export const ConfirmDeleteHistoryEventModal = ({
  showDeleteHistoryEventModal,
  setShowDeleteHistoryEventModal,
  activityId,
  animalId,
}: {
  showDeleteHistoryEventModal: boolean;
  setShowDeleteHistoryEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  activityId: string | undefined;
  animalId: string;
}) => {
  const { handleError, handleMutationResult } = useHandleMutationResult();
  const [deleteActivity, { loading: deleteLoading }] = useDeleteActivityMutation();
  const { id: businessUnitId } = useGetCurrentBusinessUnit();

  const handleDeleteActivity = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await deleteActivity({
        variables: {
          input: {
            businessUnit: +businessUnitId,
            activities: [activityId],
            animals: [animalId],
          },
        },
        refetchQueries: ["AnimalHistory"],
      });
      const errors = removeNothings(response.data?.deleteActivityBulk?.errors || []);

      handleMutationResult({
        successMessage: "Activity successfully deleted.",
        errors,
        onSuccess: () => {
          setShowDeleteHistoryEventModal(false);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };
  return (
    <AlertDialog open={showDeleteHistoryEventModal}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete this activity? This cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <form className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2" onSubmit={handleDeleteActivity}>
          <AlertDialogCancel disabled={deleteLoading} onClick={() => setShowDeleteHistoryEventModal(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button type="submit" loading={deleteLoading}>
              Delete
            </Button>
          </AlertDialogAction>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
};
