import { getFormattedDate } from "helpers/general/all";
import { DateTime, LocaleOptions } from "luxon";
import { AnimalHistoryEventFragment, SourceObjectFragmentFragment } from "generated/graphql";
import { FinancialTransactionAnimal } from "./HistorySection";

export const partitionAnimalHistoryByDate = (events: AnimalHistoryEventFragment[], locale: string) => {
  return events.reduce<Record<string, typeof events>>((eventsByDate, event) => {
    if (event.sourceObject && isType<FinancialTransactionAnimal>(event.sourceObject, "FinancialTransactionAnimal")) {
      const date = getFormattedDate(
        event.sourceObject.financialTransaction.date,
        DateTime.DATE_HUGE,
        locale as LocaleOptions,
      );

      return {
        ...eventsByDate,
        [date]: eventsByDate[date] ? [...eventsByDate[date], event] : [event],
      };
    } else {
      const date = getFormattedDate(event.happenedAt, DateTime.DATE_HUGE, locale as LocaleOptions);

      return {
        ...eventsByDate,
        [date]: eventsByDate[date] ? [...eventsByDate[date], event] : [event],
      };
    }
  }, {});
};

export const isType = <T extends SourceObjectFragmentFragment>(
  obj: SourceObjectFragmentFragment,
  typename: T["__typename"],
): obj is T => {
  return obj.__typename === typename;
};
