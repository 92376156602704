import { TABLE_IDS } from "constants/Interface";
import { AnimalFragment, AnimalStatus } from "generated/graphql";
import { CSSProperties } from "react";
import { Row } from "react-table";
import { TableFilterIdentifier } from "state/animalFiltersSlice";

export interface Props {
  animalTypeIds?: number[];
  animalIds?: string[];
  onPressEditAnimal?: (animal: AnimalFragment) => void;
  animalStatus?: AnimalStatus;
  tableFilterIdentifier: TableFilterIdentifier;
  fromPage?: "field" | "group" | "livestock" | "tags";
  rowStyles?: (row: Row<AnimalFragment>) => CSSProperties;
  isArchive?: boolean;
  isCSVErrors?: boolean;
  groupId?: string;
  fieldId?: string;
  tagId?: string;
  showSelectColumn: boolean;
  showAnimalActionButtons: boolean;
  showIndividualAnimalActionButtons?: boolean;
  defaultToSelectedAnimalsView?: boolean;
  showFiltersButton?: boolean;
  showExportButton?: boolean;
  showPagination?: boolean;
  hasOnAnimalClick?: boolean;
  backButtonLabel?: string;
  excludedFilters?: {
    groupId?: boolean;
    fieldId?: boolean;
    tagId?: boolean;
  };
  noResults?: JSX.Element;
  tableId: typeof TABLE_IDS[keyof typeof TABLE_IDS];
  resetSelectedAnimalsOnUnmount?: boolean;
}

export enum PregnancyStatus {
  PREGNANT = "pregnant",
  NOTPREGNANT = "notPregnant",
}

export enum Sex {
  MALE = "M",
  FEMALE = "F",
}

export interface Filters {
  fieldId: string;
  groupId: string;
  tagIds: string[];
  sex: string;
  pregnancyStatus: string;
  dateOfBirth: {
    from: Date | undefined;
    to: Date | undefined;
  };
  dateOnFarm: {
    from: Date | undefined;
    to: Date | undefined;
  };
}
