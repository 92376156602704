import { yupResolver } from "@hookform/resolvers/yup";
import {
  getTransactionValidationSchema,
  TransactionValidationSchema as S,
  dateRange,
  singleDate,
} from "financial/components/TransactionForm/validation";
import {
  TransactionRecordEnumType,
  FinancialTransactionState,
  useFinancialTransactionLazyQuery,
} from "generated/graphql";
import { DateTime } from "luxon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useForm } from "react-hook-form";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useTransactionActions } from "financial/components/TransactionForm/hooks/useTransactionActions";
import { useHasFeature } from "hooks/useHasFeature";

export const useTransactionForm = (transactionId: string | undefined) => {
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const { handleCreateTransaction, isCreatingTransaction } = useTransactionActions();
  const { handleError } = useHandleMutationResult();
  const [fetchFinancialTransaction, { loading: isLoadingFinancialTransaction }] = useFinancialTransactionLazyQuery({
    variables: { businessUnit: +businessUnitId, id: transactionId },
  });

  const isOshkosh = useHasFeature("OSHKOSH_ASSOCIATE_GROUP_WITH_TRANSACTION");
  const schema = getTransactionValidationSchema(isOshkosh);

  const methods = useForm<S>({
    mode: "onBlur",
    shouldFocusError: false,
    resolver: yupResolver(schema),
    defaultValues: async () => {
      try {
        if (transactionId) {
          const { data } = await fetchFinancialTransaction({
            variables: { id: transactionId, businessUnit: +businessUnitId },
          });

          return {
            transactionId: data?.financialTransaction?.id,
            name: data?.financialTransaction?.label || "",
            isSubmitted: data?.financialTransaction?.state === FinancialTransactionState.Submitted,
            transactionType: data?.financialTransaction?.recordType || TransactionRecordEnumType.Sale,
            totalAmount: data?.financialTransaction?.amount?.toString() || "",
            perHeadAmount: data?.financialTransaction?.unitPrice?.toString() || "",
            date: data?.financialTransaction?.date
              ? DateTime.fromISO(data.financialTransaction.date).toJSDate()
              : DateTime.local().toJSDate(),
            endDate: data?.financialTransaction?.endDate
              ? DateTime.fromISO(data.financialTransaction.endDate).toJSDate()
              : undefined,
            description: data?.financialTransaction?.notes,
            grossWeight: data?.financialTransaction?.grossWeight || undefined,
            shrinkage: data?.financialTransaction?.shrinkage
              ? parseFloat((data.financialTransaction.shrinkage * 100).toFixed(2))
              : undefined,
            payWeight: data?.financialTransaction?.payWeight || undefined,
            contactId: data?.financialTransaction?.contact?.id || "",
            animalsCount: data?.financialTransaction?.animalsCount || 0,
            transactionCategory: data?.financialTransaction?.category?.id || "",
            dateType: data?.financialTransaction?.endDate ? dateRange : singleDate,
            groupId: data?.financialTransaction?.group?.id || "",
          };
        } else {
          const result = await handleCreateTransaction();

          const transaction = result.data?.createFinancialTransaction?.financialTransaction;

          return {
            transactionId: transaction?.id,
            name: transaction?.label || "",
            date: transaction?.date ? DateTime.fromISO(transaction.date).toJSDate() : DateTime.local().toJSDate(),
            isSubmitted: transaction?.state === FinancialTransactionState.Submitted,
            transactionType: transaction?.recordType || TransactionRecordEnumType.Sale,
            totalAmount: "",
            perHeadAmount: "",
            description: "",
            endDate: undefined,
            grossWeight: undefined,
            shrinkage: undefined,
            payWeight: undefined,
            contactId: "",
            animalsCount: 0,
            transactionCategory: "",
            dateType: singleDate,
            groupId: "",
          };
        }
      } catch (e: unknown) {
        handleError(e);

        return {
          transactionId: undefined,
          groupId: "",
          name: "",
          isSubmitted: false,
          transactionType: TransactionRecordEnumType.Sale,
          totalAmount: "",
          date: DateTime.local().toJSDate(),
          description: "",
          perHeadAmount: "",
          grossWeight: undefined,
          shrinkage: undefined,
          payWeight: undefined,
          contactId: "",
          animalsCount: 0,
          transactionCategory: "",
          endDate: undefined,
          dateType: singleDate,
        };
      }
    },
  });

  console.log(methods.watch());

  return { isLoadingDefaultFormValues: isLoadingFinancialTransaction || isCreatingTransaction, methods };
};
