import { FieldSelect, InputCheckbox, Panel } from "components";
import { CommonContext } from "config/commonProvider";
import {
  IdentifierEnumType,
  UpdateIdentifierPreferencesInput,
  useUpdateIdentifierPreferencesMutation,
} from "generated/graphql";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { convertSnakeToCamelCase, identifierNameLookup, upperCaseFirstLetter } from "./helpers";
import { AnimalPreferencesReturnType } from "hooks/useGetAnimalIdPreferences";
import { useLocale } from "helpers/translations/src";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { Tooltip } from "twComponents/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import {
  setActiveAnimalIdentifierPreferences,
  setIdentifierPreferencesPrimaryId,
  setIdentifierPreferencesSecondaryId,
} from "feShared/businessUnit/reducer";

interface IFormValues {
  primaryId: string;
  secondaryId: string;
  name: boolean;
  breedrId: boolean;
  visualId: boolean;
  eId: boolean;
  brucellosisId: boolean;
  pedigreeId: boolean;
  tattoo: boolean;
  trichId: boolean;
  tsuBarcode: boolean;
  herdDoggId: boolean;
  passportNumber: boolean;
  uhfId: boolean;
  alternativeId: boolean;
}

interface Props {
  animalIdPreferences: AnimalPreferencesReturnType;
}

const extractBooleanIdPreferences = (formValues: IFormValues) => {
  const IdPreferences = {
    breedrId: formValues?.breedrId,
    brucellosisId: formValues?.brucellosisId,
    eId: formValues?.eId,
    herdDoggId: formValues?.herdDoggId,
    name: formValues?.name,
    passportNumber: formValues?.passportNumber,
    pedigreeId: formValues?.pedigreeId,
    tattoo: formValues?.tattoo,
    trichId: formValues?.trichId,
    tsuBarcode: formValues?.tsuBarcode,
    uhfId: formValues?.uhfId,
    visualId: formValues?.visualId,
    alternativeId: formValues?.alternativeId,
  };
  return IdPreferences;
};

export const IdPreferencesForm: React.FC<Props> = ({ animalIdPreferences }) => {
  const currentBusinessUnit = useSelector((state: RootState) => state.businessUnits.activeBusinessUnit);
  const { showNotification } = useContext(CommonContext);
  const [updateIdPreferences, { loading: loadingUpdateIdPreferencesData }] = useUpdateIdentifierPreferencesMutation();
  const { activeIdPreferences, primaryId, secondaryId, hiddenIds, activeIdPreferencesByCountry } = animalIdPreferences;
  const activeIdPreferencesArray = activeIdPreferencesByCountry
    ? Object.entries(activeIdPreferencesByCountry).map(([key, value]) => ({ [key]: value }))
    : [];
  activeIdPreferencesArray.shift();

  const { terms } = useLocale();
  const dispatch = useDispatch();
  const countryIsoCode = currentBusinessUnit?.country?.isoAlpha2;
  const countryCode = {
    [CountryISOCode.US]: countryIsoCode === CountryISOCode.US,
    [CountryISOCode.GB]: countryIsoCode === CountryISOCode.GB,
    [CountryISOCode.AU]: countryIsoCode === CountryISOCode.AU,
  };

  const initialFormValues = {
    primaryId: convertSnakeToCamelCase(primaryId),
    secondaryId: convertSnakeToCamelCase(secondaryId),
    breedrId: activeIdPreferences?.breedrId,
    brucellosisId: activeIdPreferences?.brucellosisId,
    eId: activeIdPreferences?.eId,
    herdDoggId: activeIdPreferences?.herdDoggId,
    name: activeIdPreferences?.name,
    passportNumber: activeIdPreferences?.passportNumber,
    pedigreeId: activeIdPreferences?.pedigreeId,
    tattoo: activeIdPreferences?.tattoo,
    trichId: activeIdPreferences?.trichId,
    tsuBarcode: activeIdPreferences?.tsuBarcode,
    uhfId: activeIdPreferences?.uhfId,
    visualId: activeIdPreferences?.visualId,
    alternativeId: activeIdPreferences?.alternativeId,
  };

  const excludedOptions = [...hiddenIds, "primaryId", "secondaryId", "__typename"];
  const optionsForPrimaryPicker = (currentValues: IFormValues) =>
    activeIdPreferences
      ? Object.keys(currentValues)
          .filter(
            (item) => currentValues[item] && item !== currentValues.secondaryId && !excludedOptions.includes(item),
          )
          .map((item) => ({ key: item, label: identifierNameLookup(terms, item), value: item }))
      : [];

  const optionsForSecondaryPicker = (currentValues: IFormValues) =>
    activeIdPreferences
      ? Object.keys(currentValues)
          .filter((item) => currentValues[item] && item !== currentValues.primaryId && !excludedOptions.includes(item))
          .map((item) => ({ key: item, label: identifierNameLookup(terms, item), value: item }))
      : [];
  const initialPrimaryOptions = optionsForPrimaryPicker(initialFormValues as IFormValues);
  const initialSecondaryOptions = optionsForSecondaryPicker(initialFormValues as IFormValues);
  const [primaryPickerOptions, setPrimaryPickerOptions] =
    useState<{ key: string; label: string; value: string }[]>(initialPrimaryOptions);
  const [secondaryPickerOptions, setSecondaryPickerOptions] =
    useState<{ key: string; label: string; value: string }[]>(initialSecondaryOptions);

  useEffect(() => {
    setPrimaryPickerOptions(initialPrimaryOptions);
    setSecondaryPickerOptions(initialSecondaryOptions);
  }, [activeIdPreferences]);
  const methods = useForm<IFormValues>({
    defaultValues: initialFormValues,
    mode: "all",
  });

  const updateIdentifiers = async (formValues: IFormValues) => {
    const input: UpdateIdentifierPreferencesInput = {
      businessUnit: Number(currentBusinessUnit?.id),
      primaryId: IdentifierEnumType[upperCaseFirstLetter(formValues.primaryId)],
      secondaryId: IdentifierEnumType[upperCaseFirstLetter(formValues.secondaryId)],
      name: formValues.name,
      breedrId: formValues.breedrId,
      visualId: formValues.visualId,
      eId: formValues.eId,
      brucellosisId: formValues.brucellosisId,
      pedigreeId: formValues.pedigreeId,
      tattoo: formValues.tattoo,
      trichId: formValues.trichId,
      tsuBarcode: formValues.tsuBarcode,
      herdDoggId: formValues.herdDoggId,
      passportNumber: formValues.passportNumber,
      uhfId: formValues.uhfId,
      alternativeId: formValues.alternativeId,
    };
    await updateIdPreferences({
      variables: {
        input,
      },
      refetchQueries: ["GetBusinessUnit"],
      update: async (_, { data: identifierData }) => {
        if (identifierData?.updateIdentifierPreferences?.errors) {
          // eslint-disable-next-line no-console
          console.error("ERROR", identifierData.updateIdentifierPreferences.errors);
          showNotification({
            variant: "error",
            message: `Error: ${identifierData.updateIdentifierPreferences.errors[0]?.message}`,
          });
        }
        if (identifierData?.updateIdentifierPreferences?.success) {
          showNotification({
            message: "Successfully saved animal ID preferences",
            variant: "success",
          });
          dispatch(setActiveAnimalIdentifierPreferences(extractBooleanIdPreferences(formValues)));
          if (
            input.primaryId &&
            input.primaryId != IdentifierEnumType[upperCaseFirstLetter(initialFormValues.primaryId)]
          ) {
            dispatch(setIdentifierPreferencesPrimaryId(input.primaryId as unknown as IdentifierEnumType));
          }
          if (
            input.secondaryId &&
            input.secondaryId != IdentifierEnumType[upperCaseFirstLetter(initialFormValues.primaryId)]
          ) {
            dispatch(setIdentifierPreferencesSecondaryId(input.secondaryId as unknown as IdentifierEnumType));
          }
        }
      },
    });
  };

  const onSubmit = async (formValues: IFormValues) => {
    if (!currentBusinessUnit?.id) {
      return;
    }
    //// THIS IS CODE FORM MOBILE THAT NEEDS TO BE ADDED TO BACK END IN ORDER TO WORK ON WEB
    // Let calculate % of animals with  primary id 3 ÷ 38
    // const totalAnimals = getAnimalCountByProperty(data.primaryId); // Fix me: allAnimals is not defined
    // const totalFiltersAnimalsPercentage = Math.floor((totalAnimals / allAnimals.length) * 100);
    // if (totalFiltersAnimalsPercentage < 50) {
    //   alert(
    //     "Are you sure?",
    //     `Only ${totalFiltersAnimalsPercentage}% of your animals have ${identifierNameLookup[data.primaryId]}.`,
    //   );
    // }
    await updateIdentifiers(formValues);
    methods.reset(formValues);
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;
  const selectedPrimaryId = watch("primaryId");
  const selectedSecondaryId = watch("secondaryId");

  useEffect(() => {
    const subscription = watch((values) => {
      setPrimaryPickerOptions(optionsForPrimaryPicker(values as IFormValues));
      setSecondaryPickerOptions(optionsForSecondaryPicker(values as IFormValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const renderTooltip = (key: string, isDisabled: boolean) => {
    switch (key) {
      case "visualId":
        return !countryCode[CountryISOCode.GB]
          ? "To add an animal to Breedr, you will still need either an EID or VID."
          : "To add an animal to Breedr, you must have either a Passport Number or VID.";
      case "eId":
        return !countryCode[CountryISOCode.GB]
          ? "To add an animal to Breedr, you will still need either an EID or VID."
          : "";
      case "passportNumber":
        return countryCode[CountryISOCode.GB]
          ? "To add an animal to Breedr, you must have either a Passport Number or VID."
          : "";
      default:
        return isDisabled
          ? `${identifierNameLookup(terms, key)} cannot be disabled when selected as primary or secondary ID.`
          : "";
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Panel
          actions={[
            {
              colour: "grey",
              caption: "Cancel",
              onClick: () => {
                methods.reset(initialFormValues);
              },
              variant: "hollow",
              disabled: loadingUpdateIdPreferencesData || isSubmitting || !isDirty,
            },
            {
              colour: "yellow",
              caption: "Save changes",
              type: "submit",
              disabled: loadingUpdateIdPreferencesData || isSubmitting || !isDirty,
              requesting: loadingUpdateIdPreferencesData || isSubmitting,
            },
          ]}
        >
          {!countryCode[CountryISOCode.GB] ? (
            <div className="w-[50%]">
              <div className="h-2" />
              <label className="text-md font-medium">
                Primary ID
                <Controller
                  control={control}
                  name="primaryId"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FieldSelect<IFormValues>
                        inputProps={{
                          value: value || initialFormValues.primaryId,
                          options: primaryPickerOptions,
                          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                            const primaryId = event.target.value;
                            if (primaryId) {
                              methods.setValue("primaryId", primaryId);
                            }
                            onChange(event);
                          },
                          isClearable: false,
                        }}
                      />
                    );
                  }}
                />
              </label>
              <span className="text-sm">Set the preferred primary ID you want to display</span>
              <div className="h-2" />
              <label className="text-md font-medium">
                Secondary ID
                <Controller<IFormValues>
                  control={control}
                  name="secondaryId"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FieldSelect
                        inputProps={{
                          value: value || initialFormValues.secondaryId,
                          options: secondaryPickerOptions,
                          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                            const secondaryId = event.target.value;
                            if (secondaryId) {
                              methods.setValue("secondaryId", secondaryId);
                            }
                            onChange(event);
                          },
                          isClearable: false,
                        }}
                      />
                    );
                  }}
                />
              </label>
              <span className="text-sm">Set the preferred secondary ID you want to display</span>
              <div className="h-2" />
            </div>
          ) : null}
          <div />
          <label>
            <span className="font-medium">Active IDs</span>
            <div className="h-2" />
            <span className="text-sm">Check the IDs you want to capture in the app</span>
            <div className="flex flex-col pt-2">
              {activeIdPreferencesArray.map((item) => {
                const [key, value] = Object.entries(item)[0];
                const isDisabled = key === selectedPrimaryId || key === selectedSecondaryId;
                return (
                  <div key={key} className="flex items-start">
                    <Controller<IFormValues>
                      control={control}
                      name={key as keyof IFormValues}
                      defaultValue={value}
                      render={({ field: { value, onChange } }) => (
                        <Tooltip title={renderTooltip(key, isDisabled)} position="right">
                          <InputCheckbox
                            checked={value as boolean}
                            name={key}
                            label={identifierNameLookup(terms, key)}
                            onChange={onChange}
                            disabled={isDisabled}
                          />
                        </Tooltip>
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </label>
        </Panel>
      </FormProvider>
    </form>
  );
};
