import { Header, PageContent, PageHeader, Spacer } from "components";
import { ChuteSessionsTable } from "chute/components/ChuteSessionsTable";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

export const ChuteSessions = () => {
  const { terms } = useLocale();

  return (
    <>
      <PageHeader>
        <Header
          title={`${capitaliseFirstLetter(terms.crush)} Sessions`}
          subTitle={`View all ${terms.crush} sessions`}
        />
      </PageHeader>

      <PageContent>
        <Spacer baselineHeight={3} />
        <ChuteSessionsTable />
      </PageContent>
    </>
  );
};
