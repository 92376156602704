//TODO: Move to feShared in future and sync with Mobile
export enum EventName {
  BluetoothConnection = "bluetooth-connection",
  OnboardingUserDetailsSubmit = "onboarding-user-details-submit",
  // Potentially deprecated....
  AppStartup = "app-startup",
  UserLogin = "user-log-in",
  ContactCreate = "contact-create",
  ContactEdit = "contact-edit",
  CrushTypeEid = "crush-type-eid",
  CrushScanEid = "crush-scan-eid",
  CrushSearchAnimal = "crush-search-animal",
  CrushWeightViaWh = "crush-weight-via-wh",
  CrushWeightManual = "crush-weight-manual",
  MedCabAddByScan = "med-cab-add-by-scan",
  MedCabAddManual = "med-cab-add-manual",
  MedCabVeiw = "med-cab-view",
  MedCabBatchView = "med-cab-batch-view",
  MedCabViewMedDetails = "med-cab-view-med-details",
  EntersSettings = "enters-settings",
  ActivityLogAnimal = "activity-log-animal",
  GroupShow = "group-show",
  GroupCreate = "group-create",
  GroupView = "group-view",
  FieldShow = "field-show",
  FieldView = "field-view",
  MsgShow = "msg-show",
  MsgView = "msg-view",
  MsgSendNew = "msg-send-new",
  MsgReply = "msg-reply",
  DebugInfo = "debug-info",
  FieldCreate = "field-create",
  FieldUpdate = "field-update",
  VideoPlay = "video-play",
  RegulatoryRequestDownload = "regulatory-request-download",
  RegulatoryRequestEmail = "regulatory-request-email",
  SaveConnection = "reg-save-credentials",
  QuickWeigh = "quick-weigh",
  SettingsUserListView = "settings-userlist-view",
  SettingsUserInvite = "settings-user-invite",
  SettingsAddresslistView = "settings-addresslist-view",
  SettingsAddressCreate = "settings-address-create",
  CreateAnimalBirth = "create-animal-birth",
  CreateAnimalNonbirth = "create-animal-nonbirth",
  SaveFilter = "save-filter",
  DeeplinkScreen = "deeplink-screen",
  CreateOffer = "create-offer",
  AcceptOffer = "accept-offer",
  RejectOffer = "reject-offer",
  EmptyStateButtonPress = "empty-state-button-press",
  SireCreate = "sire-create",
  SireEdit = "sire-edit",
  SireDelete = "sire-delete",
  BankDetailsEdited = "bank-details-edit",
  BankDetailsAdded = "bank-details-added",
  ListingCreate = "listing-create",
  ListingStartedHomepage = "listing-started-homepage",
  ListingUpdate = "listing-update",
  ListingView = "listing-view",
  OfferDecision = "offer-decision",
  ListingLivestockShipped = "listing-livestock-shipped",
  ListingLivestockReceived = "listing-livestock-received",
  AppActivate = "app-activate",
  StartRegulatoryRequest = "start-regulatory-request",
  RegulatoryRequestStep0 = "regulatory-request-step1",
  RegulatoryRequestStep1 = "regulatory-request-step2",
  RegulatoryRequestStep2 = "regulatory-request-step3",
  ListingStartedBuying = "listing-started-buying,",
  ViewBuyingLiveweight = "view-buying-liveweight",
  ListingStartedSelling = "listing-started-selling",
  ListingPhotoAdded = "listing-photo-added",
  SpeciesSwitch = "species-switch",
  SelectFavoriteActivityTemplate = "select-favorite-activity-template",
  AddActivityTemplateToFavorites = "add-activity-template-to-favorites",
  RemoveActivityTemplateFromFavorites = "remove-activity-template-from-favorites",
  ListingStartedMycattle = "listing-started-mycattle",
  ListingStartedGroups = "listing-started-groups",
  ListingStartedCrush = "listing-started-crush",
  SortAnimalsList = "sort-animals-list",
  ViewAnimalOffspring = "animal-offspring-view",
  ViewAnimalFromOffspringList = "view-animal-from-offspring-list",
  ListingFilterSmallDistance = "listing-filter-small-distance",
  ListingFilterMedDistance = "listing-filter-med-distance",
  ListingFilterLrgDistance = "listing-filter-lrg-distance",
  ListingFilterAnyDistance = "listing-filter-any-distance",
  BluetoothIssue = "bluetooth-issue",
  ListingFilterCategory = "listing-filter-category",
  ContactUsListingBadgePress = "contact-us-listing-badge-press",
  LoqateAddressLookup = "loqate-address-lookup",
  MapsIssue = "maps-issue",
  IdentifierPreferenceManagement = "identifier-preference-management",
  Import = "import",
  FinancialTransactionSave = "financial-transaction-save",
  FinancialTransactionDelete = "financial-transaction-delete",
}
