import { ChangeEvent, useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import { Formik, Form, FormikProps, FastField, FastFieldProps, FormikContext } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { MyLot_myLot, MyLot_myLot_animals } from "trading/api/Liveweight/types/MyLot";
import { getBUFromLocalStorage } from "helpers/storage";
import { CommonContext } from "config/commonProvider";
import { TRADING_TYPES } from "trading/constants";
import { MY_LISTINGS, TRADING, DISTRIBUTION_LISTS } from "constants/Routes";
import { getFormattedPrice, handleErrorCheck } from "helpers/general";
import { twoDecimalPlaces } from "trading/util";
import {
  useBreakpoints,
  useGetTermsConditions,
  useGetTradingTypes,
  useGetDeliveryAddresses,
  useGetCurrentBusinessUnit,
  useSelectDistributionLists,
  useStateSpecies,
  useGetCategoryListings,
} from "hooks";
import { ListingProgress } from "trading/components/containers";
import { TermsAndConditions } from "./TermsAndConditions";
import { TBStatus } from "./TBStatus";
import { AnimalsInLot } from "./AnimalsInLot";
import { PictureManagerImage } from "components/Common/PhotoManager";
import {
  Button,
  ButtonGroup,
  FieldNumber,
  FieldSelect,
  FieldText,
  Flex,
  InputTextArea,
  Modal,
  PhotoManager,
  Spacer,
  Text,
  Title,
} from "components";
import { FormValues, LotTbRequirements, PriceUnits } from "./types";
import styles from "./CreateEditListingForm.module.scss";
import { useCreateLotMutation, useUpdateLotMutation } from "generated/graphql";
import { EventName, logAnalyticsEvent } from "utils/analytics";

type FormProps = FormikProps<FormValues>;

type FormikShouldUpdate = {
  formik: FormikContext<FormValues>;
};

interface Props {
  lot?: MyLot_myLot | null;
}

const validationSchema = Yup.object().shape({
  categoryOfSale: Yup.string().required("Category is required!"),
  name: Yup.string().required("Title is required!"),
  price: Yup.number()
    .typeError("Price must be a number!")
    .positive("Price must be positive!")
    .required("Price is required!")
    .test("2DP", "No more than 2 decimal places", (value) => !!(value && twoDecimalPlaces(value))),
  priceUnit: Yup.string().required("Unit is required!"),
  description: Yup.string(),
  tbRequirements: Yup.string(),
  preMovementTestDate: Yup.string().when("tbRequirements", {
    is: (val) => val === LotTbRequirements.HadPreMovement || val === LotTbRequirements.RequirePreMovement,
    then: Yup.string().required("Date is required!"),
  }),
});

export const CreateEditListingForm: React.FC<Props> = ({ lot }) => {
  const {
    animals,
    name,
    description,
    tbRequirements,
    preMovementTestDate,
    price,
    priceUnit,
    images,
    deliveryAddress,
    distributionLists,
  } = {
    ...lot,
  };
  const { state } = useLocation<{ animals: GetAnimalsList_animalsExtended_animals[] }>();

  const customSelectionOfAnimals: GetAnimalsList_animalsExtended_animals[] = state?.animals;

  const preselectedDistributionListIDs = distributionLists?.reduce((result, preselectedDistributionList) => {
    if (preselectedDistributionList?.id) result.push(preselectedDistributionList.id);

    return result;
  }, [] as string[]);
  const {
    options: distributionListOptions,
    handleSelect: handleSelectDistributionList,
    selected: selectedDistributionLists,
  } = useSelectDistributionLists({ preselected: preselectedDistributionListIDs });

  const [isSelectedPhotos, togglePhotoSelection] = useState<boolean>(false);
  const [isConfirmChangeLocationModal, toggleChangeLocationConfirmModal] = useState<boolean>(false);
  const [imagesToDelete, changeImagesToDelete] = useState<number[]>([]);
  const [tempLocation, changeTempLocation] = useState<string>("");
  const [photos, changePhotos] = useState<PictureManagerImage[]>([]);
  const { showNotification } = useContext(CommonContext);
  const { data: tradingTypes } = useGetTradingTypes();
  const { data } = useGetTermsConditions("MARKETPLACE_SELLER");
  const terms: string | undefined = data?.termsAndConditions?.description;
  const history = useHistory();
  const { data: dataAddresses } = useGetDeliveryAddresses();
  const { currencyCodeISO, weightUnits } = useGetCurrentBusinessUnit();
  const currencySymbol = getSymbolFromCurrency(currencyCodeISO);
  const { fieldSelectOptions: categoryOptions } = useGetCategoryListings();
  const confirmSubtitle =
    "Changing the location will clear the animals you have already selected. Do you want to continue?";
  const addresses =
    dataAddresses?.deliveryAddresses?.map((address) => {
      if (address) {
        return {
          key: address.id,
          label: `${address.unitName || "\u2014"} / ${address.locationId || "\u2014"}`,
          value: address.id,
        };
      }
      return null;
    }) || [];

  const isSelectedPhotosExist: boolean = photos.some((item) => item.isSelected);
  const { activeSpecies } = useStateSpecies();

  const [createLotMutation, { loading: createLotLoading }] = useCreateLotMutation();
  const [updateLotMutation, { loading: updateLotLoading }] = useUpdateLotMutation();

  useEffect(() => {
    if (images && images.length > 0) {
      const initialPhotos = images.map((item) => ({
        id: item?.id || "",
        photo: item?.thumbnailSmall || "",
        isSelected: false,
        isNew: false,
        position: item?.position || 0,
      }));
      const sortedImages = initialPhotos.sort((a, b) => a?.position - b?.position);
      changePhotos(sortedImages);
    }
  }, [images]);

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    try {
      const animalsIds: string[] = values.lotAnimals.map((item) => item.id);
      const tradingTypeSlug: string = values.priceUnit === PriceUnits.Each ? TRADING_TYPES.LTL : TRADING_TYPES.LTD;
      const tradingTypeId = tradingTypes?.tradingTypes?.find((item) => item?.slug === tradingTypeSlug);
      const speciesId = activeSpecies ? +activeSpecies?.id : null;
      const animalType = { animalType: speciesId };
      const categoryOfSaleId =
        lot?.categoryOfSale?.category === values.categoryOfSale ? lot.categoryOfSale.id : +values.categoryOfSale;

      const input = {
        name: values.name,
        description: values.description,
        price: +values.price,
        priceUnit: values.priceUnit,
        tbRequirements: values.tbRequirements,
        preMovementTestDate: values.preMovementTestDate || null,
        tradingType: Number(tradingTypeId?.id),
        animals: animalsIds,
        deliveryAddress: +values.location || null,
        categoryOfSale: categoryOfSaleId,
      };

      if (lot) {
        const newImages = photos
          .filter((item) => item.isNew)
          .map((item) => ({ image: item.file, position: item.position }));
        const orderedImages = photos
          .filter((item) => !item.isNew)
          .map((item) => ({ id: +item.id, position: item.position }));
        await updateLotMutation({
          variables: {
            input: {
              id: Number(lot.id),
              lotImages: newImages,
              deleteImages: imagesToDelete,
              lotImagesOrder: orderedImages,
              ...input,
            },
          },
        });
        showNotification({ message: "Listing successfully updated" });
        logAnalyticsEvent(EventName.ListingUpdate);
      } else {
        await createLotMutation({
          variables: {
            input: {
              businessUnit: getBUFromLocalStorage(),
              distributionLists: selectedDistributionLists?.map((listID) => +listID),
              lotImages: photos.map((item) => ({ image: item.file, position: item.position })),
              ...input,
              ...animalType,
            },
          },
        });
        showNotification({ message: "Listing successfully created" });
        logAnalyticsEvent(EventName.ListingCreate);
      }

      history.push(`${TRADING}${MY_LISTINGS}`);
    } catch (error) {
      const message: string = lot ? "Error editing listing" : "Error creating listing";

      showNotification({
        variant: "error",
        message: message,
      });
    }
  };
  const defaultCategory = lot && lot.categoryOfSale?.id ? lot.categoryOfSale?.category : "";
  const singleAddressValue = addresses.length === 1 && addresses[0]?.value ? addresses[0].value : "";
  const initialLocation = deliveryAddress ? deliveryAddress.id : singleAddressValue;
  const initialValues = {
    name: name || "",
    price: price || "",
    priceUnit: (priceUnit as PriceUnits) || PriceUnits.Each,
    terms: false,
    tbRequirements: (tbRequirements as LotTbRequirements) || LotTbRequirements.DoNotRequirePreMovement,
    preMovementTestDate: preMovementTestDate ? new Date(preMovementTestDate) : "",
    description: description || "",
    lotAnimals: (animals as MyLot_myLot_animals[]) || [],
    location: initialLocation || (customSelectionOfAnimals && customSelectionOfAnimals[0]?.location?.id) || "",
    categoryOfSale: defaultCategory,
  };

  const handleCancelClick = (): void => {
    history.goBack();
  };

  const handleToggleSelectImages = (): void => {
    if (isSelectedPhotos) {
      const newPhotos = photos.map((item) => ({ ...item, isSelected: false }));
      changePhotos(newPhotos);
    }
    togglePhotoSelection(!isSelectedPhotos);
  };

  const handleSelectAllImages = (): void => {
    const newPhotos = photos.map((item) => ({ ...item, isSelected: true }));
    changePhotos(newPhotos);
    togglePhotoSelection(true);
  };

  const handleChangeSelection = (id: string): void => {
    const newPhotos = photos.map((item) => (item.id === id ? { ...item, isSelected: !item.isSelected } : item));
    changePhotos(newPhotos);
  };

  const handleAddNewImage = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    const file = target.files && target.files[0];
    const reader = new FileReader();

    reader.onloadend = (): void => {
      const lastPosition = (photos[photos.length - 1]?.position || 0) + 1;
      const newPhotos = [
        ...photos,
        {
          id: `${file?.name}_${file?.size}`,
          photo: reader.result || "",
          file: file,
          isSelected: false,
          isNew: true,
          position: lastPosition,
        },
      ];
      changePhotos(newPhotos);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeletePhotos = (): void => {
    const imgToDeleteTemp: number[] = [];
    const newPhotos = photos.filter((item) => {
      if (item.isSelected) {
        if (!item.isNew) {
          imgToDeleteTemp.push(+item.id);
        }
        return false;
      } else {
        return true;
      }
    });

    changePhotos(newPhotos);
    changeImagesToDelete(imgToDeleteTemp);
    togglePhotoSelection(false);
  };

  const handleModalClose = (): void => {
    toggleChangeLocationConfirmModal(false);
  };

  const { isBreakpointM } = useBreakpoints();

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ values, errors, handleChange, setFieldValue }: FormProps): JSX.Element => {
        const handleTermsChange = (_: ChangeEvent<HTMLInputElement>, checked?: boolean): void => {
          setFieldValue("terms", checked);
        };

        const handleTBRequirementsChange = (status: string): void => {
          setFieldValue("tbRequirements", status);
          setFieldValue("preMovementTestDate", "");
        };

        const handlePreMovementTestDateChange = (date: Date): void => {
          setFieldValue("preMovementTestDate", date);
        };

        const handleConfirmChangeLocationClick = (): void => {
          setFieldValue("location", tempLocation);
          setFieldValue("lotAnimals", []);
          toggleChangeLocationConfirmModal(false);
        };

        const handleLocationChange = (e: ChangeEvent<HTMLInputElement>): void => {
          if (e.target.value === values.location) {
            return;
          }
          if (values.lotAnimals.length > 0) {
            toggleChangeLocationConfirmModal(true);
            changeTempLocation(e.target.value);
          } else {
            setFieldValue("location", e.target.value);
          }
        };

        const handleTriggerAddImageEvent = (): void => {
          logAnalyticsEvent(EventName.ListingPhotoAdded);
        };

        const isTotalPriceVisible =
          values.priceUnit === PriceUnits.Each && !!+values.price && !errors.price && !!values.lotAnimals.length;
        const totalPrice = +(+values.price * values.lotAnimals.length).toFixed(2);
        const isRequesting: boolean = createLotLoading || updateLotLoading;
        const isTermsRequired: boolean = lot || !terms ? false : !values.terms;
        const isSubmitButtonDisabled: boolean =
          !!Object.keys(errors).length || isTermsRequired || !values.lotAnimals.length || isRequesting;
        const isAskingPriceDisabled = !values.lotAnimals.length;

        const animalWeights = Object.values(
          values?.lotAnimals.filter((value) => value.currentWeight !== null).map((value) => value?.currentWeight),
        );

        const combinedAverageWeight: number =
          (animalWeights.length > 0 &&
            animalWeights?.reduce<number>((acc, cur): number => (cur != null ? acc + cur : acc), 0) /
              animalWeights.length) ||
          0;

        const pricePerWeightInit: number =
          (combinedAverageWeight > 0 && +(+values.price / combinedAverageWeight).toFixed(2)) || 0;

        const hasCorrectCategories = categoryOptions.some(
          (option) =>
            option.value === values.categoryOfSale ||
            (option.label === lot?.categoryOfSale?.category && +option.value === lot?.categoryOfSale?.id) ||
            !values.categoryOfSale,
        );

        if (!hasCorrectCategories && values.categoryOfSale !== "") {
          setFieldValue("categoryOfSale", "");
        }

        return (
          <Form className={styles.full_width}>
            <ListingProgress />
            <Spacer baselineHeight={4} />
            <Flex container>
              <Flex item itemGutter xs="fill">
                <Text>Title</Text>
              </Flex>

              <Flex item itemGutter xs={12} s={6} m={4}>
                <FieldText
                  error={errors.name}
                  inputProps={{
                    value: values.name,
                    name: "name",
                    onChange: handleChange,
                    maxLength: 70,
                  }}
                />
              </Flex>
            </Flex>
            <Spacer baselineHeight={2} />
            <Flex container>
              <Flex item itemGutter xs="fill">
                <Text>Description</Text>
              </Flex>

              <Spacer allowDecimal baselineHeight={1.5} />

              <Flex item itemGutter xs="fill">
                <InputTextArea
                  name="description"
                  resize={false}
                  value={values.description}
                  maxLength={500}
                  rows={8}
                  onChange={handleChange}
                />
              </Flex>
            </Flex>
            <Spacer baselineHeight={3} />
            <Flex container item itemGrow>
              <Flex item itemGutter xs="fill">
                <Text>Select location</Text>
              </Flex>

              <Flex item itemGutter xs={12} s={6} m={4}>
                <FieldSelect
                  error={handleErrorCheck(errors.location)}
                  inputProps={{
                    name: "location",
                    value: values.location,
                    options: addresses,
                    onChange: handleLocationChange,
                  }}
                />
              </Flex>
            </Flex>
            <Spacer baselineHeight={3} />
            <FastField
              name="lotAnimals"
              shouldUpdate={(nextProps: FormikShouldUpdate, prevProps: FormikShouldUpdate): boolean => {
                const isLotAnimalsUpdated = nextProps.formik.values.lotAnimals !== prevProps.formik.values.lotAnimals;
                const isLocationUpdated = nextProps.formik.values.location !== prevProps.formik.values.location;

                return isLotAnimalsUpdated || isLocationUpdated;
              }}
            >
              {({ form }: FastFieldProps<FormValues>): JSX.Element => {
                const handleAnimalsSelect = (
                  animals: (GetAnimalsList_animalsExtended_animals | MyLot_myLot_animals)[],
                ): void => {
                  form.setFieldValue("lotAnimals", animals);
                };

                return (
                  <AnimalsInLot
                    lotAnimals={form.values.lotAnimals}
                    onLotAnimalsAdd={handleAnimalsSelect}
                    lotId={lot?.id}
                    location={form.values.location}
                    customAdditionOfAnimals={customSelectionOfAnimals}
                  />
                );
              }}
            </FastField>
            <Spacer baselineHeight={3} />
            <Flex container>
              <Flex item itemGutter>
                <Title tertiary>Add pictures</Title>
              </Flex>

              <Spacer baselineHeight={1} />

              <Flex container containerJustifyContent="space-between" item xs="fill">
                <Flex item itemGutter>
                  <input
                    className={styles.imageInput}
                    type="file"
                    accept="image/*"
                    onClick={handleTriggerAddImageEvent}
                    onChange={handleAddNewImage}
                  />

                  <Button
                    caption="Add photo"
                    onClick={(): void => {
                      // nothing to do
                    }}
                  />
                </Flex>

                {photos.length > 0 ? (
                  <Flex container>
                    {isSelectedPhotos ? (
                      <Flex item itemGutter>
                        <Button
                          caption="Delete photo(s)"
                          colour="red"
                          disabled={!isSelectedPhotosExist}
                          onClick={handleDeletePhotos}
                        />
                      </Flex>
                    ) : null}

                    <Flex item itemGutter>
                      <Button
                        caption={isSelectedPhotos ? "Cancel" : "Select"}
                        colour={isSelectedPhotos ? "grey" : "yellow"}
                        onClick={handleToggleSelectImages}
                        variant={isSelectedPhotos ? "hollow" : "solid"}
                      />
                    </Flex>

                    <Flex item itemGutter>
                      <Button caption="Select All" onClick={handleSelectAllImages} />
                    </Flex>
                  </Flex>
                ) : null}

                <Spacer baselineHeight={1} />

                <Flex item itemGutter xs="fill">
                  <PhotoManager
                    photos={photos}
                    isSelected={isSelectedPhotos}
                    changeSelection={handleChangeSelection}
                    changePhotos={changePhotos}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Spacer baselineHeight={3} />
            <Flex item itemGutter xs="fill">
              <Title tertiary>Category</Title>
            </Flex>
            <Flex item itemGutter xs={12} s={6} m={4}>
              <FieldSelect
                error={errors.categoryOfSale}
                inputProps={{
                  name: "categoryOfSale",
                  value: values.categoryOfSale,
                  options: categoryOptions,
                  onChange: handleChange,
                }}
              />
            </Flex>

            <Spacer baselineHeight={2} />

            <Flex container>
              <Flex item itemGutter xs="fill">
                <Title tertiary>Price</Title>
              </Flex>

              <Spacer baselineHeight={1} />

              <Flex item itemGutter xs={12} m={6} l={4}>
                <FieldNumber
                  error={errors.price}
                  label={`Enter asking price (${currencySymbol} per head):`}
                  inputProps={{
                    value: values.price,
                    disabled: isAskingPriceDisabled,
                    name: "price",
                    onChange: handleChange,
                    step: "0.01",
                  }}
                />
              </Flex>

              <Flex item itemGutter xs={12} m={6}>
                {isBreakpointM ? <Spacer baselineHeight={4} /> : null}

                <Text smallest>
                  which is approximately{" "}
                  <strong>
                    {" "}
                    {pricePerWeightInit > 0 ? getFormattedPrice(pricePerWeightInit, currencyCodeISO) : "N/A"} per{" "}
                    {weightUnits},
                  </strong>{" "}
                  based on the current estimated weight of the animals on this listing.
                </Text>
              </Flex>

              {isTotalPriceVisible ? (
                <>
                  <Spacer baselineHeight={isBreakpointM ? 1 : 2} />

                  <Flex item itemGutter xs="fill">
                    <Text>Total purchase price:</Text>

                    <Spacer allowDecimal baselineHeight={0.5} />

                    <Title secondary>{getFormattedPrice(totalPrice, currencyCodeISO)}</Title>
                  </Flex>

                  <Spacer baselineHeight={1} />
                </>
              ) : null}
            </Flex>

            <Spacer baselineHeight={3} />
            <TBStatus
              preMovementTestDate={values.preMovementTestDate}
              onPreMovementTestDateChange={handlePreMovementTestDateChange}
              tbRequirements={values.tbRequirements}
              onTBRequirementChange={handleTBRequirementsChange}
              isError={!!errors.preMovementTestDate}
            />
            <Spacer baselineHeight={2} />

            <Flex container>
              <Flex item itemGutter xs={12} m={6} l={4}>
                <Title tertiary>Share privately</Title>

                <Spacer baselineHeight={1} />

                {!!distributionListOptions ? (
                  <FieldSelect
                    helper={
                      !lot
                        ? "Your lists are set up under the tab 'Lists' within Trading"
                        : "Sharing a listing privately may only be done on creation"
                    }
                    inputProps={{
                      disabled: !!lot,
                      labelKey: "label",
                      multiple: true,
                      name: "distributionLists",
                      onChange: handleSelectDistributionList,
                      options: distributionListOptions,
                      value: selectedDistributionLists,
                      valueKey: "value",
                    }}
                    label="Share to a list"
                  />
                ) : (
                  <Text smaller>
                    To share to a distribution list you must first{" "}
                    <NavLink to={`${TRADING}${DISTRIBUTION_LISTS}`}>create a list</NavLink>.
                  </Text>
                )}
              </Flex>
            </Flex>
            <Spacer baselineHeight={3} />
            {!lot ? <TermsAndConditions isAgreed={values.terms} onChange={handleTermsChange} terms={terms} /> : null}
            <Flex container>
              <Flex item itemGutter>
                <ButtonGroup>
                  <Button
                    caption={lot ? "Update listing" : "Create listing"}
                    disabled={isSubmitButtonDisabled}
                    requesting={isRequesting}
                    type="submit"
                  />
                  <Button caption="Cancel" onClick={handleCancelClick} colour="grey" variant="hollow" />
                </ButtonGroup>
              </Flex>
            </Flex>
            <Modal
              showCloseButton
              active={isConfirmChangeLocationModal}
              handleClose={handleModalClose}
              title="Change location"
              subTitle={confirmSubtitle}
              actions={{
                secondary: {
                  onClick: handleModalClose,
                  caption: "Cancel",
                },
                primary: {
                  onClick: handleConfirmChangeLocationClick,
                  caption: "Confirm",
                },
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
