import { VFC, useContext, useEffect, useMemo, useState } from "react";
// Libraries
import { useLocation } from "react-router-dom";
// Utils
import { GetAnimalsList_animalsExtended_animals } from "api/MyLivestock/Animal/types/GetAnimalsList";
import { AnimalFiltersState, CommonContext } from "config/commonProvider";
import { TableOptionsContext } from "config/tableOptionsProvider";
import { FEATURES } from "constants/Features";
import { TABLE_IDS } from "constants/Interface";
import { hasFeature } from "helpers/general";
import { useAnimalsSpeciesActive, useHasFeature } from "hooks";
// Components
import { AnimalsTable, AnimalFiltersBlock } from "components/MyLivestock";
import { Flex, LoadingOverlay, NoItemsFound, Spacer } from "components";

interface AnimalListLocationState {
  animalsFilter?: AnimalFiltersState;
}

interface AnimalListProps {
  isArchive?: boolean;
  noResults?: JSX.Element;
  noResultsText?: string;
}

export const AnimalList: VFC<AnimalListProps> = ({ isArchive, noResults, noResultsText }) => {
  const { state: locationState } = useLocation<AnimalListLocationState | undefined>();

  const showAnimalFilters = useHasFeature("ANIMALS_FILTER");
  const {
    data: animals = [],
    animalsCount,
    loading: isQueryLoading,
    unfilteredAnimals,
  } = useAnimalsSpeciesActive(null, {
    isOnFarm: isArchive ? false : undefined,
  });
  const hasAnimals = !!unfilteredAnimals.length;
  const { changeAnimalsFilter } = useContext(CommonContext);
  const animalTableId = isArchive ? TABLE_IDS.ANIMALS_ARCHIVE : TABLE_IDS.ANIMALS;

  const [isSelectedAnimalsView, setIsSelectedAnimalsView] = useState(false);

  const { getTableOptions } = useContext(TableOptionsContext);
  const tableOptions = getTableOptions<GetAnimalsList_animalsExtended_animals>(animalTableId);
  const selectedRowIds = useMemo(() => tableOptions?.selectedRowsIds, [tableOptions?.selectedRowsIds]);
  const selectedAnimalIds = useMemo(() => (selectedRowIds ? [...selectedRowIds] : []), [selectedRowIds]);

  const animalsCountLabel = `Showing ${animals.length} of ${animalsCount}`;

  const animalsToExport: string[] = animals.length === animalsCount ? [] : animals.map((item) => item?.id);
  const countAnimalsToExport: number = animals.length === animalsCount ? animals.length : animalsToExport.length;

  // filters to remove depending on archive or not
  const filterFieldsToReset = {
    onFarm: {
      isRegulatorySynced: "",
      isWithdrawal: "",
      daysSinceLastWeightFrom: "",
      daysSinceLastWeightTo: "",
      fields: "",
    },
    archive: {
      dobDateFrom: "",
      dobDateTo: "",
      isRegulatorySynced: "",
      minDateLeftFarm: "",
      maxDateLeftFarm: "",
      minDeadAtDate: "",
      maxDeadAtDate: "",
      minKillWeight: "",
      maxKillWeight: "",
      killQuality: "",
      killFatScore: "",
    },
  };

  useEffect(() => {
    // due to on farm and archive animals having different filters
    // when you switch pages with filters selected, you can see
    // preselected filters from the wrong data, this rectifies that.
    changeAnimalsFilter(
      isArchive
        ? {
            ...filterFieldsToReset.onFarm,
            ...locationState?.animalsFilter,
          }
        : {
            ...filterFieldsToReset.archive,
            ...locationState?.animalsFilter,
          },
    );
  }, []);

  useEffect(() => {
    if (isSelectedAnimalsView && !selectedAnimalIds.length) {
      setIsSelectedAnimalsView(false);
    }
  }, [selectedAnimalIds, isSelectedAnimalsView]);

  // show loading spinner
  if (isQueryLoading && !hasAnimals) {
    return <LoadingOverlay />;
  }

  // show either no results component provided or fall back to NoItemsFound component
  if (!isQueryLoading && !hasAnimals) {
    return (
      <>
        <Spacer baselineHeight={2} />
        {noResults ? noResults : <NoItemsFound title={noResultsText ? noResultsText : "There are no animals view"} />}
      </>
    );
  }

  return (
    <>
      <Spacer baselineHeight={1} />

      <Spacer baselineHeight={1} />

      {showAnimalFilters ? (
        <AnimalFiltersBlock
          animalsCountLabel={animalsCountLabel}
          animalsToExport={animalsToExport}
          countAnimalsToExport={countAnimalsToExport}
          excludedFields={{ animalTypeId: true, ageAtDelivery: true, weightAtDelivery: true }}
          selectedAnimalIds={selectedAnimalIds}
          tableId={animalTableId}
          isSavedFiltersAvailable={!isArchive}
          isSelectedAnimalsView={isSelectedAnimalsView}
          isArchived={!!isArchive}
          onViewSelectionClick={setIsSelectedAnimalsView}
        />
      ) : null}

      <Spacer baselineHeight={1} />

      <Flex container item itemGrow>
        <Flex item itemGutter xs="fill">
          <AnimalsTable
            animals={animals}
            fromPage="livestock"
            isShowActionButtons={!isArchive}
            selectedRowIds={selectedRowIds}
            showCheckboxes={hasFeature(FEATURES.ANIMAL_SELECTION) && !isArchive}
            showSelectedAnimals={isSelectedAnimalsView}
            tableId={animalTableId}
            isArchive={!!isArchive}
            tablePageSize={50}
          />
        </Flex>
      </Flex>
    </>
  );
};
