import { Spacer } from "components";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { useState } from "react";
import { EmptyState } from "components/EmptyStates/Default";
import { LoadingOverlay } from "twComponents/LoadingOverlay";
import { getInternationalDateFormat } from "hooks/useFormatDate";
import { partitionAnimalHistoryByDate } from "./helpers";
import { useAnimalHistory } from "./useAnimalHistory";
import { HistorySection } from "./HistorySection";
import { ConfirmDeleteHistoryEventModal } from "./ConfirmDeleteHistoryEventModal";
import { DateFilter } from "./DateFilter";
import { ActivityTypeFilters } from "./ActivityTypeFilters";
import { RemoveFiltersButton } from "./RemoveFiltersButton";
import { LoadMoreButton } from "./LoadMoreButton";

export const AnimalHistory = ({ animalId }: { animalId: string }) => {
  const { countryCode } = useGetCurrentBusinessUnit();
  const { events, setAnimalHistoryQueryVariables, handleLoadMore, animalHistoryQueryVariables, loading, pageInfo } =
    useAnimalHistory(animalId);
  const { locale } = getInternationalDateFormat(countryCode);

  const [activityId, setActivityId] = useState<string>();
  const [showDeleteHistoryEventModal, setShowDeleteHistoryEventModal] = useState(false);

  const animalHistorySections = partitionAnimalHistoryByDate(events, locale);

  const handleDelete = (activityId: string) => {
    setShowDeleteHistoryEventModal(true);
    setActivityId(activityId);
  };

  return (
    <div className="bg-white rounded-lg max-w-5xl border border-solid border-gray-400">
      <header className="p-4 border-b border-solid border-gray-400 flex justify-between items-center flex-wrap">
        <h2 className="font-semibold text-xl">History</h2>
        <nav className="flex">
          <RemoveFiltersButton
            animalHistoryQueryVariables={animalHistoryQueryVariables}
            setAnimalHistoryQueryVariables={setAnimalHistoryQueryVariables}
          />
          <ActivityTypeFilters
            animalHistoryQueryVariables={animalHistoryQueryVariables}
            setAnimalHistoryQueryVariables={setAnimalHistoryQueryVariables}
          />
          <DateFilter
            animalHistoryQueryVariables={animalHistoryQueryVariables}
            setAnimalHistoryQueryVariables={setAnimalHistoryQueryVariables}
          />
        </nav>
      </header>

      <div className="p-4 bg-gray-50 rounded-b-xl">
        <Spacer baselineHeight={2} />
        {events.length === 0 && !loading ? <EmptyState title="No animal history" description="" /> : null}
        {loading ? <LoadingOverlay /> : null}
        {Object.entries(animalHistorySections).map(([date, events]) => {
          return <HistorySection key={date} dateLabel={date} events={events} handleDelete={handleDelete} />;
        })}
        <LoadMoreButton
          handleLoadMore={handleLoadMore}
          loading={loading}
          hasNextPage={pageInfo?.hasNextPage || false}
        />
      </div>
      <ConfirmDeleteHistoryEventModal
        activityId={activityId}
        setShowDeleteHistoryEventModal={setShowDeleteHistoryEventModal}
        showDeleteHistoryEventModal={showDeleteHistoryEventModal}
        animalId={animalId}
      />
    </div>
  );
};
