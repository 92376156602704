import { PregnancyStatus, Sex } from "animals/AnimalsList/types";
import { FieldDateRange } from "components/Common/Field/DateRange";
import { FieldNumberRange } from "components/Common/Field/NumberRange";
import { FieldSelect } from "components/Common/Field/Select";
import { positiveFloatNumberWithZero } from "helpers/regex";
import { useLocale } from "helpers/translations/src/useLocale";
import { useGetBreeds, useGetCurrentBusinessUnit, useGetFields, useGetGroups } from "hooks";
import { useAnimalFilters } from "hooks/useAnimalFilters";
import debounce from "lodash/debounce";
import { ChangeEvent, useMemo, useRef } from "react";
import { TableFilterIdentifier } from "state/animalFiltersSlice";
import { useTags } from "tags/hooks/useTagsHook";
import { Button } from "twComponents/Button";
import { ANIMAL_FILTER_VALUES, DRAWAL_OF_ANIMALS, SYNCR_OF_ANIMALS } from "constants/Animals";
import { getEndOfDay, getStartOfDay } from "feShared/utils/dates";

interface Props {
  isArchive: boolean;
  tableFilterIdentifier: TableFilterIdentifier;
  excludedFilters?: {
    groupId?: boolean;
    fieldId?: boolean;
    tagId?: boolean;
  };
}

export const AnimalFilters = ({ tableFilterIdentifier, excludedFilters, isArchive }: Props) => {
  const { countryIsoCode, weightUnits } = useGetCurrentBusinessUnit();
  const isGB = countryIsoCode === "gb";
  const { terms } = useLocale();
  const { handleResetFilters, handleApplyFilter, filters } = useAnimalFilters(tableFilterIdentifier);
  const { animalTagOptions } = useTags();
  const { fieldOptions } = useGetFields();
  const { groupOptions } = useGetGroups();
  const { breedOptions } = useGetBreeds();

  const formRef = useRef<HTMLFormElement>(null);

  const clearInputs = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const debounceMonthFrom = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        ageInMonths: { ...filters.ageInMonths, from: event.target.value },
      });
    }, 300);
  }, [filters.ageInMonths, handleApplyFilter]);
  const debounceMonthTo = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        ageInMonths: { ...filters.ageInMonths, to: event.target.value },
      });
    }, 300);
  }, [filters.ageInMonths, handleApplyFilter]);
  const debounceEstimatedWeightFrom = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        estimatedWeight: { ...filters.estimatedWeight, from: event.target.value },
      });
    }, 300);
  }, [filters.estimatedWeight, handleApplyFilter]);
  const debounceEstimatedWeightTo = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        estimatedWeight: { ...filters.estimatedWeight, to: event.target.value },
      });
    }, 300);
  }, [filters.estimatedWeight, handleApplyFilter]);
  const debounceLastWeightFrom = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        lastWeight: { ...filters.lastWeight, from: event.target.value },
      });
    }, 300);
  }, [filters.lastWeight, handleApplyFilter]);
  const debounceLastWeightTo = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        lastWeight: { ...filters.lastWeight, to: event.target.value },
      });
    }, 300);
  }, [filters.lastWeight, handleApplyFilter]);
  const debounceDaysSinceLastWeighedFrom = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        daysSinceWeighed: { ...filters.daysSinceWeighed, from: event.target.value },
      });
    }, 300);
  }, [filters.daysSinceWeighed, handleApplyFilter]);
  const debounceDaysSinceLastWeighedTo = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({
        daysSinceWeighed: { ...filters.daysSinceWeighed, to: event.target.value },
      });
    }, 300);
  }, [filters.daysSinceWeighed, handleApplyFilter]);
  const debounceGrowthRateFrom = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({ growthRate: { ...filters.growthRate, from: event.target.value } });
    }, 300);
  }, [filters.growthRate, handleApplyFilter]);
  const debounceGrowthRateTo = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      handleApplyFilter({ growthRate: { ...filters.growthRate, to: event.target.value } });
    }, 300);
  }, [filters.growthRate, handleApplyFilter]);

  return (
    <form ref={formRef} className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
      {!excludedFilters?.tagId ? (
        <FieldSelect
          label="Tags"
          inputProps={{
            name: "tags",
            value: filters.tagIds,
            multiple: true,
            options: animalTagOptions,
            placeholder: "No preference",
            onChange: (event) => {
              handleApplyFilter({
                tagIds: event.target.value,
              });
            },
            isClearable: true,
          }}
        />
      ) : null}
      {!excludedFilters?.groupId ? (
        <FieldSelect
          label="Groups"
          inputProps={{
            name: "groups",
            value: filters.groups ?? [],
            multiple: true,
            options: groupOptions,
            placeholder: "No preference",
            onChange: (event) => handleApplyFilter({ groups: event.target.value }),
            isClearable: true,
          }}
        />
      ) : null}
      {!excludedFilters?.fieldId ? (
        <FieldSelect
          label="Locations"
          inputProps={{
            name: "fields",
            value: filters.fields ?? [],
            multiple: true,
            options: fieldOptions,
            placeholder: "No preference",
            onChange: (event: { target: { value: string[] } }) => handleApplyFilter({ fields: event.target.value }),
            isClearable: true,
          }}
        />
      ) : null}
      <FieldSelect
        label="Sex"
        inputProps={{
          name: "sex",
          value: filters.sex ?? "",
          placeholder: "No preference",
          options: [
            {
              key: 1,
              value: Sex.MALE,
              label: "Male",
            },
            {
              key: 2,
              value: Sex.FEMALE,
              label: "Female",
            },
          ],
          onChange: (event: ChangeEvent<HTMLInputElement>) => handleApplyFilter({ sex: event.target.value }),
          isClearable: true,
        }}
      />
      <FieldSelect
        label="Breeds"
        inputProps={{
          name: "breed",
          value: filters.breeds ?? [],
          multiple: true,
          placeholder: "No preference",
          options: breedOptions,
          onChange: (event: { target: { value: string[] } }) => handleApplyFilter({ breeds: event.target.value }),
          isClearable: true,
        }}
      />
      <FieldNumberRange
        label="Age range (months)"
        inputProps={{
          from: {
            name: "ageInMonthsFrom",
            placeholder: "No preference",
            onChange: debounceMonthFrom,
            validation: positiveFloatNumberWithZero,
          },
          to: {
            name: "ageInMonthsTo",
            onChange: debounceMonthTo,
            placeholder: "No preference",
            min: filters.ageInMonths.from,
            validation: positiveFloatNumberWithZero,
          },
        }}
      />
      <FieldDateRange
        label="DOB range"
        inputProps={{
          from: {
            value: filters.dateOfBirth.from ? new Date(filters.dateOfBirth.from) : undefined,
            name: "dobDateFrom",
            onChange: (date: Date) => {
              handleApplyFilter({
                dateOfBirth: {
                  ...filters.dateOfBirth,
                  from: date ? getStartOfDay(date).toISOString() : undefined,
                },
              });
            },
            placeholderText: "No preference",
            isClearable: true,
          },
          to: {
            value: filters.dateOfBirth.to ? new Date(filters.dateOfBirth.to) : undefined,
            name: "dobDateTo",
            onChange: (date: Date) => {
              handleApplyFilter({
                dateOfBirth: {
                  ...filters.dateOfBirth,
                  to: date ? getEndOfDay(date).toISOString() : undefined,
                },
              });
            },
            placeholderText: "No preference",
            isClearable: true,
          },
        }}
      />
      <FieldDateRange
        label={`Date on ${terms.farm} range`}
        inputProps={{
          from: {
            value: filters.dateOnFarm.from ? new Date(filters.dateOnFarm.from) : undefined,
            name: "dofDateFrom",
            onChange: (date: Date) =>
              handleApplyFilter({
                dateOnFarm: { ...filters.dateOnFarm, from: date ? getStartOfDay(date).toISOString() : undefined },
              }),
            placeholderText: "No preference",
            isClearable: true,
          },
          to: {
            value: filters.dateOnFarm.to ? new Date(filters.dateOnFarm.to) : undefined,
            name: "dofDateTo",
            onChange: (date: Date) =>
              handleApplyFilter({
                dateOnFarm: {
                  ...filters.dateOnFarm,
                  to: date ? getEndOfDay(date).toISOString() : undefined,
                },
              }),
            placeholderText: "No preference",
            isClearable: true,
          },
        }}
      />
      {isArchive ? (
        <FieldDateRange
          label={`Date left ${terms.farm} range`}
          inputProps={{
            from: {
              value: filters.dateLeftFarm.from ? new Date(filters.dateLeftFarm.from) : undefined,
              name: "dateLeftFarmFrom",
              onChange: (date: Date) =>
                handleApplyFilter({
                  dateLeftFarm: { ...filters.dateLeftFarm, from: date ? getStartOfDay(date).toISOString() : undefined },
                }),
              placeholderText: "No preference",
              isClearable: true,
            },
            to: {
              value: filters.dateLeftFarm.to ? new Date(filters.dateLeftFarm.to) : undefined,
              name: "dateLeftFarmTo",
              onChange: (date: Date) =>
                handleApplyFilter({
                  dateLeftFarm: {
                    ...filters.dateLeftFarm,
                    to: date ? getEndOfDay(date).toISOString() : undefined,
                  },
                }),
              placeholderText: "No preference",
              isClearable: true,
            },
          }}
        />
      ) : null}
      <FieldNumberRange
        label={`Current weight range (${weightUnits}s)`}
        inputProps={{
          from: {
            name: "estimatedWeightFrom",
            placeholder: "No preference",
            onChange: debounceEstimatedWeightFrom,
            validation: positiveFloatNumberWithZero,
          },
          to: {
            name: "estimatedWeightTo",
            onChange: debounceEstimatedWeightTo,
            placeholder: "No preference",
            min: filters.estimatedWeight.from,
            validation: positiveFloatNumberWithZero,
          },
        }}
      />
      <FieldNumberRange
        label={`Last weight range (${weightUnits}s)`}
        inputProps={{
          from: {
            name: "lastWeightFrom",
            placeholder: "No preference",
            onChange: debounceLastWeightFrom,
            validation: positiveFloatNumberWithZero,
          },
          to: {
            name: "lastWeightTo",
            onChange: debounceLastWeightTo,
            placeholder: "No preference",
            min: filters.lastWeight.from,
            validation: positiveFloatNumberWithZero,
          },
        }}
      />

      {!isArchive ? (
        <FieldNumberRange
          label="Days since weighed range"
          inputProps={{
            from: {
              name: "daysSinceLastWeighedFrom",
              placeholder: "No preference",
              onChange: debounceDaysSinceLastWeighedFrom,
              validation: positiveFloatNumberWithZero,
            },
            to: {
              name: "daysSinceLastWeightTo",
              onChange: debounceDaysSinceLastWeighedTo,
              placeholder: "No preference",
              min: filters.daysSinceWeighed.from,
              validation: positiveFloatNumberWithZero,
            },
          }}
        />
      ) : null}
      <FieldNumberRange
        label={`${terms.dlwg} range (${weightUnits}s)`}
        inputProps={{
          from: {
            name: "growthRateFrom",
            placeholder: "No preference",
            validation: positiveFloatNumberWithZero,
            onChange: debounceGrowthRateFrom,
          },
          to: {
            name: "growthRateTo",
            placeholder: "No preference",
            min: filters.growthRate.from,
            validation: positiveFloatNumberWithZero,
            onChange: debounceGrowthRateTo,
          },
        }}
      />
      <FieldSelect
        label="Pregnancy status"
        inputProps={{
          name: "pregnancyStatus",
          value: filters.pregnancyStatus ?? "",
          placeholder: "No preference",
          options: [
            {
              key: 1,
              value: PregnancyStatus.PREGNANT,
              label: "Pregnant",
            },
            {
              key: 2,
              value: PregnancyStatus.NOTPREGNANT,
              label: "Not pregnant",
            },
          ],
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handleApplyFilter({ pregnancyStatus: event.target.value }),
          isClearable: true,
        }}
      />
      <FieldDateRange
        label="Pregnancy due date range"
        inputProps={{
          from: {
            value: filters.pregnancyDueDate.from ? new Date(filters.pregnancyDueDate.from) : undefined,
            name: "pregnancyDueDate",
            onChange: (date: Date) =>
              handleApplyFilter({
                pregnancyDueDate: {
                  ...filters.pregnancyDueDate,
                  from: date ? getStartOfDay(date).toISOString() : undefined,
                },
              }),
            placeholderText: "No preference",
            isClearable: true,
          },
          to: {
            value: filters.pregnancyDueDate.to ? new Date(filters.pregnancyDueDate.to) : undefined,
            name: "pregnancyDueDate",
            onChange: (date: Date) =>
              handleApplyFilter({
                pregnancyDueDate: {
                  ...filters.pregnancyDueDate,
                  to: date ? getEndOfDay(date).toISOString() : undefined,
                },
              }),
            placeholderText: "No preference",
            isClearable: true,
          },
        }}
      />
      {isGB ? (
        <>
          <div>
            <FieldDateRange
              label="Delivery date range"
              inputProps={{
                from: {
                  value: filters.tradingDeliveryDate.from ? new Date(filters.tradingDeliveryDate.from) : undefined,
                  name: "deliveryDateRange",
                  onChange: (date: Date) =>
                    handleApplyFilter({
                      tradingDeliveryDate: {
                        ...filters.tradingDeliveryDate,
                        from: date ? getStartOfDay(date).toISOString() : undefined,
                      },
                    }),
                  placeholderText: "No preference",
                  isClearable: true,
                },
                to: {
                  value: filters.tradingDeliveryDate.to ? new Date(filters.tradingDeliveryDate.to) : undefined,
                  name: "deliveryDateRange",
                  onChange: (date: Date) =>
                    handleApplyFilter({
                      tradingDeliveryDate: {
                        ...filters.tradingDeliveryDate,
                        to: date ? getEndOfDay(date).toISOString() : undefined,
                      },
                    }),
                  placeholderText: "No preference",
                  isClearable: true,
                },
              }}
            />
          </div>
          <div>
            <FieldDateRange
              label="Last synced date range"
              inputProps={{
                from: {
                  value: filters.regulatorySyncDate.from ? new Date(filters.regulatorySyncDate.from) : undefined,
                  name: "lastSyncedDateFrom",
                  onChange: (date: Date) =>
                    handleApplyFilter({
                      regulatorySyncDate: {
                        ...filters.regulatorySyncDate,
                        from: date ? getStartOfDay(date).toISOString() : undefined,
                      },
                    }),
                  placeholderText: "No preference",
                  isClearable: true,
                },
                to: {
                  value: filters.regulatorySyncDate.to ? new Date(filters.regulatorySyncDate.to) : undefined,
                  name: "lastSyncedDateTo",
                  onChange: (date: Date) =>
                    handleApplyFilter({
                      regulatorySyncDate: {
                        ...filters.regulatorySyncDate,
                        to: date ? getEndOfDay(date).toISOString() : undefined,
                      },
                    }),
                  placeholderText: "No preference",
                  isClearable: true,
                },
              }}
            />
          </div>
          <div>
            <FieldSelect
              label="Is Synced"
              inputProps={{
                name: "isRegulatorySynced",
                value:
                  filters.isRegulatorySynced === true
                    ? ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED
                    : filters.isRegulatorySynced === false
                    ? ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.NOT_SYNCED
                    : "",
                placeholder: "No preference",
                options: SYNCR_OF_ANIMALS,
                onChange: (event: ChangeEvent<HTMLInputElement>) =>
                  handleApplyFilter({
                    isRegulatorySynced:
                      event.target.value === ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.SYNCED
                        ? true
                        : event.target.value === ANIMAL_FILTER_VALUES.IS_BCMS_SYNCED.NOT_SYNCED
                        ? false
                        : undefined,
                  }),
                isClearable: true,
              }}
            />
          </div>
        </>
      ) : null}
      <FieldSelect
        label="In Withdrawal"
        inputProps={{
          name: "isInWithdrawal",
          value:
            filters.isInWithdrawal === true
              ? ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL
              : filters.isInWithdrawal === false
              ? ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.NOT_DRAWAL
              : "",
          placeholder: "No preference",
          options: DRAWAL_OF_ANIMALS,
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handleApplyFilter({
              isInWithdrawal:
                event.target.value === ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.DRAWAL
                  ? true
                  : event.target.value === ANIMAL_FILTER_VALUES.IS_WITHDRAWAL.NOT_DRAWAL
                  ? false
                  : undefined,
            }),
          isClearable: true,
        }}
      />

      <div className="py-2 flex items-end">
        <Button
          className="w-fit mb-1"
          variant="outline"
          onClick={() => {
            handleResetFilters();
            clearInputs();
          }}
        >
          Clear Filters
        </Button>
      </div>
    </form>
  );
};
