import { Modal } from "components";
import { FINANCIAL_TRANSACTIONS } from "constants/Routes";
import { useHandleMutationResult } from "hooks/useHandleMutationResult";
import { useHistory } from "react-router-dom";
import { useTransactionActions } from "../hooks/useTransactionActions";
import { useFormContext } from "react-hook-form";
import { TransactionValidationSchema as S } from "financial/components/TransactionForm/validation";
import { EventName, logAnalyticsEvent } from "utils/analytics";
import { getBUFromLocalStorage } from "helpers/storage";

type Props = {
  showDeleteConfirmationModal: boolean;
  setShowDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldDeleteTransactionOnRouteChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteConfirmationModal = ({
  setShowDeleteConfirmationModal,
  showDeleteConfirmationModal,
  setShouldDeleteTransactionOnRouteChange,
}: Props) => {
  const businessUnitId = getBUFromLocalStorage();
  const methods = useFormContext<S>();

  if (!methods) {
    throw new Error("DeleteConfirmationModal must be used inside a <Form /> or <FormProvider />");
  }

  const { handleError, handleMutationResult } = useHandleMutationResult();
  const { handleDeleteFinancialTransaction, isDeletingFinancialTransaction } = useTransactionActions();

  const history = useHistory();

  const onDeleteFinancialTransaction = async () => {
    setShouldDeleteTransactionOnRouteChange(false);

    const transactionId = methods.watch("transactionId");

    try {
      logAnalyticsEvent(EventName.FinancialTransactionDelete, {
        transactionId,
        businessUnitId,
      });

      const result = await handleDeleteFinancialTransaction(transactionId);
      const errors = result?.data?.deleteFinancialTransaction?.errors || [];

      handleMutationResult({
        successMessage: "Successfully removed animals from transaction.",
        errors,
        onSuccess: () => {
          history.push(`${FINANCIAL_TRANSACTIONS}`);
        },
      });
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <Modal
      active={showDeleteConfirmationModal}
      title="Delete Draft"
      subTitle="Are you sure you want to delete this draft?"
      actions={{
        primary: {
          caption: "Confirm",
          disabled: isDeletingFinancialTransaction,
          requesting: isDeletingFinancialTransaction,
          onClick: onDeleteFinancialTransaction,
        },
        secondary: {
          caption: "Cancel",
          onClick: () => setShowDeleteConfirmationModal(false),
        },
      }}
    />
  );
};
