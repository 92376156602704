import { AnimalHistoryQueryVariables, useAnimalHistoryLazyQuery } from "generated/graphql";
import { removeNothings } from "helpers/general";
import { useEffect, useState } from "react";

const PAGE_SIZE = 5;

export type UseAnimalHistoryResult = ReturnType<typeof useAnimalHistory>;

export const useAnimalHistory = (animalId: string) => {
  const [animalHistoryQueryVariables, setAnimalHistoryQueryVariables] = useState<AnimalHistoryQueryVariables>({
    first: PAGE_SIZE,
    animal: animalId,
  });

  const [fetchAnimalHistory, { data, fetchMore, loading }] = useAnimalHistoryLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = data?.animalHistory?.pageInfo;
  const historyEdges = data?.animalHistory?.edges;
  const events = removeNothings(historyEdges?.map((edge) => edge?.node) || []);

  useEffect(() => {
    fetchAnimalHistory({ variables: animalHistoryQueryVariables });
  }, [animalHistoryQueryVariables, fetchAnimalHistory]);

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          ...animalHistoryQueryVariables,
          after: pageInfo.endCursor,
        },
      });
    }
  };
  return {
    events,
    loading,
    pageInfo,
    handleLoadMore,
    setAnimalHistoryQueryVariables,
    animalHistoryQueryVariables,
  };
};
