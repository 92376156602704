import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { CREATE_SIRE, UPDATE_SIRE } from "api/Sires/mutations";
import { GET_MY_SIRES_LIST } from "api/Sires/queries";
import { GetSireById_sire } from "api/Sires/types/GetSireById";
import { CreateSireVariables, CreateSire } from "api/Sires/types/CreateSire";
import { UpdateSireVariables, UpdateSire } from "api/Sires/types/UpdateSire";
import { CommonContext } from "config/commonProvider";
import { SIRES } from "constants/Routes";
import { ELEMENTS_IDS } from "constants/ElementsIds";
import { validationSchema } from "helpers/sire";
import { getBUFromLocalStorage } from "helpers/storage";
import { handleEnterPress } from "helpers/general";
import { Button, CheckBox, FieldText, Flex, FormBuilder, Spacer } from "components";
import { EventName, logAnalyticsEvent } from "utils/analytics";

interface SiresFormProps {
  sire?: GetSireById_sire;
  createMode: boolean;
}

const SiresForm: React.FC<SiresFormProps> = ({ sire = {}, createMode }) => {
  const history = useHistory();
  const BU: number = getBUFromLocalStorage();

  const { showNotification } = useContext(CommonContext);

  const [createSire, { loading: createSireLoading }] = useMutation<CreateSire, CreateSireVariables>(CREATE_SIRE);
  const [updateSire, { loading: updateSireLoading }] = useMutation<UpdateSire, UpdateSireVariables>(UPDATE_SIRE);

  const saveSire = async ({
    id,
    businessUnit,
    name,
    passportNumber,
    pedigree,
    registrationNumber,
    sire,
  }): Promise<void> => {
    const input = {
      id,
      businessUnit,
      name,
      passportNumber,
      pedigree,
      registrationNumber,
      sire,
    };

    try {
      input.businessUnit = BU;

      let success: boolean | null | undefined = null;

      if (!createMode) {
        const { data } = await updateSire({
          variables: {
            input,
          },
        });
        success = data?.updateSire?.success;
      } else {
        const { data } = await createSire({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: GET_MY_SIRES_LIST,
              variables: {
                businessUnitId: BU,
              },
            },
          ],
        });
        success = data?.createSire?.success;
        logAnalyticsEvent(EventName.SireCreate, { businessUnitId: BU, sireId: data?.createSire?.sire?.id });
      }

      if (!success) {
        showNotification({
          variant: "error",
          message: `Error ${createMode ? "creating" : "updating"} sire`,
        });
      } else {
        showNotification({
          message: `Sire successfully ${createMode ? "created" : "updated"}`,
        });
        history.push(SIRES);
      }
    } catch (e) {
      showNotification({
        variant: "error",
        message: "Something went wrong! Please try again later.",
      });
    }
  };

  const formOptions = {
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: sire,
    onSubmit: (formVars): Promise<void> => saveSire(formVars),
    validationSchema,
  };

  return (
    <FormBuilder {...formOptions}>
      {({ values, errors, handleChange, handleSubmit }): React.ReactNode => {
        const opts = (name, inputProps = {}): FieldTextProps => {
          return {
            error: errors[name],
            inputProps: {
              value: values[name] || "",
              name,
              onChange: handleChange,
              ...inputProps,
            },
          };
        };

        return (
          <>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText
                  label="Sire Name"
                  {...opts("name", {
                    autoFocus: true,
                  })}
                />
              </Flex>
            </Flex>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText label="Passport Number" {...opts("passportNumber")} />
              </Flex>
            </Flex>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <CheckBox
                  type="text"
                  label="Pedigree"
                  name="pedigree"
                  onChange={handleChange}
                  error={errors.pedigree}
                  value={!!values.pedigree}
                  checked={!!values.pedigree}
                  onKeyPress={(event): void => handleEnterPress(event, handleSubmit)}
                />
              </Flex>
            </Flex>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText label="Registration Number" {...opts("registrationNumber")} />
              </Flex>
            </Flex>
            <Flex container>
              <Flex item itemGutter xs={12} l={6} xl={4}>
                <FieldText label="Sire’s Sire" {...opts("sire")} />
              </Flex>
            </Flex>
            <Spacer baselineHeight={2} />
            <Flex container>
              <Flex item itemGutter>
                <Button
                  id={ELEMENTS_IDS.SIRE_EDIT_FORM_CANCEL_BUTTON}
                  caption="Cancel"
                  colour="grey"
                  variant="hollow"
                  onClick={(): void => history.push(SIRES)}
                />
              </Flex>
              <Flex item itemGutter>
                <Button
                  id={ELEMENTS_IDS.SIRE_EDIT_FORM_ADD_UPDATE_BUTTON}
                  disabled={createSireLoading || updateSireLoading}
                  requesting={createSireLoading || updateSireLoading}
                  caption={createMode ? "Add" : "Update"}
                  onClick={handleSubmit}
                />
              </Flex>
            </Flex>
          </>
        );
      }}
    </FormBuilder>
  );
};

export default SiresForm;
