import { getBUFromLocalStorage } from "helpers/storage";
import { useGetBusinessUnitQuery } from "generated/graphql";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

export type AnimalPreferencesReturnType = ReturnType<typeof useGetAnimalIdPreferences>;

export const useGetAnimalIdPreferences = () => {
  const rootStateBusinessUnit = useSelector((state: RootState) => state.businessUnits.activeBusinessUnit);
  const businessUnitId = Number(rootStateBusinessUnit?.id) || getBUFromLocalStorage();
  const countryCode = rootStateBusinessUnit?.country?.isoAlpha2;
  const { data, loading } = useGetBusinessUnitQuery({
    variables: {
      businessUnit: businessUnitId,
    },
    skip: !businessUnitId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const fetchedBUData = data?.businessUnit;
  const hiddenIdsByCountry = {
    [CountryISOCode.US]: ["passportNumber"],
    [CountryISOCode.GB]: [
      "trichId",
      "tsuBarcode",
      "herdDoggId",
      "uhfId",
      "breedrId",
      "name",
      "brucellosisId",
      "tattoo",
      "alternativeId",
    ],
    [CountryISOCode.AU]: ["herdDoggId"],
  };

  const hiddenIds =
    countryCode && hiddenIdsByCountry[countryCode]
      ? hiddenIdsByCountry[countryCode]
      : [hiddenIdsByCountry[CountryISOCode.US]];
  const activeIdPreferences =
    fetchedBUData?.identifierPreferencesActiveIds || rootStateBusinessUnit?.identifierPreferencesActiveIds;
  const primaryId =
    fetchedBUData?.identifierPreferencesPrimaryId || rootStateBusinessUnit?.identifierPreferencesPrimaryId;
  const secondaryId =
    fetchedBUData?.identifierPreferencesSecondaryId || rootStateBusinessUnit?.identifierPreferencesSecondaryId;
  const activeIdPreferencesByCountry = activeIdPreferences
    ? Object.entries(activeIdPreferences).reduce((acc, [key, value]) => {
        if (!hiddenIds.includes(key)) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: boolean | "ActiveIdentifierPreferencesType" })
    : {};

  const nameOfActiveIds = Object.keys(activeIdPreferences || {}).filter(
    (key) => activeIdPreferences && activeIdPreferences[key] === true,
  );

  return {
    nameOfActiveIds,
    activeIdPreferences,
    primaryId,
    secondaryId,
    loading,
    hiddenIds,
    activeIdPreferencesByCountry,
  };
};
