import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActiveIdentifierPreferencesType, BusinessUnitFullFragment, IdentifierEnumType } from "generated/graphql";

export interface IBusinessUnitsState {
  activeBusinessUnit: BusinessUnitFullFragment | null;
}

// reducer with initial state
export const initialBusinessUnitsState: IBusinessUnitsState = {
  activeBusinessUnit: null,
};

export const businessUnitsSlice = createSlice({
  name: "businessUnits",
  initialState: initialBusinessUnitsState,
  reducers: {
    setActiveBusinessUnit: (state, action: PayloadAction<BusinessUnitFullFragment | null>) => {
      state.activeBusinessUnit = action.payload;
    },
    setActiveAnimalIdentifierPreferences: (state, action: PayloadAction<ActiveIdentifierPreferencesType | null>) => {
      if (state.activeBusinessUnit) {
        state.activeBusinessUnit.identifierPreferencesActiveIds = action.payload;
      }
    },
    setIdentifierPreferencesPrimaryId: (state, action: PayloadAction<IdentifierEnumType | null>) => {
      if (state.activeBusinessUnit) {
        state.activeBusinessUnit.identifierPreferencesPrimaryId = action.payload;
      }
    },
    setIdentifierPreferencesSecondaryId: (state, action: PayloadAction<IdentifierEnumType | null>) => {
      if (state.activeBusinessUnit) {
        state.activeBusinessUnit.identifierPreferencesSecondaryId = action.payload;
      }
    },
  },
});

export const {
  setActiveBusinessUnit,
  setActiveAnimalIdentifierPreferences,
  setIdentifierPreferencesPrimaryId,
  setIdentifierPreferencesSecondaryId,
} = businessUnitsSlice.actions;

type RootState = {
  businessUnits: IBusinessUnitsState;
};

// Selectors
export const selectActiveBusinessUnitId = (state: RootState) => state.businessUnits.activeBusinessUnit?.id;
export const selectActiveBusinessUnit = (state: RootState) => state.businessUnits.activeBusinessUnit;

export const businessUnitsReducer = businessUnitsSlice.reducer;
