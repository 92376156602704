import { AnimalHistoryQueryVariables, useGetActivityTypesQuery } from "generated/graphql";
import { removeNothings } from "helpers/general/all";
import { Icon } from "components/Common/Icon";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { Button } from "twComponents/Button";
import { useState } from "react";
import { ICON_NAMES } from "constants/Icons";
import { Popover, PopoverContent, PopoverTrigger } from "twComponents/Popover";
import { SlidersHorizontal } from "lucide-react";
import { Check } from "lucide-react";
import { cn } from "utils/misc";

export const ActivityTypeFilters = ({
  setAnimalHistoryQueryVariables,
  animalHistoryQueryVariables,
}: {
  setAnimalHistoryQueryVariables: React.Dispatch<React.SetStateAction<AnimalHistoryQueryVariables>>;
  animalHistoryQueryVariables: AnimalHistoryQueryVariables;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { id: businessUnitId } = useGetCurrentBusinessUnit();
  const { data: activityTypeData } = useGetActivityTypesQuery({ variables: { businessUnit: +businessUnitId } });

  const activityTypes = removeNothings(activityTypeData?.activityTypes || []);

  const handleToggleFilter = (id: number, isSelected: boolean) => {
    const activityTypeIds = animalHistoryQueryVariables.activityTypes;

    const variables: AnimalHistoryQueryVariables = {
      ...animalHistoryQueryVariables,
      activityTypes:
        activityTypeIds && Array.isArray(activityTypeIds) && activityTypeIds.length > 0
          ? isSelected
            ? activityTypeIds.filter((activityTypeId) => activityTypeId !== id)
            : [...activityTypeIds, id]
          : [id],
    };

    setAnimalHistoryQueryVariables(variables);
  };

  return (
    <Popover onOpenChange={setIsOpen}>
      <Button variant="outline" className="mx-3 px-4" size="md" asChild>
        <PopoverTrigger>
          <SlidersHorizontal size={16} className="mr-2" />
          Filters{" "}
          {`(${
            (Array.isArray(animalHistoryQueryVariables.activityTypes) &&
              animalHistoryQueryVariables.activityTypes.length) ||
            0
          })`}
          <Icon className={`ml-2 ${isOpen ? "rotate-90" : "-rotate-90"}`} name="chevron" size="tiny" />
        </PopoverTrigger>
      </Button>
      <PopoverContent>
        <div className="mt-3">
          <h4 className="m-3 font-medium">Activities:</h4>
          <div>
            {activityTypes.map(({ id, name, slug }, index) => {
              const isSelected =
                Array.isArray(animalHistoryQueryVariables.activityTypes) &&
                (animalHistoryQueryVariables.activityTypes || []).includes(+id);

              return (
                <button
                  key={id}
                  className={cn(
                    "px-3 text-sm text-left w-full h-11",
                    isSelected ? "bg-gray-100" : "bg-white",
                    index === activityTypes.length - 1 ? "rounded-b-md" : "",
                  )}
                  onClick={() => handleToggleFilter(+id, isSelected)}
                  type="button"
                >
                  <Icon name={slug as keyof typeof ICON_NAMES} size="small" className="mr-2" />
                  {name}
                  <Check
                    color="green"
                    strokeWidth="3"
                    className={cn("float-right", isSelected ? "opacity-100" : "opacity-0")}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
