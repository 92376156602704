import { capitaliseFirstLetter, useTranslation } from "../";
import { useMemo } from "react";

export const useLocale = () => {
  const { t } = useTranslation();

  const localeText = (term: string, fallback: string, capitalise = false): string => {
    const translatedTerm = t(term, fallback);
    if (capitalise) {
      const capitaliseValue = capitaliseFirstLetter(translatedTerm);
      return capitaliseValue == null ? translatedTerm : capitaliseValue;
    }
    return translatedTerm;
  };

  const terms = useMemo(() => {
    // Actions Section
    const addAnimal = t("actions.addAnimal", "add animal");
    const addPreviousKeeper = t("actions.addPreviousKeeper", "add previous keeper");
    const administerMedicine = t("actions.administerMedicine", "administer medicine");
    const cancel = t("actions.cancel", "cancel");
    const create = t("actions.create", "create");
    const createAlert = t("actions.createAlert", "create alert");
    const createCattle = t("actions.createCattle", "create cattle");
    const createListing = t("actions.createListing", "create listing");
    const logActivity = t("actions.logActivity", "log activity");
    const logBirth = t("actions.logBirth", "log birth");
    const logWeight = t("actions.logWeight", "log weight");
    const manage = t("actions.manage", "manage");
    const moveAnimalToGroup = t("actions.moveAnimalToGroup", "move animal to group");
    const moveToPasture = t("actions.moveToPasture", "move to pasture");
    const quickActions = t("actions.quickActions", "quick actions");
    const quickScan = t("actions.quickScan", "quick scan");
    const quickWeigh = t("actions.quickWeigh", "quick weigh");
    const removeGroupMembership = t("actions.removeGroupMembership", "remove group membership");
    const saveAndContinue = t("actions.saveAndContinue", "save and continue");
    const saveAndCreateAnother = t("actions.saveAndCreateAnother", "save and create another");
    const saveAndFinish = t("actions.saveAndFinish", "save and finish");
    const scan = t("actions.scan", "scan");
    const scanPassport = t("actions.scanPassport", "scan passport");
    const setUpSession = t("actions.setUpSession", "setup session");
    const weigh = t("actions.weigh", "weigh");

    // Animal Section
    const animalCondition = t("animal.animalCondition", "animal condition");
    const avgDlwg = t("animal.avgDlwg", "ADG");
    const dlwg = t("animal.dlwg", "ADG");
    const dlwgFull = t("animal.dlwgFull", "average daily gain");
    const birthWeight = t("animal.birthWeight", "birth weight");
    const breed = t("animal.breed", "breed");
    const dam = t("animal.dam", "dam");
    const dof = t("animal.dof", "DOR");
    const eid = t("animal.eid", "EID");
    const dob = t("animal.dob", "DOB");
    const estimatedDob = t("animal.estimatedDob", "estimated DOB");
    const female = t("animal.female", "female");
    const male = t("animal.male", "male");
    const offspringVigor = t("animal.offspringVigor", "offspring vigor");
    const passport = t("animal.passport", "passport");
    const passportID = t("animal.passportID", "animal ID");
    const passportNumber = t("animal.passportNumber", "animal number");
    const pedigreeId = t("animal.pedigreeId", "registered ID");
    const sex = t("animal.sex", "sex");
    const sire = t("animal.sire", "sire");
    const vid = t("animal.vid", "VID");

    // Business Unit Section
    const farmId = t("businessUnit.farmId", "Location ID");
    const postcode = t("businessUnit.postcode", "postcode");

    // Crush Section
    const crush = t("crush.crush", "chute");
    const draft = t("crush.draft", "sort");
    const manageCrush = t("crush.manageCrush", "manage chute");
    const manageCrushSession = t("crush.manageCrushSession", "manage chute session");

    // General Section
    const surname = t("general.surname", "surname");
    const firstName = t("general.firstName", "first name");
    const abort = t("general.abort", "abort");
    const activityDate = t("general.activityDate", "activity date");
    const back = t("general.back", "back");
    const castrated = t("general.castrated", "castrated");
    const castration = t("general.castration", "castration");
    const colour = t("general.colour", "color");
    const colours = t("general.colours", "colors");
    const date = t("general.date", "date");
    const deadOnArrival = t("general.deadOnArrival", "dead on arrival");
    const enter = t("general.enter", "enter");
    const farm = t("general.farm", "ranch");
    const field = t("general.field", "pasture");
    const fields = t("general.fields", "pasture");
    const followingFieldsAreAdditional = t(
      "general.followingFieldsAreAdditional",
      "the following fields are additional",
    );
    const group = t("general.group", "group");
    const groups = t("general.groups", "groups");
    const help = t("general.help", "help");
    const herd = t("general.herd", "herd");
    const herds = t("general.herds", "herds");
    const hideAdditionalFields = t("general.hideAdditionalFields", "hide additional fields");
    const location = t("general.location", "location");
    const locations = t("general.locations", "locations");
    const name = t("general.name", "name");
    const note = t("general.note", "note");
    const notes = t("general.notes", "notes");
    const pasture = t("general.pasture", "pasture");
    const pen = t("general.pen", "pen");
    const performance = t("general.performance", "performance");
    const previousKeeper = t("general.previousKeeper", "previous keeper");
    const purchasePrice = t("general.purchasePrice", "purchase price");
    const route = t("general.route", "route");
    const selectAnOption = t("general.selectAnOption", "select an option");
    const selectDate = t("general.selectDate", "select a date");
    const showAdditionalFields = t("general.showAdditionalFields", "show additional fields");
    const stillBirth = t("general.stillBirth", "still birth");
    const supplyChain = t("general.supplyChain", "supply chain");
    const tagNotRecognised = t("general.tagNotRecognised", "tag not recognised");
    const type = t("general.type", "type");

    // Homepage Section
    const updatesAndAlerts = t("homepage.updatesAndAlerts", "updates and alerts");

    // Navigation Section
    const activeSessions = t("navigation.activeSessions", "active sessions");
    const addressBook = t("navigation.addressBook", "address book");
    const bankDetails = t("navigation.bankDetails", "bank details");
    const connectedSystems = t("navigation.connectedSystems", "connected systems");
    const finishedSessions = t("navigation.finishedSessions", "finished sessions");
    const helpAndSupport = t("navigation.helpAndSupport", "help and support");
    const home = t("navigation.home", "home");
    const logOut = t("navigation.logOut", "log out");
    const maps = t("navigation.maps", "maps");
    const medicineCabinet = t("navigation.medicineCabinet", "medicine cabinet");
    const menu = t("navigation.menu", "menu");
    const movement = t("navigation.movement", "movement");
    const movements = t("navigation.movements", "movements");
    const myCattle = t("navigation.myCattle", "my cattle");
    const notificationSettings = t("navigation.notificationSettings", "notification settings");
    const performanceTab = t("navigation.performanceTab", "performance");
    const seeTemplates = t("navigation.seeTemplates", "see templates");
    const sessions = t("navigation.sessions", "sessions");
    const settings = t("navigation.settings", "settings");
    const sireInformation = t("navigation.sireInformation", "sire information");
    const trades = t("navigation.trades", "trades");
    const userManagement = t("navigation.userManagement", "user management");
    const vetAndMed = t("navigation.vetAndMed", "vet and med");

    //MedicineCabinet Section
    const administrationMethod = t("medicineCabinet.administrationMethod", "administration method");
    const administrationSite = t("medicineCabinet.administrationSite", "administration site");
    const batchNumber = t("medicineCabinet.batchNumber", "batch number");
    const cost = t("medicineCabinet.cost", "cost");
    const dateAdded = t("medicineCabinet.dateAdded", "date added");
    const defaultDosage = t("medicineCabinet.defaultDosage", "default dosage");
    const expiryDate = t("medicineCabinet.expiryDate", "expiration date");
    const manufacturer = t("medicineCabinet.manufacturer", "manufacturer");
    const medicine = t("medicineCabinet.medicine", "medicine");
    const packSize = t("medicineCabinet.packSize", "number of containers");
    const quantity = t("medicineCabinet.quantity", "quantity");
    const totalVolume = t("medicineCabinet.totalVolume", "total volume");
    const unitsOfAdministration = t("medicineCabinet.unitsOfAdministration", "unit of measurement");
    const volumeOfUnit = t("medicineCabinet.volumeOfUnit", "volume of one container");
    const withdrawalPeriod = t("medicineCabinet.withdrawalPeriod", "withdrawal period");
    const withdrawalPeriodDaysCattleDairy = t("medicineCabinet.withdrawalPeriodDaysCattleDairy", "withdrawal period (cattle dairy)");
    const withdrawalPeriodDaysCattleMeat = t("medicineCabinet.withdrawalPeriodDaysCattleMeat", "withdrawal period (cattle meat)");
    const withdrawalPeriodDaysPigs = t("medicineCabinet.withdrawalPeriodDaysPigs", "withdrawal period (pigs)");
    const withdrawalPeriodDaysSheep = t("medicineCabinet.withdrawalPeriodDaysSheep", "withdrawal period (sheep)");

    // Validation Section
    const isRequired = t("validation.isRequired", "is required");
    const shouldBeString = t("validation.shouldBeString", "should be a string");
    const shouldBeNumber = t("validation.shouldBeNumber", "should be a number");
    const shouldBeBoolean = t("validation.shouldBeBoolean", "should be true or false");
    const shouldNotContainWhiteSpaces = t("validation.shouldNotContainWhiteSpace", "should not contain white spaces");
    const notInFuture = t("validation.notInFuture", "Date cannot be in the future");
    const notEarlierThanDOB = t("validation.notEarlierThanDOB", "Date cannot be earlier than DOB");
    const notEarlierThan = t("validation.notEarlierThan", "Date cannot be earlier than");
    const eidOrVisualIdRequires = t("validation.eidOrVisualIdRequires", "Please enter either EID or Visual ID");
    const cowsOnly = t("validation.cowsOnly", "test is for cows only");
    const bullsOnly = t("validation.bullsOnly", "tests is for bulls only");
    const alreadyAssociated = t("validation.alreadyAssociated", "This id is already associated with another animal");
    const alreadyAssociatedInCrush = t(
      "validation.alreadyAssociatedInCrush",
      "This id is already associated with an animal in ",
    );
    const tooShort = t("validation.tooShort", "is too short");

    // Notification Section
    const notification = t("notification.notification", "Notification");
    const success = t("notification.success", "Success"); 
    const error = t("notification.error", "Error");
    const warning = t("notification.warning", "Warning");
    const info = t("notification.info", "Info");
    const networkError = t("notification.networkError", "Network error");
    const addMedicineSuccess = t("notification.medicineSuccess", "Medicine successfully added to cabinet");
    const addMedicineError = t("notification.medicineError", "An error occurred while adding medicine to cabinet");

    return {
      // Actions Section
      addAnimal,
      addPreviousKeeper,
      administerMedicine,
      cancel,
      create,
      createAlert,
      createCattle,
      createListing,
      logActivity,
      logBirth,
      logWeight,
      manage,
      moveAnimalToGroup,
      moveToPasture,
      quickActions,
      quickScan,
      quickWeigh,
      removeGroupMembership,
      saveAndContinue,
      saveAndCreateAnother,
      saveAndFinish,
      scan,
      scanPassport,
      setUpSession,
      weigh,
      // Animal Section
      animalCondition,
      avgDlwg,
      dlwg,
      dlwgFull,
      birthWeight,
      breed,
      dam,
      dof,
      eid,
      dob,
      estimatedDob,
      female,
      male,
      offspringVigor,
      passport,
      passportID,
      passportNumber,
      pedigreeId,
      sex,
      sire,
      vid,
      // Business Unit Section
      farmId,
      postcode,
      // Crush Section
      crush,
      draft,
      manageCrush,
      manageCrushSession,
      // General Section
      abort,
      activityDate,
      back,
      castrated,
      castration,
      colour,
      colours,
      date,
      deadOnArrival,
      enter,
      farm,
      field,
      fields,
      followingFieldsAreAdditional,
      group,
      groups,
      help,
      herd,
      herds,
      hideAdditionalFields,
      location,
      locations,
      name,
      note,
      notes,
      pasture,
      pen,
      performance,
      previousKeeper,
      purchasePrice,
      route,
      selectAnOption,
      selectDate,
      showAdditionalFields,
      stillBirth,
      supplyChain,
      tagNotRecognised,
      type,
      // Homepage Section
      updatesAndAlerts,
      // Navigation Section
      activeSessions,
      addressBook,
      bankDetails,
      connectedSystems,
      finishedSessions,
      helpAndSupport,
      home,
      logOut,
      maps,
      medicineCabinet,
      menu,
      movement,
      movements,
      myCattle,
      notificationSettings,
      performanceTab,
      seeTemplates,
      sessions,
      settings,
      sireInformation,
      trades,
      userManagement,
      vetAndMed,
      surname,
      firstName,
      // MedicineCabinet Section
      administrationMethod,
      administrationSite,
      batchNumber,
      cost,
      dateAdded,
      defaultDosage,
      expiryDate,
      manufacturer,
      medicine,
      packSize,
      quantity,
      totalVolume,
      volumeOfUnit,
      unitsOfAdministration,
      withdrawalPeriod,
      withdrawalPeriodDaysCattleDairy,
      withdrawalPeriodDaysCattleMeat,
      withdrawalPeriodDaysPigs,
      withdrawalPeriodDaysSheep,
      // Validation Section
      isRequired,
      shouldBeString,
      shouldBeNumber,
      shouldBeBoolean,
      shouldNotContainWhiteSpaces,
      notInFuture,
      notEarlierThanDOB,
      notEarlierThan,
      eidOrVisualIdRequires,
      cowsOnly,
      bullsOnly,
      alreadyAssociated,
      alreadyAssociatedInCrush,
      tooShort,
      // Notification Section
      notification,
      success,
      error,
      warning,
      info,
      networkError,
      addMedicineSuccess,
      addMedicineError,
    };
  }, [t]);

  return { terms, localeText };
};
