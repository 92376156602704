import React, { useMemo } from "react";
import { getFormattedNumber } from "helpers/general";
import { getDaysOnFarm } from "helpers/myLivestock";
import { useGetCurrentBusinessUnit } from "hooks";
import { Flex, KeyValuePair, Paper, Spacer } from "components";
import { AnimalsPerformancePill } from "components/MyLivestock";
import { KeyValuePairProps } from "components/Common/KeyValuePair";
import { GQLAnimal } from "pages/MyLivestock/Animals/AnimalView/AnimalView";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";
import { EmptyStatesPerformanceTab } from "components/EmptyStates/PerformanceTab";

interface AnimalPerformanceBlockProps {
  animal: NonNullable<GQLAnimal>;
  isArchived: boolean;
}

interface AnimalPerformanceItems extends KeyValuePairProps {
  isHidden?: boolean;
}

export const AnimalPerformanceBlock: React.FC<AnimalPerformanceBlockProps> = (props) => {
  const { animal, isArchived = false } = props;
  const { weightUnits } = useGetCurrentBusinessUnit();
  const { terms } = useLocale();

  const {
    id,
    currentWeight,
    performanceCategory,
    dateMovedToFarm,
    growthRate,
    lastWeight,
    targetDailyLiveWeightGain,
    group,
    daysToTargetWeight,
    deadAt,
    dateLeftFarm,
  } = animal;

  const performanceInfo: AnimalPerformanceItems[] = useMemo(
    () => [
      {
        isHidden: isArchived,
        label: "Current estimated weight",
        unit: weightUnits,
        value: currentWeight ? getFormattedNumber(currentWeight) : null,
      },
      {
        label: "Last weight",
        unit: weightUnits,
        value: lastWeight?.value ? getFormattedNumber(lastWeight?.value) : null,
      },
      {
        label: capitaliseFirstLetter(terms.avgDlwg),
        unit: `${weightUnits}/day`,
        value: growthRate ? getFormattedNumber(growthRate) : null,
      },
      {
        isHidden: isArchived,
        label: `Target ${terms.avgDlwg}`,
        unit: `${weightUnits}/day`,
        value: targetDailyLiveWeightGain ? getFormattedNumber(targetDailyLiveWeightGain) : null,
      },
      {
        isHidden: isArchived,
        label: "Days Till Target Date",
        value: group?.daysTillTarget,
      },
      {
        isHidden: isArchived,
        label: "Days To Target Weight",
        value: daysToTargetWeight,
      },
      {
        isHidden: !dateMovedToFarm,
        label: `Time on ${terms.farm}`,
        value: `${getDaysOnFarm({ dateMovedToFarm, deadAt, dateLeftFarm })} days`,
      },
    ],
    [id, terms],
  );

  const performanceInfoLastWeight = performanceInfo.find((item) => item.label === "Last weight");
  const showPerformancePill = !isArchived && !!performanceCategory;

  return (
    <>
      <Paper>
        <Spacer baselineHeight={2} />
        <Flex container containerAlignItems="center" containerWrap="nowrap">
          <Flex item itemGutter itemGrow container>
            {showPerformancePill ? (
              <Flex item itemGutter container containerAlignItems="center">
                <AnimalsPerformancePill
                  label={`${performanceCategory} performance`.toLocaleUpperCase()}
                  performanceCategory={performanceCategory}
                />
              </Flex>
            ) : null}

            {performanceInfo.map(
              (item) =>
                !item?.isHidden && (
                  <Flex key={item?.label} item itemGutter container containerAlignItems="center">
                    <KeyValuePair label={item?.label} unit={item?.unit} value={item?.value} />
                  </Flex>
                ),
            )}
          </Flex>
        </Flex>

        <Spacer baselineHeight={2} />
      </Paper>
      {performanceInfoLastWeight?.value === null ? <EmptyStatesPerformanceTab /> : null}
    </>
  );
};
