import React from "react";
import { getBUFromLocalStorage } from "helpers/storage";
import { Button, Flex, Icon, Spacer, Text, Title } from "components";
import { EventName, logAnalyticsEvent } from "utils/analytics";

type Props = {
  onNextStep: () => void;
  onPrevStep: () => void;
  url: string | undefined;
};

const hrefForMail =
  // eslint-disable-next-line max-len
  "mailto:bcmsenquiries@rpa.gov.uk?subject=CTS Web service user ID and password request&body=Attach the form before sending";

export const ThirdStep: React.FC<Props> = ({ onNextStep, onPrevStep, url }) => {
  const businessUnitId = getBUFromLocalStorage();

  const handleDownloadForm = (): void => {
    if (url) {
      logAnalyticsEvent(EventName.RegulatoryRequestDownload, { businessUnitId });
      window.location.assign(url);
    }
  };

  const handleEmailForm = (): void => {
    logAnalyticsEvent(EventName.RegulatoryRequestEmail, { businessUnitId });
    window.location.assign(hrefForMail);
  };

  return (
    <Flex container xs={8} containerJustifyContent="center" containerDirection="column">
      <Flex container xs={6} containerJustifyContent="center">
        <Title tertiary>STEP 3 - Download, attach and email your form to CTS Online</Title>
        <Spacer baselineHeight={1} />
        <Text smallest>
          If you have an existing farm management system connected to BCMS, requesting these details will reset the
          password for that linked software.
        </Text>
        <Spacer baselineHeight={2} />
      </Flex>
      <Spacer baselineHeight={1} />
      <Flex container xs={6} containerJustifyContent="center">
        <Flex item itemGutter xs={2}>
          <Flex container containerJustifyContent="center" xs="fill">
            <Title>1</Title>
          </Flex>
          <Flex container containerJustifyContent="center" xs="fill">
            <Icon colour="black" name="downloadFile" size="extraLarge" ariaLabelledBy="Download form" />
          </Flex>
          <Spacer baselineHeight={1} />
          <Flex container containerJustifyContent="center" xs="fill">
            <Title secondary>Download Form</Title>
          </Flex>
          <Spacer baselineHeight={1} />
          <Button caption="Download now" onClick={handleDownloadForm} width="full" />
          <Spacer baselineHeight={1} />
          <Flex container xs="fill">
            <Text smallest>Check your Downloads folder once you have downloaded.</Text>
          </Flex>
        </Flex>
        <Flex item itemGutter xs={2}>
          <Flex container containerJustifyContent="center" xs="fill">
            <Title>2</Title>
          </Flex>
          <Flex container containerJustifyContent="center" xs="fill">
            <Icon colour="black" name="emailAttachment" size="extraLarge" ariaLabelledBy="Email attachment" />
          </Flex>
          <Spacer baselineHeight={1} />
          <Flex container containerJustifyContent="center" xs="fill">
            <Title secondary>Send email & attach form</Title>
          </Flex>
          <Spacer baselineHeight={1} />
          <Button caption="Send email" onClick={handleEmailForm} width="full" />
          <Spacer baselineHeight={1} />
          <Flex container xs="fill">
            <Text smallest>This will open in a new window. </Text>
            <Spacer />
            <b>
              <Text smallest>IMPORTANT!</Text>
            </b>
            <Spacer />
            <Text smallest> Remember to attach your form.</Text>
          </Flex>
        </Flex>
      </Flex>
      <Spacer baselineHeight={5} />
      <Flex container containerJustifyContent="center">
        <Flex item itemGutter>
          <Button caption="Back" variant="hollow" colour="grey" onClick={onPrevStep} />
        </Flex>
        <Flex item itemGutter>
          <Button caption="Finish" onClick={onNextStep} />
        </Flex>
      </Flex>
    </Flex>
  );
};
