import { useEffect, useMemo, useState } from "react";
import { getBUFromLocalStorage } from "helpers/storage";
import {
  GetInventoryInfoQuery,
  GetStockItemQuery,
  useGetInventoryInfoQuery,
  useGetStockItemLazyQuery,
} from "generated/graphql";

export type MedicineStockItem = GetStockItemQuery["stockItem"];
export type MedicineInventoryInfo = GetInventoryInfoQuery["inventoryInfo"];

export const useAdministerMedicine = () => {
  const [medicine, updateMedicine] = useState<string | null>(null);
  const [medicineDetails, updateMedicineDetails] = useState<MedicineStockItem | null>(null);
  const [batches, updateBatches] = useState<MedicineInventoryInfo>([]);
  const [batch, updateBatch] = useState<NonNullable<MedicineInventoryInfo>[number] | null>(null);

  const businessUnitId = getBUFromLocalStorage();

  // get medicine stock items from BE
  const { data: medicinesData, refetch } = useGetInventoryInfoQuery({
    variables: {
      businessUnitId,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const [getMedicineDetails] = useGetStockItemLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    onCompleted: (data) => {
      updateMedicineDetails(data?.stockItem);
    },
  });

  const refreshMedicines = (): void => {
    refetch();
  };

  // set currently selected medicine to state
  const setMedicine = (medicine: string | null): void => updateMedicine(medicine);

  // set currently selected batch to state
  const setBatch = (batchId: string | null): void => {
    const filteredBatch = (batches || []).find((item) => item?.id === batchId) || null;

    updateBatch(batchId ? filteredBatch : null);
  };

  // extract medicine names from batch data array (not ideal but here we are)
  const medicines = useMemo(() => {
    if (!medicinesData?.inventoryInfo) return [];

    return medicinesData?.inventoryInfo
      ?.reduce((acc: string[], medicine) => {
        // hide batches with no name, volume remaining or original volume
        if (!medicine?.name || !medicine.totalOriginalVolume || !medicine?.totalRemainingVolume || medicine?.isArchived)
          return acc;

        return !acc.includes(medicine?.name) ? [...acc, medicine.name] : acc;
      }, [])
      .sort();
  }, [medicinesData]);

  // update batch list when new medicine is selected
  useEffect(() => {
    if (!medicinesData?.inventoryInfo) return;

    const selectedMedicine = medicinesData.inventoryInfo.find((med) => med?.name === medicine);
    if (selectedMedicine && selectedMedicine?.stockItemId) {
      getMedicineDetails({
        variables: {
          businessUnitId,
          id: selectedMedicine?.stockItemId,
        },
      });
    }

    // get batches with a name, remaining volume and original volume
    const filteredBatches = medicinesData.inventoryInfo.filter(
      (item) => item?.totalOriginalVolume && item?.totalRemainingVolume && item?.name === medicine && !item.isArchived,
    );
    updateBatches(filteredBatches);
  }, [medicine]);

  return {
    batch,
    batches,
    medicineDetails,
    medicines,
    refreshMedicines,
    setMedicine,
    setBatch,
  };
};
