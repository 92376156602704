import { FC } from "react";
import { Button, Flex, Spacer } from "components";
import { WizardOptions } from "import/components/WizardOptions";
import { BatchUploadFormat } from "hooks/useGetBatchUploadFormats";
import { useHasFeature } from "hooks/useHasFeature";

interface Props {
  activityTypeId: string;
  onClickBack: () => void;
  onSelect: (batchUploadFormat: BatchUploadFormat) => void;
  batchUploadFormats: BatchUploadFormat[];
}

export const ImportWizardChooseTemplate: FC<Props> = ({
  activityTypeId,
  onClickBack,
  onSelect,
  batchUploadFormats,
}) => {
  const hasMoveOffCsvUploadFeature = useHasFeature("MOVE_OFF_DRAFT_CSV_IMPORT");
  const isOshkosh = useHasFeature("OSHKOSH");

  const batchUploadFormatsForType = batchUploadFormats.filter(
    (activityTemplate) => activityTemplate.activityType?.id === activityTypeId,
  );

  const visibilityRules: Record<string, boolean> = {
    // 'activity-move-off-farm' and 'cmd-mark-off-farm' are the same feature
    // but one uses the second modern driver schema, while the first uses
    // the older activities method. Even though the first will be deactivated,
    // it may be worth keeping the visibility rule in case it is ever
    // accidentally reactivated.
    "activity-move-off-farm": !hasMoveOffCsvUploadFeature,
    "cmd-mark-off-farm": !hasMoveOffCsvUploadFeature,
    "cmd-deliver-out": hasMoveOffCsvUploadFeature,
    ezfeed: isOshkosh,
  };

  const filteredActivityTemplates = batchUploadFormatsForType.filter((activityTemplate) => {
    const isUploadFormatVisible: boolean | undefined = visibilityRules[activityTemplate.slug];
    if (isUploadFormatVisible === undefined) {
      return true;
    }

    return isUploadFormatVisible;
  });

  return (
    <>
      <WizardOptions>
        {filteredActivityTemplates.map((batchUploadFormat) => {
          const handleClick = () => onSelect(batchUploadFormat);

          return (
            <WizardOptions.Option
              key={batchUploadFormat.slug}
              name={batchUploadFormat.name || batchUploadFormat.slug}
              onClick={handleClick}
            />
          );
        })}
      </WizardOptions>

      <Spacer baselineHeight={4} />

      <Flex container containerJustifyContent="space-between">
        <Button caption="Back" colour="grey" onClick={onClickBack} variant="hollow" />
      </Flex>
    </>
  );
};
