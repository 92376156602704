import { AnimalHistoryEventFragment, SourceObjectFragmentFragment, TransactionRecordEnumType } from "generated/graphql";
import getSymbolFromCurrency from "currency-symbol-map";
import { useGetCurrentBusinessUnit } from "hooks/useGetCurrentBusinessUnit";
import { Button } from "twComponents/Button";
import { FINANCIAL_TRANSACTIONS, TRANSACTIONS } from "constants/Routes";
import { Link } from "react-router-dom";
import { Tooltip } from "twComponents/Tooltip";
import { ICON_NAMES } from "constants/Icons";
import { isType } from "../helpers";
import { HistoryEvent } from "../HistoryEvent";
import { useContacts } from "settings/hooks/useContacts";
import { Fragment } from "react";

type ActivityLog = Extract<SourceObjectFragmentFragment, { __typename: "ActivityLog" }>;
export type FinancialTransactionAnimal = Extract<
  SourceObjectFragmentFragment,
  { __typename: "FinancialTransactionAnimal" }
>;

export const HistorySection = ({
  dateLabel,
  events,
  handleDelete,
}: {
  dateLabel: string;
  events: AnimalHistoryEventFragment[];
  handleDelete: (activityId: string) => void;
}) => {
  const { currencyCodeISO } = useGetCurrentBusinessUnit();
  const { findContact } = useContacts();

  return (
    <section className="relative pl-8 pt-6 ml-4 border-l border-t border-dashed border-gray-600">
      <div className="bg-white w-4 h-4 absolute left-[-0.5rem] top-[-0.5rem] flex justify-center">
        <div className=" bg-gray-900 w-2 h-2 rounded-full self-center" />
      </div>
      <h3 className="absolute top-[-0.8rem] bg-gray-50 font-semibold px-4">{dateLabel}</h3>
      <ul>
        {events.map((event, index) => {
          const sourceObject = event.sourceObject;

          if (!sourceObject) return null;

          if (isType<ActivityLog>(sourceObject, "ActivityLog")) {
            return (
              <Fragment key={sourceObject.id}>
                <HistoryEvent
                  isDeletable={event.isDeletable}
                  handleDelete={handleDelete}
                  id={sourceObject.id}
                  iconName={event.activityType.slug as keyof typeof ICON_NAMES}
                  title={
                    <span className="text-sm">
                      {sourceObject.payload?.shortDescription}{" "}
                      {sourceObject.payload?.valueDescription ? (
                        <span className="font-semibold">
                          {sourceObject.payload.valueDescription
                            ? `${sourceObject.payload.valueDescription}`
                            : undefined}
                        </span>
                      ) : null}
                    </span>
                  }
                  description={sourceObject?.payload?.longDescription}
                  happenedAt={event.happenedAt}
                />
                <div className={index === events.length - 1 ? "h-10" : "h-4"} />
              </Fragment>
            );
          }
          if (isType<FinancialTransactionAnimal>(sourceObject, "FinancialTransactionAnimal")) {
            const contact = findContact(sourceObject.financialTransaction.contact?.id)?.name || "N/A";

            const {
              allocatedValue,
              financialTransaction: { id, recordType },
            } = sourceObject;

            return (
              <Fragment key={id}>
                <HistoryEvent
                  id={id}
                  isDeletable={false}
                  title={
                    <div>
                      <span className="text-sm">
                        {`${
                          recordType === TransactionRecordEnumType.Sale ? "Sold to" : "Purchased from"
                        } ${contact} for ${getSymbolFromCurrency(currencyCodeISO)}${allocatedValue}`}
                      </span>
                      <span className="pl-2">{"\u00B7"}</span>
                      <Tooltip title="Go to transaction" className="inline-block">
                        <Button asChild variant="link" className="text-sm font-light">
                          <Link to={`${FINANCIAL_TRANSACTIONS}${TRANSACTIONS}/${id}`}>Link to transaction</Link>
                        </Button>
                      </Tooltip>
                    </div>
                  }
                  happenedAt={event.happenedAt}
                  iconName={currencyCodeISO === "GBP" ? "poundSign" : "dollarSign"}
                  metrics={[
                    {
                      key: "Type: ",
                      value: recordType ? `${recordType}` : "N/A",
                    },
                  ]}
                />
                <div className={index === events.length - 1 ? "h-10" : "h-4"} />
              </Fragment>
            );
          }

          return null;
        })}
      </ul>
    </section>
  );
};
