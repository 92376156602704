import { Route, Redirect, Switch } from "react-router-dom";
import { DASHBOARD } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { CommonRoutes, PATHS as commonPaths } from "./CommonRoutes";
import FarmerRoutes, { PATHS as farmerPaths } from "pages/Dashboard/routes/FarmerRoutes";
import { ImportsRoutes, PATHS as importsPaths } from "pages/Dashboard/routes/ImportsRoutes";
import LivestockRoutes, { PATHS as livestockPaths } from "pages/Dashboard/routes/LivestockRoutes";
import ProcessorRoutes, { PATHS as processorPaths } from "pages/Dashboard/routes/ProcessorRoutes";
import SettingsRoutes, { PATHS as settingsPaths } from "pages/Dashboard/routes/SettingsRoutes";
import SupplierRoutes, { PATHS as supplierPaths } from "pages/Dashboard/routes/SupplierRoutes";
import TradingRoutes, { PATHS as tradingPaths } from "trading/Routes";
import { DeliveriesRoutes, PATHS as deliveriesPaths } from "deliveries/routes";
import { useHasFeature } from "hooks";
import { FinancialRoutes, PATHS as financialPaths } from "financial/routes";
import { ChuteRoutes, PATHS as chutePaths } from "chute/routes";
import { getBUTypeFromLocalStorage } from "helpers/storage";

const paths = [
  ...commonPaths,
  ...importsPaths,
  ...livestockPaths,
  ...deliveriesPaths,
  ...settingsPaths,
  ...supplierPaths,
  ...tradingPaths,
  ...processorPaths,
  ...farmerPaths,
  ...financialPaths,
  ...chutePaths,
];

export const DashboardRoutes: React.FC = () => {
  const selectedBUType = getBUTypeFromLocalStorage();

  const showOutwardMovements = useHasFeature("OUTWARD_MOVEMENTS");
  const showFinancial = useHasFeature("FINANCIAL_TRANSACTIONS");

  const isProcessor: boolean = selectedBUType === BU_TYPES.processor;

  return (
    <Switch>
      <Route exact path={paths}>
        {isProcessor ? <ProcessorRoutes /> : <FarmerRoutes />}
        <ImportsRoutes />
        {showOutwardMovements ? <DeliveriesRoutes /> : null}
        {showFinancial ? <FinancialRoutes /> : null}
        <ChuteRoutes />
        <LivestockRoutes />
        <SettingsRoutes />
        <SupplierRoutes />
        <TradingRoutes />
        <CommonRoutes />
      </Route>
      <Redirect to={DASHBOARD} />
    </Switch>
  );
};
