import { useCallback, useContext, useMemo, useState } from "react";
import { useGetCurrentBusinessUnit } from "hooks";
import { getErrorMessage, removeNothings } from "helpers/general";
import { Table } from "twComponents/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate, getInternationalDateFormat } from "feShared/utils/dates";
import { EmptyState } from "components/EmptyStates/Default";
import { TABLE_IDS } from "constants/Interface";
import { useLocale } from "helpers/translations/src";
import { Button } from "twComponents/Button";
import { AmsChuteExportsQuery, useAmsChuteExportsQuery } from "generated/graphql";
import { useAppSelector } from "state/store";
// import { useLazyPaginatedQuery } from "hooks/useLazyPaginatedQuery";
import { getDraftGroups, getSessionName } from "chute/helpers";
import { CommonContext } from "config/commonProvider";
import { Pill } from "twComponents/Pill";

export type AmsChuteExport = NonNullable<
  NonNullable<NonNullable<AmsChuteExportsQuery["amsChuteExports"]>["edges"]>[number]
>["node"];

export type AmsChuteSession = AmsChuteExport["session"];

export const ChuteSessionsTable = () => {
  const { countryCode } = useGetCurrentBusinessUnit();
  const { shortDateFormat } = getInternationalDateFormat(countryCode);
  const businessUnitId = useAppSelector((state) => state?.businessUnits?.activeBusinessUnit?.id) || "";
  const { terms } = useLocale();
  const { showNotification } = useContext(CommonContext);
  const [urlOfFileBeingDownloaded, setUrlOfFileBeingDownloaded] = useState<string | undefined>();

  const handleDownload = useCallback(
    async (url: string, name: string) => {
      try {
        setUrlOfFileBeingDownloaded(url);

        const response = await fetch(url, {
          method: "GET",
        });

        setUrlOfFileBeingDownloaded(undefined);

        if (!response.ok) {
          showNotification({
            variant: "error",
            message: "Failed to download file.",
          });
          return;
        }

        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error: unknown) {
        setUrlOfFileBeingDownloaded(undefined);
        showNotification({
          variant: "error",
          message: getErrorMessage(error),
        });
      }
    },
    [showNotification],
  );

  const { data, loading } = useAmsChuteExportsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: {
      businessUnit: businessUnitId,
      first: 100,
    },
  });

  // const [getAmsChuteExports, { loading, data }] = useAmsChuteExportsLazyQuery({
  //   fetchPolicy: "cache-and-network",
  //   nextFetchPolicy: "cache-only",
  // });

  const { accessor } = createColumnHelper<AmsChuteExport>();
  // TODO: Needs the backend to send the count of total sessions...
  // const totalNumberOfItems = data?.amsChuteExports?.edges?.length || 0;

  // const { paginationProps } = useLazyPaginatedQuery({
  //   initialQueryVariables: { businessUnit: businessUnitId },
  //   lazyQuery: getAmsChuteExports,
  //   tableId: TABLE_IDS.CHUTE_SESSION_TABLE,
  //   totalNumberOfItems: totalNumberOfItems,
  //   pageInfo: data?.amsChuteExports?.pageInfo,
  //   loading,
  // });

  const columns = useMemo(
    () => [
      accessor("session", {
        header: "Name",
        cell: ({ row }) => {
          const session = row.original.session;
          return getSessionName(session) || "-";
        },
      }),
      accessor("session.sessionConfig", {
        header: "Group Name(s)",
        cell: (info) => {
          const sessionConfig = info.getValue();
          const draftGroups = getDraftGroups(sessionConfig);

          if (draftGroups.length === 0) {
            return "\u2014";
          }

          return draftGroups.map((group, index) => {
            return <Pill className={`${index === 0 ? "" : "ml-2"}`} key={group.id} caption={group.name || ""} />;
          });
        },
      }),
      accessor("session.createdAt", {
        header: "Date",
        cell: ({ row }) => formatDate(row.original?.session?.createdAt, shortDateFormat) || "-",
      }),
      accessor("amsSidekickXmlUrl", {
        header: "Data File",
        maxSize: 20,
        cell: ({ row }) => {
          const session = row.original.session;
          const fileUrl = row.original.amsSidekickXmlUrl;
          const fileName = `download_${getSessionName(session)}_${session?.id}.xml`;

          return fileUrl ? (
            <Button
              variant="outline"
              onClick={() => handleDownload(fileUrl, fileName)}
              disabled={loading || !!urlOfFileBeingDownloaded}
              loading={urlOfFileBeingDownloaded === fileUrl}
            >
              Download
            </Button>
          ) : (
            "-"
          );
        },
      }),
    ],
    [accessor, handleDownload, loading, shortDateFormat, urlOfFileBeingDownloaded],
  );

  const chuteSessions = useMemo(
    () => removeNothings(data?.amsChuteExports?.edges.map((session) => session?.node) || []),
    [data?.amsChuteExports?.edges],
  );

  return (
    <Table
      // paginationProps={paginationProps}
      tableId={TABLE_IDS.CHUTE_SESSION_TABLE}
      options={{ data: chuteSessions, columns, enableSorting: false }}
      loading={loading}
      emptyStateComponent={<EmptyState title={`You haven't created any ${terms.crush} sessions yet.`} description="" />}
    />
  );
};
