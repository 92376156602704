import { normaliseUnit } from "../utils";
import { InventoryInfoType } from "generated/graphql";

/**
 * @description gets a 'normalized' unit string from a batch
 * @param batch the batch object
 * @returns normlized unit as string
 */
export const getBatchUnit = (batch: InventoryInfoType): string => {
  return normaliseUnit(batch?.volumeUnits || "");
};
