import { InputSelectOption } from "components/Common/Field/Select";
import { MedicineInventoryInfo } from "hooks/useAdministerMedicine";

export const getBatchOptions = (batches: MedicineInventoryInfo): (InputSelectOption | null)[] => {
  if (!batches || !batches.length) return [];

  const filteredBatches = batches.filter(
    (batch) => batch?.totalRemainingVolume && batch?.totalOriginalVolume && !batch.isArchived,
  );

  return filteredBatches?.map((batch) => ({
    key: `${batch?.batchNumber}-${batch?.stockItemId}`,
    label: `${batch?.batchNumber} - ${batch?.totalRemainingVolume}/${batch?.totalOriginalVolume} ${batch?.volumeUnits} remaining`,
    value: batch?.batchNumber || "",
  }));
};
