import React, { FC, useContext, useState, useEffect, useMemo, useCallback } from "react";
import {
  AnimalFragment,
  useGetAnimalsPageLazyQuery,
  useGroupsInfoQuery,
  GetAnimalsPageQueryVariables,
  useAnimalsExportAsyncMutation,
  AnimalSorting,
  IdentifierType,
  AnimalStatus,
  UploadCsvDateHintEnumType,
  AnimalExportColumnInput,
} from "generated/graphql";

import { CellProps, Column } from "react-table";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";

import { TableOptionsContext } from "config/tableOptionsProvider";
import { getDaysOnFarm, getFieldName, getMonthsAge, getShortBreedrId } from "helpers/myLivestock";
import { getBUFromLocalStorage, getTableSettingsFromLocalStorage } from "helpers/storage";
import { getFormattedNumber, removeNothings } from "helpers/general";
import {
  areAllRowsSelected,
  reorderColumns,
  createColumnObject,
  sortRuleToAnimalSorting,
  getAnimalExportColumn,
  getBooleanFilter,
  getDnaStatus,
  getAnimalStatus,
} from "./helpers";
import {
  getCalvingEaseScore,
  getServiceType,
} from "components/MyLivestock/Animals/AnimalInfoBlock/helpers/getAnimalInfoItems";
import {
  useFormatDate,
  useGetCurrentBusinessUnit,
  useStateSpecies,
  useGetAnimalIdPreferences,
  useHasFeature,
} from "hooks";

import TableActionColumn from "pages/MyLivestock/Animals/TableActionColumn";
import { AnimalActions } from "animals/AnimalActions";
import { AnimalPregnancyLabel, AnimalsPerformancePill, AnimalsSexClassLabel } from "components/MyLivestock";
import { BaseInput } from "components/Common/Field/BaseInput";
import { Button } from "twComponents/Button";
import { Icon, InputCheckbox, LoadingOverlay, Pill, Table, TableHeaderCaptions, Tooltip } from "components";
import { renderPedigreeStatus, renderStatus } from "components/MyLivestock/Animals/AnimalsTable";
import { SelectedAnimalsTable } from "animals/SelectedAnimalsList";
import { TABLE_IDS } from "constants/Interface";
import { Checkbox, getActionsColumn, TablePaginationTarget } from "components/Common/Table";
import { ExportAnimalsButton } from "components/MyLivestock/Animals/AnimalFiltersBlock/ExportAnimalsButton";
import { PregnancyStatus, Props, Sex } from "./types";
import { Tag } from "tags/components/Tag";
import { ColumnOptionsModal } from "components/Common/Table/ColumnOptionsModal";
import {
  COLUMN_IDS,
  DEFAULT_COLUMN_IDS,
  DEFAULT_COLUMN_IDS_CSV_ERRORS,
  DEFAULT_COLUMN_IDS_US,
  DEFAULT_COLUMN_IDS_US_ARCHIVE,
} from "./constants";
import { useDispatch } from "react-redux";
import { setMenuState, setTaskId } from "state/asyncJobSlice";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { capitaliseFirstLetter, capitaliseFirstLetterOfEachWord } from "helpers/translations/src/format";
import { convertSnakeToCamelCase, identifierNameLookup } from "components/Settings/IdPreferencesForm/helpers";
import { useLocale } from "helpers/translations/src/useLocale";
import { getLoadingStateRows } from "utils/misc";
import { usePagination } from "twComponents/PaginationControl/helpers";
import { PaginationControl } from "twComponents/PaginationControl";
import { AnimalPedigreeLabel } from "components/MyLivestock/Animals/AnimalPedigreeLabel";
import { EmptyStatesMyCattleTab } from "components/EmptyStates/MyCattleTab";
import { EmptyStatesNoResults } from "components/EmptyStates/NoResults";
import { AnimalsBreedPercentages } from "components/MyLivestock/Animals/AnimalsBreedPercentages";
import { useAnimalFilters } from "hooks/useAnimalFilters";
import { AnimalFilters } from "animals/AnimalFilters";
import { ICON_NAMES } from "constants/Icons";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";

export const AnimalsList: FC<Props> = ({
  tableId,
  fromPage,
  isArchive = false,
  isCSVErrors = false,
  fieldId,
  groupId,
  tagId,
  onPressEditAnimal,
  showSelectColumn,
  showAnimalActionButtons,
  showIndividualAnimalActionButtons = true,
  excludedFilters,
  tableFilterIdentifier,
  rowStyles,
  animalIds,
  animalStatus,
  resetSelectedAnimalsOnUnmount = true,
  animalTypeIds,
  showFiltersButton = true,
  showExportButton = true,
  showPagination = true,
  hasOnAnimalClick = true,
}) => {
  const businessUnitId = getBUFromLocalStorage();
  const history = useHistory();
  const { getTableOptions, setTableOptions } = useContext(TableOptionsContext);
  const [sortBy, setSortBy] = React.useState<AnimalSorting>(AnimalSorting.UpdatedAtDesc);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSelectedAnimalsView, setIsSelectedAnimalsView] = useState(false);
  const { getFormatDate } = useFormatDate();
  const { weightUnits, countryIsoCodeUpperCase } = useGetCurrentBusinessUnit();
  const countryCode = {
    us: countryIsoCodeUpperCase === CountryISOCode.US,
    gb: countryIsoCodeUpperCase === CountryISOCode.GB,
    au: countryIsoCodeUpperCase === CountryISOCode.AU,
  };
  const { activeSpecies } = useStateSpecies();
  const activeSpeciesTypeId = activeSpecies?.id ? parseInt(activeSpecies?.id) : undefined;
  const { terms } = useLocale();
  const [columnOptionsModalOpen, setColumnOptionsModalOpen] = useState(false);
  const currentLocalStorageSettings = getTableSettingsFromLocalStorage(tableId) || [];
  const currentLocalStorageColumns = currentLocalStorageSettings?.columns || [];
  const { primaryId, secondaryId, activeIdPreferencesByCountry } = useGetAnimalIdPreferences();
  const primaryIdCamelCase = convertSnakeToCamelCase(primaryId);
  const secondaryIdCamelCase = convertSnakeToCamelCase(secondaryId);
  const [exportCsv] = useAnimalsExportAsyncMutation();
  const { areFiltersDirty, handleToggleFilterVisibility, filters, showFilters } =
    useAnimalFilters(tableFilterIdentifier);

  const isCreateOnDeliveriesEnabled = useHasFeature("CREATE_INWARD_MOVEMENTS");

  const dispatch = useDispatch();
  const taskId = useSelector((state: RootState) => state.asyncJob.taskId);

  const useGenetics = useHasFeature("GENETICS");
  const trioVerified = useHasFeature("TRIO_VERIFIED");

  const { data: groupsData } = useGroupsInfoQuery({
    variables: {
      businessUnitId,
      animalTypeIds: fromPage === "field" ? undefined : activeSpeciesTypeId,
    },
  });

  const numberOfGroups = groupsData?.groupsExtended?.groupsCount;

  const tableOptions = getTableOptions<AnimalFragment>(tableId);
  const sortByValue = tableOptions?.sortBy;
  const selectedRowIds = tableOptions?.selectedRowsIds;

  const [getAnimals, { data, loading }] = useGetAnimalsPageLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const totalNumberOfAnimals = data?.animals?.count ?? 0;

  const { paginationQueryVariables, ...paginationProps } = usePagination({
    totalNumberOfItems: totalNumberOfAnimals,
    pageInfo: data?.animals?.pageInfo,
    loading,
    tableId,
  });

  const {
    controls: { setPageNumber },
    paginationInfo: { pageSize },
  } = paginationProps;

  useEffect(() => {
    const variables: GetAnimalsPageQueryVariables = {
      search: searchTerm,
      searchIds: [
        IdentifierType.EId,
        primaryId ? (primaryId as unknown as IdentifierType) : IdentifierType.VisualId,
        secondaryId ? (secondaryId as unknown as IdentifierType) : IdentifierType.VisualId,
      ],
      breeds: filters.breeds || undefined,
      isMale: getBooleanFilter(filters.sex, Sex.MALE, Sex.FEMALE),
      isPregnant: getBooleanFilter(filters.pregnancyStatus, PregnancyStatus.PREGNANT, PregnancyStatus.NOTPREGNANT),
      minDob: filters.dateOfBirth.from || undefined,
      maxDob: filters.dateOfBirth.to || undefined,
      minDof: filters.dateOnFarm.from || undefined,
      maxDof: filters.dateOnFarm.to || undefined,
      minDepartedAt: filters.dateLeftFarm.from || undefined,
      maxDepartedAt: filters.dateLeftFarm.to || undefined,
      fields: fieldId || filters.fields || undefined,
      groups: groupId || filters.groups || undefined,
      businessUnitId: businessUnitId,
      last: paginationQueryVariables.last,
      before: paginationQueryVariables.before,
      first: paginationQueryVariables.first,
      after: paginationQueryVariables.after,
      orderBy: sortBy ? [sortBy] : undefined,
      animalTags: tagId ? [tagId] : filters.tagIds.length > 0 ? filters.tagIds : undefined,
      animalTypeIds: animalTypeIds ? animalTypeIds : fromPage === "field" ? undefined : activeSpeciesTypeId,
      animalIds,
      status: animalStatus ? animalStatus : !isArchive ? AnimalStatus.Active : AnimalStatus.Inactive,
      isRegulatorySynced: filters.isRegulatorySynced,
      isInWithdrawal: filters.isInWithdrawal,
      minAgeMonths: filters.ageInMonths.from ? parseInt(filters.ageInMonths.from) : undefined,
      maxAgeMonths: filters.ageInMonths.to ? parseInt(filters.ageInMonths.to) : undefined,
      minGrowthRate: filters.growthRate.from ? parseInt(filters.growthRate.from) : undefined,
      maxGrowthRate: filters.growthRate.to ? parseInt(filters.growthRate.to) : undefined,
      minPregnancyDueDate: filters.pregnancyDueDate.from || undefined,
      maxPregnancyDueDate: filters.pregnancyDueDate.to || undefined,
      maxDaysSinceWeighed: filters.daysSinceWeighed.from ? parseInt(filters.daysSinceWeighed.from) : undefined,
      minDaysSinceWeighed: filters.daysSinceWeighed.to ? parseInt(filters.daysSinceWeighed.to) : undefined,
      minDeliveryDate: filters.tradingDeliveryDate.from || undefined,
      maxDeliveryDate: filters.tradingDeliveryDate.to || undefined,
      minLastWeight: filters.lastWeight.from ? parseInt(filters.lastWeight.from) : undefined,
      maxLastWeight: filters.lastWeight.to ? parseInt(filters.lastWeight.to) : undefined,
      minEstimatedWeight: filters.estimatedWeight.from ? parseInt(filters.estimatedWeight.from) : undefined,
      maxEstimatedWeight: filters.estimatedWeight.to ? parseInt(filters.estimatedWeight.to) : undefined,
      minRegulatorySyncDate: filters.regulatorySyncDate.from || undefined,
      maxRegulatorySyncDate: filters.regulatorySyncDate.to || undefined,
    };
    getAnimals({
      variables,
      notifyOnNetworkStatusChange: true,
    });
  }, [
    activeSpeciesTypeId,
    animalIds,
    animalStatus,
    animalTypeIds,
    businessUnitId,
    fieldId,
    filters.ageInMonths.from,
    filters.ageInMonths.to,
    filters.breeds,
    filters.dateLeftFarm.from,
    filters.dateLeftFarm.to,
    filters.dateOfBirth.from,
    filters.dateOfBirth.to,
    filters.dateOnFarm.from,
    filters.dateOnFarm.to,
    filters.daysSinceWeighed.from,
    filters.daysSinceWeighed.to,
    filters.estimatedWeight.from,
    filters.estimatedWeight.to,
    filters.fields,
    filters.groups,
    filters.growthRate.from,
    filters.growthRate.to,
    filters.isInWithdrawal,
    filters.isRegulatorySynced,
    filters.lastWeight.from,
    filters.lastWeight.to,
    filters.pregnancyDueDate.from,
    filters.pregnancyDueDate.to,
    filters.pregnancyStatus,
    filters.regulatorySyncDate.from,
    filters.regulatorySyncDate.to,
    filters.sex,
    filters.tagIds,
    filters.tradingDeliveryDate.from,
    filters.tradingDeliveryDate.to,
    fromPage,
    getAnimals,
    groupId,
    isArchive,
    paginationQueryVariables.after,
    paginationQueryVariables.before,
    paginationQueryVariables.first,
    paginationQueryVariables.last,
    primaryId,
    searchTerm,
    secondaryId,
    sortBy,
    tagId,
  ]);

  const animals = useMemo(
    () =>
      removeNothings(
        data?.animals?.edges.map((animal) => {
          return animal?.node;
        }) ?? [],
      ),
    [data],
  );

  useEffect(() => {
    if (sortByValue) {
      setPageNumber(1);
      setSortBy(sortRuleToAnimalSorting(sortByValue[0]));
    }
  }, [setPageNumber, sortByValue]);

  const selectedAnimalIds = useMemo(() => (selectedRowIds ? [...selectedRowIds] : []), [selectedRowIds]);

  const onCellClick = useCallback(
    (animal: AnimalFragment) => {
      hasOnAnimalClick ? history.push(`/system/${fromPage || "livestock"}/animal/${animal.id}`) : null;
    },
    [history, fromPage],
  );

  //@ts-expect-error
  const columns: Column<AnimalFragment>[] = useMemo(
    () => [
      {
        id: COLUMN_IDS.SELECTION,
        Header: function SelectAllHeader({ data }: { data: AnimalFragment[] }) {
          const animalIds: Array<string> = data.map((a) => a.id);
          const selectedRowsIds = tableOptions?.selectedRowsIds;
          const checked = areAllRowsSelected(animalIds, selectedRowsIds ?? undefined);

          const updatedRows = new Set(
            Array.from(selectedRowsIds ?? []).filter((rowId) => {
              return !animalIds.includes(rowId);
            }),
          );

          const handleCheckboxChange = (): void => {
            if (checked) {
              setTableOptions({
                id: tableId,
                selectedRowsIds: updatedRows,
              });
            } else {
              const ids = new Set<string>(selectedRowsIds);

              data.forEach((item) => {
                ids.add(item["id"]);
              });

              setTableOptions({
                id: tableId,
                selectedRowsIds: ids,
              });
            }
          };
          return showSelectColumn ? <InputCheckbox checked={checked} onChange={handleCheckboxChange} /> : null;
        },
        Cell: function SelectColumn(
          props: React.PropsWithChildren<CellProps<AnimalFragment, string | null | undefined>>,
        ): React.ReactNode {
          const id = props.cell.row.original.id;

          return showSelectColumn ? <Checkbox tableId={tableId} id={id} /> : null;
        },
        sticky: "left",
        minWidth: showSelectColumn ? 40 : 0,
        width: showSelectColumn ? 40 : 0,
      },
      {
        id: COLUMN_IDS.ANIMAL_ID,
        Header: capitaliseFirstLetterOfEachWord(terms.passportNumber),
        accessor: "passportNumber",
        onCellClick,
        minWidth: 170,
        width: 170,
      },
      {
        id: COLUMN_IDS.E_ID,
        Header: "EID",
        accessor: "eId",
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.E_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.VISUAL_ID,
        Header: "VID",
        accessor: "visualId",
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.VISUAL_ID] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.BREEDR_ID,
        Header: "Breedr ID",
        onCellClick,
        accessor: "breedrId",
        Cell: ({ row }): React.ReactNode => getShortBreedrId(row.values[COLUMN_IDS.BREEDR_ID]) || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.NAME,
        Header: "Name",
        onCellClick,
        accessor: "name",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.NAME] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.BRUCELLOSIS_ID,
        Header: "Brucellosis ID",
        onCellClick,
        accessor: "brucellosisId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.BRUCELLOSIS_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.TRICH_ID,
        Header: "Trich ID",
        onCellClick,
        accessor: "trichId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TRICH_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.TSU_BARCODE,
        Header: "TSU Barcode",
        onCellClick,
        accessor: "tsuBarcode",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TSU_BARCODE] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.HERDDOGG_ID,
        Header: "HerdDogg ID",
        onCellClick,
        accessor: "herdDoggId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.HERDDOGG_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.PEDIGREE_ID,
        Header: capitaliseFirstLetterOfEachWord(terms.pedigreeId),
        onCellClick,
        accessor: "pedigreeId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.PEDIGREE_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.TATTOO_ID,
        Header: "Tattoo ID",
        onCellClick,
        accessor: "tattoo",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.TATTOO_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.UHF_ID,
        Header: "UHF ID",
        onCellClick,
        accessor: "uhfId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.UHF_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.ALTERNATIVE_ID,
        Header: "Alternative ID",
        onCellClick,
        accessor: "alternativeId",
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.ALTERNATIVE_ID] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.DATE_OF_BIRTH,
        Header: "DOB",
        accessor: "dob",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DATE_OF_BIRTH] ? getFormatDate(row.values[COLUMN_IDS.DATE_OF_BIRTH]) : "\u2014",
        onCellClick,
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.AGE,
        Header: <TableHeaderCaptions title="Age" subtitle="Months" />,
        accessor: ({ dateLeftFarm, deadAt, dob }): number | null => {
          // if dead, calculate age at death
          if (deadAt) return getMonthsAge(dob, deadAt);

          // if off farm, calculate age at move off farm
          if (dateLeftFarm) return getMonthsAge(dob, dateLeftFarm);

          // otherwise just calculate current age
          return getMonthsAge(dob);
        },
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.AGE] || "\u2014",
        minWidth: 100,
        width: 100,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.SEX_CLASSIFICATION,
        Header: <TableHeaderCaptions title="Sex" subtitle="Classification" />,
        accessor: ({ isMale, sexClassification }): string => sexClassification?.slug || (isMale ? "M" : "F"),
        onCellClick,
        Cell: function SexCell({ row }): React.ReactNode {
          const { id, isCastrated, isMale, sexClassification } = row?.original;

          return (
            <AnimalsSexClassLabel
              id={`animal_sex_label__${id}`}
              isCastrated={isCastrated}
              isMale={isMale}
              label={sexClassification?.title || null}
            />
          );
        },
        minWidth: 130,
        width: 100,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.SEX,
        Header: "Sex",
        accessor: ({ isMale }): string => (isMale ? "M" : "F"),
        onCellClick,
        Cell: function SexCell({ row }): React.ReactNode {
          const { id, isCastrated, isMale } = row?.original;

          return (
            <AnimalsSexClassLabel
              id={`animal_sex_label__${id}`}
              isCastrated={isCastrated}
              isMale={isMale}
              label={isMale ? "M" : "F"}
            />
          );
        },
        minWidth: 100,
        width: 100,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.BREED,
        Header: "Breed",
        accessor: "breedPercentages",
        onCellClick,
        Cell: function BreedsCell({ row }) {
          const { breedPercentages } = row?.original;
          return <AnimalsBreedPercentages breedPercentages={breedPercentages} orientation="col" showFullName />;
        },
        minWidth: 150,
        width: 200,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.BREED_CODE,
        Header: "Breed Code",
        accessor: "breedPercentages",
        onCellClick,
        Cell: function BreedsCell({ row }) {
          const { breedPercentages } = row?.original;
          return <AnimalsBreedPercentages breedPercentages={breedPercentages} orientation="row" showFullName={false} />;
        },
        minWidth: 150,
        width: 200,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.TAGS,
        Header: "Tags",
        accessor: "tags",
        onCellClick,
        Cell: function TagsCell({ row }) {
          const { tags } = row?.original;

          return tags.map((tag) => {
            const t = {
              tagId: tag.tagId,
              name: tag.name,
              color: tag.color || "grey",
              ownership: tag?.schema?.ownership,
            };
            return <Tag key={tag.tagId} tag={t} />;
          });
        },
        minWidth: 100,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.GROUP,
        Header: "Group",
        accessor: ({ group }): string | null => group?.name || null,
        onCellClick,
        Cell: ({ row }): React.ReactElement => row.values[COLUMN_IDS.GROUP] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.FIELD,
        Header: "Location",
        accessor: ({ field }): string | null =>
          field ? getFieldName({ fieldName: field.name, unitName: field.location?.unitName }) : null,
        onCellClick,
        Cell: ({ row }): React.ReactElement => row.values[COLUMN_IDS.FIELD] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.PREVIOUS_KEEPER,
        Header: isCreateOnDeliveriesEnabled ? "Source Address" : capitaliseFirstLetterOfEachWord(terms.previousKeeper),
        onCellClick,
        accessor: ({ previousKeeper }): string | null => previousKeeper?.name || null,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.PREVIOUS_KEEPER] || "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.UPDATED_AT,
        Header: "Last Updated",
        onCellClick,
        accessor: ({ updatedAt }): string | null => (updatedAt ? getFormatDate(updatedAt) : "\u2014"),
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.UPDATED_AT] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.DATE_ON_FARM,
        Header: `Date On ${capitaliseFirstLetter(terms.farm)}`,
        accessor: "dateMovedToFarm",
        onCellClick,
        Cell: ({ value }): React.ReactNode => (value ? getFormatDate(value) : "\u2014"),
        minWidth: 120,
        width: 120,
      },
      {
        id: COLUMN_IDS.DAYS_ON_FARM,
        Header: `Days On ${capitaliseFirstLetter(terms.farm)}`,
        accessor: (row): number | null =>
          row?.dateMovedToFarm
            ? getDaysOnFarm({
                dateMovedToFarm: row.dateMovedToFarm,
                dateLeftFarm: row?.dateLeftFarm,
                deadAt: row?.deadAt,
              })
            : null,
        onCellClick,
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DAYS_ON_FARM] ? `${row.values[COLUMN_IDS.DAYS_ON_FARM]} days` : "\u2014",
        minWidth: 150,
        width: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.DATE_LEFT_FARM,
        Header: `Date Left ${capitaliseFirstLetter(terms.farm)}`,
        onCellClick,
        accessor: "dateLeftFarm",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DATE_LEFT_FARM] ? getFormatDate(row.values[COLUMN_IDS.DATE_LEFT_FARM]) : "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.DEAD_AT,
        Header: "Dead At Date",
        onCellClick,
        accessor: "deadAt",
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.DEAD_AT] ? getFormatDate(row.values[COLUMN_IDS.DEAD_AT]) : "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.CURRENT_WEIGHT,
        Header: <TableHeaderCaptions title="Current Weight" subtitle="Estimated" />,
        accessor: ({ currentWeight }): number | null => (currentWeight ? +currentWeight?.toFixed(1) : null),
        onCellClick,
        Cell: function CurrentWeightCell({ row }): React.ReactNode {
          return !!row.values[COLUMN_IDS.CURRENT_WEIGHT] ? (
            <AnimalsPerformancePill
              label={`${row.values[COLUMN_IDS.CURRENT_WEIGHT]} ${weightUnits}`}
              performanceCategory={row?.original?.performanceCategory}
            />
          ) : (
            "\u2014"
          );
        },
        minWidth: 160,
        width: 160,
      },
      {
        id: COLUMN_IDS.LAST_WEIGHT,
        Header: "Last Weight",
        accessor: ({ lastWeight }): number | null => lastWeight?.value || null,
        Cell: function LastWeightCell({ row }): React.ReactNode {
          return !!row.values[COLUMN_IDS.LAST_WEIGHT] ? (
            <Pill caption={`${getFormattedNumber(row.values[COLUMN_IDS.LAST_WEIGHT])} ${weightUnits}`} colour="grey" />
          ) : (
            "\u2014"
          );
        },
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT,
        Header: "Days Since Weighed",
        accessor: ({ lastWeight }): number | null =>
          lastWeight?.date ? Math.trunc(DateTime.local().diff(DateTime.fromISO(lastWeight.date), ["days"]).days) : null,
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT] || "\u2014",
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.DLWG,
        Header: capitaliseFirstLetterOfEachWord(terms.avgDlwg),
        accessor: ({ growthRate }): string | null => growthRate?.toFixed(1) || null,
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.DLWG] || "\u2014",
        minWidth: 100,
        width: 100,
      },
      {
        id: COLUMN_IDS.SIRE,
        Header: "Sire",
        accessor: ({ linkToMySire, sirePassportNumber }): string | null =>
          linkToMySire?.passportNumber || sirePassportNumber || null,
        onCellClick,
        Cell: function SireCell({ row: { original } }): React.ReactNode {
          const sire = original?.linkToMySire;
          const isPedigree = sire?.pedigree;
          return sire ? (
            <AnimalPedigreeLabel
              id={`animal_sire_label__${original?.id}`}
              isPedigree={!!isPedigree}
              passportNumber={sire?.passportNumber}
            />
          ) : (
            original?.sirePassportNumber || "\u2014"
          );
        },
        minWidth: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.DAM,
        Header: "Dam",
        accessor: ({ damPassportNumber }): string | null => damPassportNumber || null,
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.DAM] || "\u2014",
        minWidth: 150,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.PREGNANCY_STATUS,
        Header: <TableHeaderCaptions title="Pregnancy" subtitle="Status" />,
        accessor: "isPregnant",
        onCellClick,
        Cell: function PregnancyStatusCell({ row: { original } }): React.ReactNode {
          return !original?.isMale && original?.isPregnant ? (
            <AnimalPregnancyLabel
              dueDate={original?.pregnancyDueDate}
              id={`animal_pregnancy_label__${original?.id}`}
              isMale={original?.isMale}
              isPregnant={!!original?.isPregnant}
            />
          ) : (
            "\u2014"
          );
        },
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
      {
        id: COLUMN_IDS.PREGNANCY_DUE_DATE,
        Header: <TableHeaderCaptions title="Pregnancy" subtitle="Due Date" />,
        accessor: "pregnancyDueDate",
        onCellClick,
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.PREGNANCY_DUE_DATE]
            ? getFormatDate(row.values[COLUMN_IDS.PREGNANCY_DUE_DATE])
            : "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.SERVICE_TYPE,
        Header: "Service Type",
        accessor: "serviceType",
        onCellClick,
        Cell: ({ row }): React.ReactNode => getServiceType(row.values[COLUMN_IDS.SERVICE_TYPE]) || "\u2014",
        minWidth: 150,
      },
      {
        id: COLUMN_IDS.CALVING_EASE_SCORE,
        Header: "Calving Ease",
        accessor: "calvingEaseScore",
        onCellClick,
        Cell: ({ row }): React.ReactNode => {
          return getCalvingEaseScore(row.values.calvingEaseScore) || "\u2014";
        },
        minWidth: 150,
      },
      {
        id: COLUMN_IDS.LAST_SYNCED,
        Header: "Last Synced",
        accessor: "lastRegulatorySyncedDate",
        onCellClick,
        Cell: ({ row }): React.ReactNode =>
          row.values[COLUMN_IDS.LAST_SYNCED] ? getFormatDate(row.values[COLUMN_IDS.LAST_SYNCED]) : "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.ANIMAL_STATUS,
        Header: " Animal Status",
        accessor: (animal): string | null => getAnimalStatus(animal),
        onCellClick,
        Cell: ({ row }): React.ReactNode => row.values[COLUMN_IDS.ANIMAL_STATUS] || "\u2014",
        minWidth: 150,
        width: 150,
      },
      {
        id: COLUMN_IDS.STATUS,
        Header: "Status",
        onCellClick,
        accessor: (animal): JSX.Element => {
          if (useGenetics) {
            return renderPedigreeStatus(
              {
                pedigreeId: animal.pedigreeId,
                withdrawalEnd: animal.withdrawalEnd,
                deadAt: animal.deadAt,
                dateLeftFarm: animal.dateLeftFarm,
              },
              { isArchive, isCarcass: undefined },
              getFormatDate,
            );
          }

          return renderStatus(
            {
              isRegulatorySynced: animal.isRegulatorySynced,
              withdrawalEnd: animal.withdrawalEnd,
              deadAt: animal.deadAt,
              dateLeftFarm: animal.dateLeftFarm,
            },
            {
              isArchive,
              isCarcass: undefined,
              isGb: countryCode.gb,
            },
            getFormatDate,
          );
        },
        disableSortBy: true,
        minWidth: isArchive ? 70 : 100,
        width: isArchive ? 70 : 100,
      },
      {
        id: COLUMN_IDS.DNA_STATUS,
        Header: "DNA Status",
        accessor: (animal: AnimalFragment): JSX.Element => {
          const { status, icon, message } = getDnaStatus({
            parentageTrioVerified: animal.parentageTrioVerified,
            hasDnaResults: animal.hasDnaResults,
          });
          return (
            <div>
              <Tooltip
                title={message}
                className={`bg-white border-white-100 text-black-100 text-sm ${
                  status === "notTested" ? "opacity-30" : "opacity-80"
                }`}
              >
                <Icon name={icon as keyof typeof ICON_NAMES} size="large" />
              </Tooltip>
            </div>
          );
        },
        disableSortBy: true,
        onCellClick,
        minWidth: 110,
        width: 110,
      },
      ...(showIndividualAnimalActionButtons
        ? [
            {
              ...getActionsColumn<AnimalFragment>(!!onPressEditAnimal),
              Cell: function ActionColumn({ row: { original } }): JSX.Element | null {
                const isOnFarm = !original?.dateLeftFarm && !original?.deadAt;
                if (!isOnFarm) {
                  return null;
                }
                if (onPressEditAnimal) {
                  return (
                    <button className="flex" onClick={() => onPressEditAnimal(original)}>
                      <Icon name="pencil" size="small" colour="blue" />
                      <p className="pl-2">Edit</p>
                    </button>
                  );
                }

                return (
                  <TableActionColumn
                    animal={original}
                    group={undefined}
                    isFarmWithGroups={numberOfGroups ? numberOfGroups > 0 : false}
                  />
                );
              },
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onCellClick,
      isArchive,
      tableOptions?.selectedRowsIds,
      setTableOptions,
      getFormatDate,
      weightUnits,
      numberOfGroups,
      showSelectColumn,
      showIndividualAnimalActionButtons,
      tableId,
    ],
  );

  const columnVisibilityRules = {
    [COLUMN_IDS.CURRENT_WEIGHT]: !isArchive,
    [COLUMN_IDS.DAYS_SINCE_LAST_WEIGHT]: !isArchive,
    [COLUMN_IDS.FIELD]: !isArchive,
    [COLUMN_IDS.PREGNANCY_DUE_DATE]: !isArchive,
    [COLUMN_IDS.PREGNANCY_STATUS]: !isArchive,
    [COLUMN_IDS.DEAD_AT]: isArchive,
    [COLUMN_IDS.DATE_LEFT_FARM]: isArchive,
    [COLUMN_IDS.KILL_WEIGHT]: isArchive,
    [COLUMN_IDS.KILL_QUALITY]: isArchive,
    [COLUMN_IDS.KILL_FAT_SCORE]: isArchive,
    [COLUMN_IDS.LAST_SYNCED]: countryCode.gb,
    [COLUMN_IDS.STATUS]: (!countryCode.gb && !isArchive) || countryCode.gb,
    [COLUMN_IDS.ANIMAL_ID]: activeIdPreferencesByCountry?.passportNumber,
    [COLUMN_IDS.E_ID]: activeIdPreferencesByCountry?.eId,
    [COLUMN_IDS.VISUAL_ID]: activeIdPreferencesByCountry?.visualId,
    [COLUMN_IDS.BRUCELLOSIS_ID]: activeIdPreferencesByCountry?.brucellosisId,
    [COLUMN_IDS.HERDDOGG_ID]: activeIdPreferencesByCountry?.herdDoggId,
    [COLUMN_IDS.PEDIGREE_ID]: activeIdPreferencesByCountry?.pedigreeId,
    [COLUMN_IDS.TATTOO_ID]: activeIdPreferencesByCountry?.tattoo,
    [COLUMN_IDS.TRICH_ID]: activeIdPreferencesByCountry?.trichId,
    [COLUMN_IDS.TSU_BARCODE]: activeIdPreferencesByCountry?.tsuBarcode,
    [COLUMN_IDS.NAME]: activeIdPreferencesByCountry?.name,
    [COLUMN_IDS.UHF_ID]: activeIdPreferencesByCountry?.uhfId,
    [COLUMN_IDS.BREEDR_ID]: activeIdPreferencesByCountry?.breedrId,
    [COLUMN_IDS.ALTERNATIVE_ID]: activeIdPreferencesByCountry?.alternativeId,
    [COLUMN_IDS.DNA_STATUS]: trioVerified,
    [COLUMN_IDS.ANIMAL_STATUS]: isArchive || isCSVErrors,
  };

  const hiddenColumns = Object.keys(columnVisibilityRules).filter((key) => {
    return !columnVisibilityRules[key];
  });

  const defaultColumnIds = countryCode.us
    ? isArchive
      ? Object.values(DEFAULT_COLUMN_IDS_US_ARCHIVE)
      : isCSVErrors
      ? Object.values(DEFAULT_COLUMN_IDS_CSV_ERRORS)
      : Object.values(DEFAULT_COLUMN_IDS_US)
    : Object.values(DEFAULT_COLUMN_IDS);

  const defaultColumns = createColumnObject(
    columns,
    currentLocalStorageColumns,
    hiddenColumns,
    defaultColumnIds,
    primaryIdCamelCase,
  );

  const [customColumns, setCustomColumns] = useState<Column<AnimalFragment>[]>(
    reorderColumns(columns, defaultColumns, primaryIdCamelCase),
  );

  const [customColumnTrigger, setCustomColumnTrigger] = useState<number>(0);
  useEffect(() => {
    setCustomColumns(
      currentLocalStorageColumns.length > 0
        ? reorderColumns(columns, currentLocalStorageColumns, primaryIdCamelCase)
        : reorderColumns(columns, defaultColumns, primaryIdCamelCase),
    );
  }, [columns, customColumnTrigger, primaryId]);

  const getExportColumns = (): AnimalExportColumnInput[] => {
    const exportColumns = customColumns.map((column) => {
      return getAnimalExportColumn(column);
    });
    return removeNothings(exportColumns);
  };

  const handleAnimalsExport = async () => {
    const { data } = await exportCsv({
      variables: {
        input: {
          animalIds: selectedAnimalIds.length > 0 ? selectedAnimalIds : animalIds,
          businessUnit: businessUnitId.toString(),
          columns: getExportColumns(),
          dateHint: countryCode.us ? UploadCsvDateHintEnumType.Usa : UploadCsvDateHintEnumType.International,
          animalTypes: activeSpeciesTypeId ? [activeSpeciesTypeId] : undefined,
          orderBy: sortBy ? [sortBy] : undefined,
          isMale: getBooleanFilter(filters.sex, Sex.MALE, Sex.FEMALE),
          isPregnant: getBooleanFilter(filters.pregnancyStatus, PregnancyStatus.PREGNANT, PregnancyStatus.NOTPREGNANT),
          minDob: filters.dateOfBirth.from || undefined,
          maxDob: filters.dateOfBirth.to || undefined,
          minDof: filters.dateOnFarm.from || undefined,
          maxDof: filters.dateOnFarm.to || undefined,
          minDepartedAt: filters.dateLeftFarm.from || undefined,
          maxDepartedAt: filters.dateLeftFarm.to || undefined,
          fieldIn: fieldId ? [fieldId] : filters.fields.length > 0 ? filters.fields : undefined,
          groupIn: groupId ? [groupId] : filters.groups.length > 0 ? filters.groups : undefined,
          breedIn: filters.breeds,
          animalTags: tagId ? [tagId] : filters.tagIds.length > 0 ? filters.tagIds : undefined,
          isRegulatorySynced: filters.isRegulatorySynced,
          isInWithdrawal: filters.isInWithdrawal,
          minAgeMonths: filters.ageInMonths.from ? parseInt(filters.ageInMonths.from) : undefined,
          maxAgeMonths: filters.ageInMonths.to ? parseInt(filters.ageInMonths.to) : undefined,
          minGrowthRate: filters.growthRate.from ? parseInt(filters.growthRate.from) : undefined,
          maxGrowthRate: filters.growthRate.to ? parseInt(filters.growthRate.to) : undefined,
          minPregnancyDueDate: filters.pregnancyDueDate.from || undefined,
          maxPregnancyDueDate: filters.pregnancyDueDate.to || undefined,
          maxDaysSinceWeighed: filters.daysSinceWeighed.from ? parseInt(filters.daysSinceWeighed.from) : undefined,
          minDaysSinceWeighed: filters.daysSinceWeighed.to ? parseInt(filters.daysSinceWeighed.to) : undefined,
          minDeliveryDate: filters.tradingDeliveryDate.from || undefined,
          maxDeliveryDate: filters.tradingDeliveryDate.to || undefined,
          minLastWeight: filters.lastWeight.from ? parseInt(filters.lastWeight.from) : undefined,
          maxLastWeight: filters.lastWeight.to ? parseInt(filters.lastWeight.to) : undefined,
          minEstimatedWeight: filters.estimatedWeight.from ? parseInt(filters.estimatedWeight.from) : undefined,
          maxEstimatedWeight: filters.estimatedWeight.to ? parseInt(filters.estimatedWeight.to) : undefined,
          minRegulatorySyncDate: filters.regulatorySyncDate.from || undefined,
          maxRegulatorySyncDate: filters.regulatorySyncDate.to || undefined,
          status: animalStatus ? animalStatus : !isArchive ? AnimalStatus.Active : AnimalStatus.Inactive,
        },
      },
    });
    dispatch(setMenuState("expanded"));
    dispatch(setTaskId(data?.animalsExportAsync?.export?.id));
  };
  const onChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);
  const debouncedSearchTerm = useMemo(() => {
    return debounce(onChangeSearchTerm, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (isSelectedAnimalsView && !selectedAnimalIds.length) {
      setIsSelectedAnimalsView(false);
    }
  }, [selectedAnimalIds, isSelectedAnimalsView]);

  useEffect(() => {
    setPageNumber(1);
  }, [filters, setPageNumber]);

  useEffect(() => {
    return () => {
      if (resetSelectedAnimalsOnUnmount) {
        setTableOptions({ selectedRows: undefined, selectedRowsIds: undefined, id: tableId });
      }
    };
  }, [resetSelectedAnimalsOnUnmount, setTableOptions, tableId]);

  const animalsData = useMemo(
    () =>
      loading && animals.length === 0
        ? getLoadingStateRows<AnimalFragment>(pageSize, generatePlaceholderAnimalRow)
        : animals,
    [animals, loading, pageSize],
  );

  const emptyTableDisplay = () => {
    if (loading) {
      return <LoadingOverlay />;
    } else if (!areFiltersDirty && !searchTerm) {
      return <EmptyStatesMyCattleTab />;
    } else if (areFiltersDirty || searchTerm) {
      return <EmptyStatesNoResults />;
    }
  };

  if (isSelectedAnimalsView) {
    return (
      <div className="w-full px-[10px]">
        {!!selectedAnimalIds.length && showAnimalActionButtons ? <AnimalActions animalIds={selectedAnimalIds} /> : null}
        <div className="flex justify-between my-2">
          <Button variant="outline" onClick={() => setIsSelectedAnimalsView(false)}>
            Back to list
          </Button>
          <div className="flex">
            <TablePaginationTarget tableId={TABLE_IDS["ANIMAL_LIST_SERVER_SELECTED"]} />
            <ExportAnimalsButton
              isLoading={!!taskId}
              numAnimals={selectedRowIds?.size || 0}
              onClick={() => handleAnimalsExport()}
              isServerList
            />
          </div>
        </div>
        <SelectedAnimalsTable
          fromPage={fromPage}
          columns={customColumns}
          hiddenColumns={hiddenColumns}
          sourceTableId={tableId}
          rowStyles={rowStyles}
        />
      </div>
    );
  }

  return (
    <div className="w-full px-[10px]">
      {!!selectedAnimalIds.length && showAnimalActionButtons ? <AnimalActions animalIds={selectedAnimalIds} /> : null}
      <div className="flex mt-2">
        <div className="flex">
          <div className="w-72 mr-2">
            <BaseInput
              type="text"
              onChange={debouncedSearchTerm}
              name="animal_search"
              placeholder={
                (primaryId as unknown as IdentifierType) !== IdentifierType.EId &&
                (secondaryId as unknown as IdentifierType) !== IdentifierType.EId
                  ? `Search by EID${primaryId ? `, ${identifierNameLookup(terms, primaryId)}` : ""}${
                      secondaryId ? ` or ${identifierNameLookup(terms, secondaryId)}` : ""
                    }`
                  : `Search by ${primaryId ? `${identifierNameLookup(terms, primaryId)}` : ""}${
                      secondaryId ? ` or ${identifierNameLookup(terms, secondaryId)}` : ""
                    }`
              }
            />
          </div>

          {showSelectColumn && Boolean(selectedAnimalIds.length) ? (
            <Button onClick={() => setIsSelectedAnimalsView((state) => !state)} variant="secondary">
              {`${selectedAnimalIds?.length} Selected - View selection`}
            </Button>
          ) : null}
        </div>
        <div className="flex justify-end flex-1 flex-wrap mb-2">
          <>
            {showPagination ? <PaginationControl paginationProps={paginationProps} /> : null}
            <Button title="Columns" className="ml-2" variant="outline" onClick={() => setColumnOptionsModalOpen(true)}>
              <span className="px-1">Columns</span>
              <Icon name="column" size="small" />
            </Button>
            {showFiltersButton ? (
              <Button title="Filters" className="ml-2" onClick={handleToggleFilterVisibility} variant="outline">
                <span className="px-1">Filters</span>
                <Icon name="filter" size="small" />
              </Button>
            ) : null}
          </>

          {showExportButton ? (
            <div className="ml-2">
              <ExportAnimalsButton
                isLoading={!!taskId}
                numAnimals={selectedRowIds?.size || totalNumberOfAnimals}
                onClick={() => handleAnimalsExport()}
                isServerList
              />
            </div>
          ) : null}
        </div>
      </div>
      {showFilters ? (
        <AnimalFilters
          isArchive={isArchive}
          tableFilterIdentifier={tableFilterIdentifier}
          excludedFilters={excludedFilters}
        />
      ) : null}
      {animals.length === 0 ? (
        emptyTableDisplay()
      ) : (
        <Table<AnimalFragment>
          data={animalsData}
          isAnimals={!(fromPage === "field")}
          columns={customColumns}
          hiddenColumns={hiddenColumns}
          tableId={tableId}
          manualSortBy={true}
          manualPagination={true}
          rowStyles={rowStyles}
        />
      )}
      <ColumnOptionsModal
        columns={columns}
        hiddenColumns={hiddenColumns}
        isShowModal={columnOptionsModalOpen}
        onClose={() => setColumnOptionsModalOpen(false)}
        setCustomColumnnTrigger={setCustomColumnTrigger}
        defaultColumnIds={defaultColumnIds}
        tableId={tableId}
        primaryId={primaryIdCamelCase}
        secondaryId={secondaryIdCamelCase}
      />
    </div>
  );
};

export const generatePlaceholderAnimalRow = () => ({
  id: "",
  tags: [],
  passportNumber: "—",
  isCastrated: false,
  isMale: false,
  animalBreeds: [],
  breedPercentages: [],
  updatedAt: "",
});
