import gql from "graphql-tag";

export const SOURCE_OBJECT_FRAGMENT = gql`
  fragment SourceObjectFragment on SourceObject {
    __typename
    ... on ActivityLog {
      __typename
      id
      payload {
        valueDescription
        longDescription
        shortDescription
      }
    }
    ... on FinancialTransactionAnimal {
      __typename
      allocatedValue
      financialTransaction {
        id
        contact {
          id
        }
        date
        grossWeight
        recordType
      }
    }
  }
`;

export const animal = gql`
  fragment animal on Animal {
    id
    animalBreeds {
      id
      name
      breedrCode
      newClassification
    }
    breedPercentages {
      breed {
        id
        name
        breedrCode
      }
      percentage
    }
    currentWeight
    dateMovedToFarm
    dob
    eId
    brucellosisId
    tattoo
    tsuBarcode
    pedigreeId
    trichId
    herdDoggId
    name
    breedrId
    uhfId
    alternativeId
    growthRate
    isCastrated
    latestPurchasePrice
    isMale
    isPregnant
    lastWeight {
      date
      value
    }
    lastRegulatorySyncedDate
    group {
      id
      name
    }
    field {
      id
      name
      location {
        unitName
      }
    }
    passportNumber
    performanceCategory
    pregnancyDueDate
    sexClassification {
      slug
      title
    }
    targetLiveWeight
    visualId
    isRegulatorySynced
    deliveryDate
    sirePassportNumber
    damPassportNumber
    damId
    linkToMySire {
      id
      name
      pedigree
      passportNumber
    }
    birthInformation {
      birthWeight
    }
    serviceType
    calvingEaseScore
    animalType {
      id
    }
    withdrawalEnd
    dateLeftFarm
    deadAt
    tags {
      color
      name
      tagId
      schema {
        ownership
      }
    }
    previousKeeper {
      name
      contact {
        id
      }
    }
    updatedAt
    parentageTrioVerified
    hasDnaResults
  }
`;

export const ANIMAL_HISTORY_EVENT = gql`
  fragment AnimalHistoryEvent on EventObjectType {
    __typename
    id
    happenedAt
    isDeletable
    activityType {
      id
      name
      slug
    }
    sourceObject {
      ...SourceObjectFragment
    }
  }
  ${SOURCE_OBJECT_FRAGMENT}
`;
