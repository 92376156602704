import { DELIVERIES } from "constants/Routes";
import { useLocation } from "react-router-dom";

export const BetaBanner = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const showBetaBanner = pathname.indexOf(`${DELIVERIES}`) !== -1;

  return (
    <>
      {showBetaBanner ? (
        <header
          aria-label="Beta Feature Notice: Movements"
          className="bg-breedr-yellow-400 h-12 flex justify-center items-center fixed top-0 w-full z-50"
        >
          <h1 className="text-base text-center">
            BETA - Movements is a new feature. We currently support a maximum of 220 animals in one movement.
          </h1>
        </header>
      ) : null}
      <div className={`${showBetaBanner ? "pt-12" : ""}`}>{children}</div>
    </>
  );
};
