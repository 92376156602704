import { FC, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import { MARK_OFFERS_AS_READ } from "trading/api/mutations";
import { MarkOffersAsRead, MarkOffersAsReadVariables } from "trading/api/types/MarkOffersAsRead";
import { CommonContext } from "config/commonProvider";
import { BU_TYPES } from "trading/constants";
import {
  ANIMALS,
  LIVESTOCK,
  REPORTS,
  TRADING,
  SUPPLY_CHAINS,
  TRADING_ROOT,
  DASHBOARD,
  MEDICINE_CABINET,
  REGULATORY,
  IMPORT,
  USER_SETTINGS,
  SETTINGS,
  DELIVERIES,
  DRAFTS,
  MOVE_OFF,
  FINANCIAL_TRANSACTIONS,
  CHUTE,
} from "constants/Routes";
import { useHasFeature, useGetCurrentBusinessUnit, useStateSpecies, useVerticalBreakpoints } from "hooks";
import { RootMenu, RootMenuLink, RootMenuLogo, RootMenuNav, Tooltip } from "components";
import { RootMenuLinkProps } from "components/Structure/RootMenu/RootMenuLink";
import styles from "./DashboardMenu.module.scss";
import { getBUFromLocalStorage, getBUTypeFromLocalStorage } from "helpers/storage";
import { CountryISOCode } from "helpers/translations/src/useHoldingInfo";
import { ICON_NAMES } from "constants/Icons";
import { capitaliseFirstLetter, useLocale } from "helpers/translations/src";

interface MenuLink extends RootMenuLinkProps {
  key: number;
  tooltip?: string;
  skip?: boolean;
}

export const DashboardMenu: FC = () => {
  const { activeSpecies } = useStateSpecies();

  const [countUnreadRFLs, setCountOfUnreadRFLs] = useState<number>(0);
  const { mainCommonState, resetAnimalsFilter } = useContext(CommonContext);
  const { isVBreakpointS } = useVerticalBreakpoints();
  const selectedBU = getBUFromLocalStorage();
  const selectedBUType = getBUTypeFromLocalStorage();
  const { terms } = useLocale();

  const [markOffersAsRead, { data }] = useMutation<MarkOffersAsRead, MarkOffersAsReadVariables>(MARK_OFFERS_AS_READ, {
    variables: {
      input: {
        businessUnit: selectedBU,
      },
    },
  });

  useEffect(() => {
    if (data?.offerMarkAsRead?.success) {
      setCountOfUnreadRFLs(0);
    }
  }, [data, selectedBU]);

  const { regulatorySystem, countryIsoCodeUpperCase } = useGetCurrentBusinessUnit();

  const showMovements = useHasFeature("OUTWARD_MOVEMENTS");
  const showFinancial = useHasFeature("FINANCIAL_TRANSACTIONS");

  const isShowRegulatory = !isEmpty(regulatorySystem);

  const isShowHomePage: boolean = useHasFeature("SHOW_HOME_PAGE");
  const isShowReports: boolean = useHasFeature("REPORTS");
  const isShowAnimalsForBuyer: boolean = useHasFeature("SHOW_ANIMALS_FOR_BUYER");

  const showChute = useHasFeature("DARR_CHUTE");

  const unreadRFLsBadge: { text: string; className: string } | undefined =
    countUnreadRFLs > 0
      ? {
          text: "",
          className: styles.animatedBadge,
        }
      : undefined;

  const MAIN_MENU_PROCESSORS: MenuLink[] = [
    {
      key: 0,
      icon: "home",
      path: DASHBOARD,
      tooltip: "Dashboard",
      skip: !isShowHomePage,
    },
    countryIsoCodeUpperCase === CountryISOCode.GB
      ? {
          key: 1,
          icon: "handshake",
          path: TRADING,
          tooltip: "Trading",
          badge: unreadRFLsBadge,
          activePaths: [TRADING_ROOT],
        }
      : {
          key: 1,
          icon: "handshake",
          path: SUPPLY_CHAINS,
          tooltip: "Supply Chains",
          badge: unreadRFLsBadge,
          activePaths: [TRADING_ROOT],
        },
    {
      key: 2,
      icon: (activeSpecies?.image as keyof typeof ICON_NAMES) || "cattle",
      path: ANIMALS,
      tooltip: activeSpecies?.name ? `My ${activeSpecies.name}` : "My Livestock",
      skip: !isShowAnimalsForBuyer,
      activePaths: [LIVESTOCK],
    },
    {
      key: 8,
      icon: "delivery",
      path: `${DELIVERIES}${MOVE_OFF}${DRAFTS}`,
      tooltip: "My Movements",
      skip: !showMovements,
    },
    {
      key: 6,
      icon: "weightFilled",
      path: CHUTE,
      tooltip: capitaliseFirstLetter(terms.crush),
      skip: !showChute,
    },
    {
      key: 3,
      icon: "chart",
      path: REPORTS,
      tooltip: "Reports",
      skip: !isShowReports,
    },
    {
      key: 9,
      icon: "dollarSign",
      path: FINANCIAL_TRANSACTIONS,
      tooltip: "Financial Transactions",
      skip: !showFinancial,
    },
    {
      key: 4,
      icon: "upload",
      path: `${IMPORT}`,
      tooltip: "Import Data",
    },
  ];

  const MAIN_MENU_FARMERS: MenuLink[] = [
    {
      key: 0,
      icon: "home",
      path: DASHBOARD,
      tooltip: "Dashboard",
      skip: !isShowHomePage,
    },
    countryIsoCodeUpperCase === CountryISOCode.GB
      ? {
          key: 1,
          icon: "handshake",
          path: TRADING,
          tooltip: "Trading",
          badge: unreadRFLsBadge,
          activePaths: [TRADING_ROOT],
        }
      : {
          key: 1,
          icon: "handshake",
          path: SUPPLY_CHAINS,
          tooltip: "Supply Chains",
          badge: unreadRFLsBadge,
          activePaths: [TRADING_ROOT],
        },
    {
      key: 2,
      icon: (activeSpecies?.image as keyof typeof ICON_NAMES) || "cattle",
      path: ANIMALS,
      tooltip: activeSpecies?.name ? `My ${activeSpecies.name}` : "My Livestock",
      activePaths: [LIVESTOCK],
    },
    {
      key: 8,
      icon: "delivery",
      path: `${DELIVERIES}${MOVE_OFF}${DRAFTS}`,
      tooltip: "My Movements",
      skip: !showMovements,
    },
    {
      key: 3,
      icon: "medicines",
      path: MEDICINE_CABINET,
      tooltip: "Medicine cabinet",
    },
    {
      key: 10,
      icon: "weightFilled",
      path: CHUTE,
      tooltip: capitaliseFirstLetter(terms.crush),
      skip: !showChute,
    },
    {
      key: 5,
      icon: "chart",
      path: REPORTS,
      tooltip: "Reports",
      skip: !isShowReports,
    },
    {
      key: 9,
      icon: "dollarSign",
      path: FINANCIAL_TRANSACTIONS,
      tooltip: "Financial Transactions",
      skip: !showFinancial,
    },
    {
      key: 6,
      icon: "regulatory",
      path: REGULATORY,
      tooltip: "Regulatory",
      skip: !isShowRegulatory,
    },
    {
      key: 7,
      icon: "upload",
      path: `${IMPORT}`,
      tooltip: "Import Data",
    },
  ];

  const menuItems = selectedBUType === BU_TYPES.processor ? MAIN_MENU_PROCESSORS : MAIN_MENU_FARMERS;

  // 4. Clear filters when navigation occurs
  const handleMenuClick = (path: string): void => {
    if (!isEmpty(mainCommonState.animalsFilters)) {
      resetAnimalsFilter();
    }
    if (path === TRADING && countUnreadRFLs > 0) {
      markOffersAsRead();
    }
  };

  return (
    <RootMenu>
      <RootMenuLogo />

      <RootMenuNav>
        {menuItems.map(({ icon, key, path, skip, tooltip, ...rest }) => {
          if (skip) return null;

          return tooltip ? (
            <Tooltip placement="right" key={key} title={tooltip}>
              <RootMenuLink
                icon={icon}
                iconSmall={isVBreakpointS}
                onClick={(): void => handleMenuClick(path)}
                path={path}
                {...rest}
              />
            </Tooltip>
          ) : (
            <RootMenuLink icon={icon} key={key} onClick={(): void => handleMenuClick(path)} path={path} {...rest} />
          );
        })}
      </RootMenuNav>

      <RootMenuNav secondary>
        <Tooltip placement="right" title="Settings">
          <RootMenuLink
            activePaths={[SETTINGS]}
            icon="settings"
            iconSmall={isVBreakpointS}
            path={`${SETTINGS}${USER_SETTINGS}`}
          />
        </Tooltip>
      </RootMenuNav>
    </RootMenu>
  );
};
