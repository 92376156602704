import { Icon } from "components";
import { Button } from "twComponents/Button";
import { DateTime } from "luxon";
import { Tooltip } from "twComponents/Tooltip";
import { ICON_NAMES } from "constants/Icons";
import { Clock } from "lucide-react";
import { useFormatDate } from "hooks/useFormatDate";

export const HistoryEvent = ({
  id,
  iconName,
  happenedAt,
  title,
  metrics,
  description,
  ...props
}: {
  id: string;
  iconName: keyof typeof ICON_NAMES;
  happenedAt: string;
  title: React.ReactNode;
  description?: Maybe<string>;
  metrics?: { key: string; value: string }[];
} & ({ isDeletable: true; handleDelete: (id: string) => void } | { isDeletable: false })) => {
  const { getFormatDate } = useFormatDate();

  return (
    <div className="relative" data-testid="history-event">
      <div className="left-[-3.3rem] top-[0.7rem] w-10 h-10 bg-white border border-gray-400 border-solid rounded-full absolute flex justify-center items-center">
        <Icon name={iconName} size="small" />
      </div>
      <li className={"border-solid border-gray-400 border rounded-xl p-4 flex justify-between bg-white"}>
        <div className="flex flex-col gap-2">
          {title}
          {description ? <span className="text-xs">{description}</span> : null}
          {metrics && metrics.length > 0 ? (
            <div className="flex gap-2">
              {(metrics || []).map(({ key, value }) => {
                return (
                  <div
                    className="px-4 bg-gray-200 border rounded-full text-black font-semibold text-xs h-6 content-center w-fit"
                    key={key}
                  >
                    <span className="font-light">{key}</span>
                    <span className="font-medium">{value}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="px-2 border-solid border-gray-400 border rounded-full text-black font-semibold text-xs h-6 w-fit flex justify-center items-center">
            <Clock size={16} />
            <span className="font-light ml-1">{getFormatDate(happenedAt, DateTime.TIME_SIMPLE)}</span>
          </div>
        </div>
        <div>
          <Tooltip
            title={props.isDeletable ? "Delete event" : "Cannot delete event"}
            className="inline-block"
            position="left"
          >
            <Button
              variant="ghost"
              size="square"
              disabled={!props.isDeletable}
              onClick={props.isDeletable ? () => props.handleDelete(id) : undefined}
            >
              <Icon name="trash" size="small" />
              <span className="sr-only">Delete</span>
            </Button>
          </Tooltip>
        </div>
      </li>
    </div>
  );
};
