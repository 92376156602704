// React
import React from "react";
import { NavLink } from "react-router-dom";
// Libraries
import cn from "classnames";
// Components
import { Flex } from "components";
// Resources
import styles from "./Tabs.module.scss";

export interface TabsProps {
  tabs: SubTab[];
  disabled?: number;
  grayColorTabs?: boolean;
  grayBackgroundTabs?: boolean;
  customStyle?: string;
  customText?: string;
  selectedTab: number;
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
}

export interface SubTab {
  key: number;
  name: string;
  route: string;
  count?: number;
  skip?: boolean;
  value?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  selectedTab,
  disabled: disabledTab,
  customStyle,
  customText,
  grayColorTabs,
  grayBackgroundTabs,
  onMouseOver,
}) => (
  <Flex container className={cn(styles.tabs, customStyle, grayBackgroundTabs && styles.tabsGray)}>
    <Flex item itemGutter>
      {tabs.map((tab) => {
        if (tab.skip) {
          return null;
        }

        const handleClick = (event): void => {
          if (tab.key === disabledTab) event.preventDefault();
        };

        return (
          <NavLink
            className={cn(styles.tabs__tab, {
              [styles["tabs__tab--selected"]]: selectedTab === tab.key,
              [styles["tabs__tab--disabled"]]: disabledTab === tab.key,
            })}
            key={`tab_${tab.key}`}
            onClick={handleClick}
            to={tab.route}
            onMouseOver={onMouseOver}
          >
            <span
              data-testid={tab.name.toLowerCase().replace(/\s/g, "")}
              className={cn(styles.tabs__tab_caption, customText, {
                [styles["tabs__tab_caption--inactive"]]: selectedTab !== tab.key && grayColorTabs,
              })}
            >
              {tab.name}
            </span>

            {!!tab.count && tab.count > 0 ? <span className={styles.tabs__tab_count}>{`(${tab.count})`}</span> : null}
          </NavLink>
        );
      })}
    </Flex>
  </Flex>
);
Tabs.displayName = "Tabs";
