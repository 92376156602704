import { AmsChuteSession } from "chute/components/ChuteSessionsTable";

// NOTE: This is a manually written out type, because
// the backend returns an any. There are many more properties on
// a session config object, but we don't need it for this helper function,
// so I've left them out of this type.
//
// I've opted for defensive programming here by making everything a Maybe.
// It seems safest to me given that it is a manually written type.
export interface SessionConfig {
  drafts: Maybe<
    {
      target:
        | {
            id: string | null | undefined;
            kind: string | null | undefined;
            name: string | null | undefined;
          }
        | null
        | undefined;
    }[]
  >;
}

export type MaybeSessionConfig = Maybe<SessionConfig>;

export const getDraftGroups = (sessionConfig: MaybeSessionConfig) => {
  if (!sessionConfig) {
    return [];
  }

  const drafts = sessionConfig.drafts || [];
  const groupDrafts = drafts
    .filter((draft) => draft?.target?.kind === "group")
    .map((draft) => {
      return {
        id: draft.target?.id,
        name: draft.target?.name,
      };
    });

  return groupDrafts;
};

export enum CrushActivity {
  AnimalProfitCalc = "ANIMAL_PROFIT_CALC",
  Calfulator = "CALFULATOR",
  LogWeight = "LOG_WEIGHT",
  UpdateAnimalIds = "UPDATE_VID_EID",
}

export const isOldStructuredDraftSession = (session: AmsChuteSession) =>
  !!session?.sessionConfig?.drafts?.targets?.length;

export const isSessionHasDraft = (session: AmsChuteSession) => {
  const sessionConfig = session?.sessionConfig;

  return (
    isOldStructuredDraftSession(session) ||
    !!(sessionConfig?.drafts?.length && sessionConfig?.drafts.some((d) => !!d.target?.id))
  );
};

export const getSessionName = (session: AmsChuteSession) => {
  const crushActivities: CrushActivity[] = [];

  const sessionConfig = session?.sessionConfig;

  // Logic for backward compatibility
  if (sessionConfig.crushActivities) {
    crushActivities.push(...sessionConfig.crushActivities);
  } else if (sessionConfig?.type === "weight") {
    crushActivities.push(CrushActivity.LogWeight);
  }

  if (session?.sessionConfig.name) {
    return session.sessionConfig.name;
  }

  const labels = crushActivities.map(
    (el) =>
      ({
        [CrushActivity.LogWeight]: "Weight",
        [CrushActivity.UpdateAnimalIds]: "VID/EID",
        [CrushActivity.Calfulator]: "Calfulator",
        [CrushActivity.AnimalProfitCalc]: "Animal Profit Calulator",
      }[el]),
  );

  const hasDraft = isSessionHasDraft(session);

  if (hasDraft) {
    labels.push("Draft");
  }

  return labels.join(" & ");
};
