import { Button } from "twComponents/Button";

export const LoadMoreButton = ({
  loading,
  handleLoadMore,
  hasNextPage,
}: {
  hasNextPage: boolean;
  loading: boolean;
  handleLoadMore: () => void;
}) => {
  if (!hasNextPage) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <Button
        variant="outline"
        size={"lg"}
        className="rounded-full justify-self-center"
        disabled={!hasNextPage}
        onClick={handleLoadMore}
        loading={loading}
      >
        Load more
      </Button>
    </div>
  );
};
