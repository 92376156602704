import { ChangeEvent, FC } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { GET_ORGANISATIONS_LIST } from "api/Organisations/queries";
import { GetOrganizations } from "api/Organisations/types/GetOrganizations";
import { ANIMALS, DASHBOARD, TRADING } from "constants/Routes";
import { BU_TYPES } from "trading/constants";
import { getBUFromLocalStorage, getBUTypeFromLocalStorage } from "helpers/storage";
import { useHasFeature, useStateSpecies } from "hooks";
import { InputSelect } from "components";
import { GetBusinessUnitQueryResult } from "generated/graphql";
import { useAuth } from "auth/hooks/useAuth";
import { generateOptions, separateOptions, sortAndCombineOptions } from "./helpers";

type BusinessUnits = Array<NonNullable<GetBusinessUnitQueryResult["data"]>["businessUnit"]>;

interface Props {
  businessUnits: BusinessUnits;
}

export const DashboardBusinessUnitSelect: FC<Props> = () => {
  const { changeBusinessUnit: changeBU } = useAuth();
  const currentBusinessUnitID = getBUFromLocalStorage().toString();
  const selectedBUType = getBUTypeFromLocalStorage();
  const history = useHistory();
  const { handleSetActiveSpecies, handleResetSpeciesList } = useStateSpecies();

  const isShowHomePage = useHasFeature("SHOW_HOME_PAGE");
  const isProcessor = selectedBUType === BU_TYPES.processor;
  const pathToRedirect = isProcessor ? TRADING : ANIMALS;

  const { data: organisations } = useQuery<GetOrganizations>(GET_ORGANISATIONS_LIST, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const userOrganisations = organisations?.organisations || [];
  const options = generateOptions(userOrganisations);
  const { organizations, businessUnits } = separateOptions(options);
  const sortedOptions = sortAndCombineOptions(organizations, businessUnits, userOrganisations);

  const changeBusinessUnit = (newBusinessUnitID: string): void => {
    console.log("newBusinessUnitID", newBusinessUnitID);
    changeBU(newBusinessUnitID);
    handleResetSpeciesList();
    handleSetActiveSpecies(undefined);

    history.push(isShowHomePage ? DASHBOARD : pathToRedirect);
  };
  const handleChangeBusinessUnit = (event: ChangeEvent<HTMLSelectElement>) => changeBusinessUnit(event.target.value);

  return (
    <InputSelect
      name="businessUnitSwitcher"
      onChange={handleChangeBusinessUnit}
      options={sortedOptions}
      value={options.length ? currentBusinessUnitID : ""}
    />
  );
};
