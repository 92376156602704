import { DASHBOARD, CHUTE } from "constants/Routes";
import { ChuteSessions } from "chute/pages/ChuteSessions";
import { Redirect, Route, Switch } from "react-router-dom";

const ROUTES_PATHS = {
  ChuteSessions: `${CHUTE}`,
};

export const PATHS = Object.keys(ROUTES_PATHS).map((key) => ROUTES_PATHS[key]);

export const ChuteRoutes = () => {
  return (
    <Route exact path={PATHS}>
      <Switch>
        <Route exact path={CHUTE} component={ChuteSessions} />
        <Redirect to={DASHBOARD} />
      </Switch>
    </Route>
  );
};
