import { AmericanAngusEpd, BreedseekOrder, NeogenEpd } from "generated/graphql";
import { Epd, EpdTests, GeneticTestResultTableRow, GeneticsTests } from "./types";

export const camelCaseToLabel = (name: string): string => {
  const words = name.replace(/([a-z0-9])([A-Z])/g, "$1 $2").trim();
  const formattedName = words.charAt(0).toUpperCase() + words.slice(1);
  return formattedName;
};

export const transformEpdData = (data: AmericanAngusEpd | NeogenEpd | null | undefined): Epd[] => {
  if (!data) return [];
  const transformedEpdData: Epd[] = [];
  const processedBaseNames: { [key: string]: Epd } = {};

  for (const key in data) {
    if (key === "__typename" || key === "createdAt" || key === "updatedAt") continue;

    const typeMatch = key.match(/(Acc|Epd|Pct)$/);
    const baseName = typeMatch ? key.substring(0, key.length - typeMatch[0].length) : key;
    const formattedBaseName = camelCaseToLabel(baseName);

    if (!processedBaseNames[baseName]) {
      processedBaseNames[baseName] = { name: formattedBaseName, val: null, pct: null, acc: null };
      transformedEpdData.push(processedBaseNames[baseName]);
    }

    const value = parseFloat(data[key] as string);
    if (typeMatch) {
      const type = typeMatch[0];
      if (type === "Epd") processedBaseNames[baseName].val = value;
      else if (type === "Pct") processedBaseNames[baseName].pct = value;
      else if (type === "Acc") processedBaseNames[baseName].acc = value;
    } else {
      processedBaseNames[baseName].val = value;
    }
  }

  return transformedEpdData;
};

export const getTestName = (testItem: GeneticsTests | EpdTests) => {
  switch (testItem?.__typename) {
    case "BreedseekOrder":
      return "Breed composition";
    case "BeefXDairyData":
      return "Igenity BeefxDairy";
    case "AmericanAngusEPD":
      return "American Angus";
    case "NeogenEPD":
      return "Neogen";
    default:
      return "";
  }
};
export const transformTestResultsToTableRow = (
  item: GeneticsTests,
  excludedKeys: string[],
  breedseekResults: BreedseekOrder | null | undefined,
  getFormatDate: (date: string | Date | undefined, formatOptions?: Intl.DateTimeFormatOptions) => string,
): GeneticTestResultTableRow => {
  if (!item) {
    return undefined;
  }
  const testName = getTestName(item) || "";
  const date = getFormatDate(item?.updatedAt ?? item?.createdAt) ?? "-";
  const barcode = item?.barcode ?? "-";
  const breeds = breedseekResults?.breeds ?? [];

  const flattenedBreeds = [];
  breeds.map((breed) => {
    flattenedBreeds[breed!.breed.name] = breed!.breedPctValue;
  });

  // origin being the only key that is required in Breedseek but not in BeefXDairy
  const isBreedseek = item.__typename === "BreedseekOrder";

  const flattenedItem = isBreedseek ? { ...item, ...flattenedBreeds } : item;

  const metrics = Object.keys(flattenedItem)
    .filter((key) => !excludedKeys.includes(key))
    .map((key) => ({
      name: camelCaseToLabel(key),
      value:
        isBreedseek && key !== "order" && key !== "origin"
          ? parseFloat(flattenedItem[key]).toFixed(2) + "%"
          : typeof flattenedItem[key] === "number"
          ? item[key].toFixed(1)
          : item[key],
    }));

  return {
    testName,
    date,
    barcode,
    metrics,
  };
};
