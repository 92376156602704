import { TransactionRecordEnumType } from "generated/graphql";
import * as yup from "yup";

const testIsMaxTwoDigits = (val: number | undefined) => {
  if (val != undefined) {
    return twoDecimalsPattern.test(`${val}`);
  }
  return true;
};
const testMaxAmount = (price: string | undefined) => {
  if (!price) {
    true;
  }
  const parsedPriceWithoutDecimals = price?.replace(/,/g, "").split(".")[0];

  if (!parsedPriceWithoutDecimals) {
    return true;
  }

  return parsedPriceWithoutDecimals.length < 9;
};

const TWO_DECIMALS_ERROR_MESSAGE = "Max two decimals are allowed.";
const WEIGHT_AMOUNT_ERROR_MESSAGE = "Weight must be between 0 and 100,000";
const PERCENTAGE_AMOUNT_ERROR_MESSAGE = "Shrinkage must be between 0% and 100%";
const MAX_AMOUNT_MESSAGE = "Must be less than 100,000,000";

const twoDecimalsPattern = /^\d+(\.\d{0,2})?$/;

export const isTwoDigits = ["test-max-two-decimals", TWO_DECIMALS_ERROR_MESSAGE, testIsMaxTwoDigits] as const;
const isPriceTooBig = ["test-max-amount", MAX_AMOUNT_MESSAGE, testMaxAmount] as const;

export type TransactionValidationSchema = yup.InferType<ReturnType<typeof getTransactionValidationSchema>>;

export const dateRange = "dateRange";
export const singleDate = "singleDate";
export type DateType = typeof dateRange | typeof singleDate;

export const getTransactionValidationSchema = (isOshkosh: boolean) => {
  return yup.object({
    transactionId: yup.string(),
    isSubmitted: yup.bool().required(),
    dateType: yup.mixed<DateType>().oneOf(["dateRange", "singleDate"]).defined(),
    date: yup.date().required("Transaction date is a required field."),
    endDate: yup.date().when("dateType", {
      is: "dateRange",
      then: yup
        .date()
        .required("End date is a required field.")
        .when("date", (date, schema) =>
          date ? schema.min(date, "End date must be later than the start date.") : schema,
        ),
    }),
    transactionType: yup
      .mixed<TransactionRecordEnumType>()
      .oneOf([TransactionRecordEnumType.Purchase, TransactionRecordEnumType.Sale, TransactionRecordEnumType.Expense])
      .required("Transaction type is a required field."),
    description: yup.string().max(50),
    name: yup.string().max(50).required("Name is a required field."),
    totalAmount: yup
      .string()
      .required("Total price is a required field.")
      .test(...isPriceTooBig),
    perHeadAmount: yup.string().test(...isPriceTooBig),
    payWeight: yup
      .number()
      .transform((value: number) => (Number.isNaN(value) ? 0 : value))
      .min(0, WEIGHT_AMOUNT_ERROR_MESSAGE)
      .max(100000, WEIGHT_AMOUNT_ERROR_MESSAGE)
      .test(...isTwoDigits),
    grossWeight: yup
      .number()
      .transform((value: number) => (Number.isNaN(value) ? 0 : value))
      .min(0, WEIGHT_AMOUNT_ERROR_MESSAGE)
      .max(100000, WEIGHT_AMOUNT_ERROR_MESSAGE)
      .test(...isTwoDigits),
    shrinkage: yup
      .number()
      .transform((value: number) => (Number.isNaN(value) ? 0 : value))
      .min(0, PERCENTAGE_AMOUNT_ERROR_MESSAGE)
      .max(100, PERCENTAGE_AMOUNT_ERROR_MESSAGE)
      .test(...isTwoDigits),
    contactId: yup.string(),
    groupId: isOshkosh
      ? yup.string().when("transactionType", {
          is: TransactionRecordEnumType.Expense,
          then: (schema) => schema.required("Group is a required field."),
        })
      : yup.string(),
    animalsCount: yup.number(),
    transactionCategory: yup.string().when("transactionType", {
      is: TransactionRecordEnumType.Expense,
      then: (schema) => schema.required("Category is a required field."),
      otherwise: (schema) => schema.transform(() => undefined),
    }),
  });
};
