import { GetOrganizations_organisations } from "api/Organisations/types/GetOrganizations";
import { InputSelectOptionWithTitles } from "components/Common/Field/Select";

export const generateOptions = (
  userOrganisations: (GetOrganizations_organisations | null)[] | null | undefined,
): InputSelectOptionWithTitles[] => {
  if (!userOrganisations) {
    return [];
  }
  return userOrganisations.reduce((result: InputSelectOptionWithTitles[], userOrg) => {
    if (userOrg?.organisation) {
      result.push({
        key: `${userOrg.organisation.id}-org-id`,
        label: userOrg.organisation.name || "",
      });
    }
    (userOrg?.businessUnits || []).forEach((businessUnit) => {
      result.push({
        key: businessUnit?.id || "key",
        value: businessUnit?.id,
        label: businessUnit?.name || "",
      });
    });

    return result;
  }, []);
};

export const separateOptions = (options: InputSelectOptionWithTitles[]) => {
  const organizations: InputSelectOptionWithTitles[] = [];
  const businessUnits: InputSelectOptionWithTitles[] = [];

  options.forEach((option) => {
    if (option.key.includes("-org-id")) {
      organizations.push(option);
    } else {
      businessUnits.push(option);
    }
  });

  return { organizations, businessUnits };
};

export const sortAndCombineOptions = (
  organizations: InputSelectOptionWithTitles[],
  businessUnits: InputSelectOptionWithTitles[],
  userOrganisations: (GetOrganizations_organisations | null)[],
): InputSelectOptionWithTitles[] => {
  organizations.sort((a, b) => (a.label as string).localeCompare(b.label as string));

  const orgMap = new Map<string, InputSelectOptionWithTitles[]>();

  businessUnits.forEach((bu) => {
    const orgId = userOrganisations.find((org) => org?.businessUnits?.some((unit) => unit?.id === bu.key))?.organisation
      ?.id;
    if (orgId) {
      if (!orgMap.has(orgId)) {
        orgMap.set(orgId, []);
      }
      orgMap.get(orgId)?.push(bu);
    }
  });

  orgMap.forEach((units) => {
    units.sort((a, b) => (a.label as string).localeCompare(b.label as string));
  });

  const sortedOptions: InputSelectOptionWithTitles[] = [];

  organizations.forEach((org) => {
    sortedOptions.push(org);
    const orgId = org.key.split("-")[0];
    const relatedUnits = orgMap.get(orgId) || [];
    sortedOptions.push(...relatedUnits);
  });

  return sortedOptions;
};
