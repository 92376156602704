import React, { useState } from "react";
import { LogActivityModal } from "animals/LogActivityModal";
import { MarkAsDeadModal } from "animals/MarkAsDeadModal";
import { AddSelectedAnimalsToTag } from "components/MyLivestock/Animals/AnimalBulkActions/AddSelectedAnimalsToTag";
import { RemoveSelectedAnimalsToTag } from "components/MyLivestock/Animals/AnimalBulkActions/RemoveSelectedAnimalsToTag";
import { MoveToFieldModal } from "fields/MoveToFieldModal";
import { useHasFeature } from "hooks";
import { TABLE_IDS } from "constants/Interface";
import { MarkOffFarmModal } from "components/MyLivestock/Animals/AnimalBulkActions/MarkOffFarm/MarkOffFarmModal";
import { ConfirmAddPreviousKeeperModal } from "components/MyLivestock/Animals/AnimalBulkActions/ConfirmAddPreviousKeeperModal";
import { Button as TwButton } from "twComponents/Button";
import { AssignToGroupModal } from "groups/components/AssignToGroupModal";

interface Props {
  animalIds: Array<string>;
}

export const AnimalActions: React.FC<Props> = ({ animalIds = [] }) => {
  const [showMarkAsDeadModal, setShowMarkAsDeadModal] = useState(false);
  const [showMoveToFieldModal, setShowMoveToFieldModal] = useState(false);
  const [showAssignToGroupModal, setShowAssignToGroupModal] = useState(false);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [showRemoveTagModal, setShowRemoveTagModal] = useState(false);

  const isMovementsEnabled = useHasFeature("OUTWARD_MOVEMENTS");

  return (
    <div className="flex justify-end flex-wrap">
      <TwButton onClick={() => setShowAssignToGroupModal(true)} className="mx-1">
        Add to group
      </TwButton>
      {showAssignToGroupModal ? (
        <AssignToGroupModal
          animalIds={animalIds}
          setShowModal={setShowAssignToGroupModal}
          showModal={showAssignToGroupModal}
        />
      ) : null}

      <TwButton onClick={() => setShowMoveToFieldModal(true)} className="mx-1">
        Move to location
      </TwButton>
      {showMoveToFieldModal ? (
        <MoveToFieldModal
          entityToMove={{ type: "animal", ids: animalIds }}
          showModal={showMoveToFieldModal}
          setShowModal={setShowMoveToFieldModal}
        />
      ) : null}

      <div className="mx-1">
        <ConfirmAddPreviousKeeperModal animalIds={animalIds} />
      </div>

      <div className="mx-1">
        <TwButton onClick={() => setShowAddTagModal(true)} className="mx-1">
          Add tag
        </TwButton>
        {showAddTagModal ? (
          <AddSelectedAnimalsToTag
            animalIds={animalIds}
            showModal={showAddTagModal}
            setShowModal={setShowAddTagModal}
          />
        ) : null}
      </div>
      <div className="mx-1">
        <TwButton onClick={() => setShowRemoveTagModal(true)} className="mx-1">
          Remove tag
        </TwButton>
        {showRemoveTagModal ? (
          <RemoveSelectedAnimalsToTag
            animalIds={animalIds}
            showModal={showRemoveTagModal}
            setShowModal={setShowRemoveTagModal}
          />
        ) : null}
      </div>
      {isMovementsEnabled ? null : (
        <div className="mx-1">
          <MarkOffFarmModal animalIds={animalIds} tableId={TABLE_IDS.ANIMALS} />
        </div>
      )}

      <TwButton className="mx-1" onClick={() => setShowMarkAsDeadModal(true)}>
        Mark as dead
      </TwButton>
      <MarkAsDeadModal animalIds={animalIds} showModal={showMarkAsDeadModal} setShowModal={setShowMarkAsDeadModal} />

      <div className="ml-1">
        <LogActivityModal animalIds={animalIds} />
      </div>
    </div>
  );
};
