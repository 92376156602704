import { Text } from "components";
import { LoadingOverlay } from "twComponents/LoadingOverlay";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useConfirmNavigationModal } from "financial/components/TransactionForm/hooks/useConfirmNavigationModal";
import { useTransactionForm } from "./hooks/useTransactionForm";
import { FormFields } from "./FormFields";
import { TransactionAnimalsTable } from "./TransactionAnimalsTable";
import { FormActions } from "./FormActions";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import { NavigationConfirmationModal } from "./NavigationConfirmationModal";
import { useHandleFormSubmission } from "./hooks/useHandleFormSubmission";
import { useHasFeature } from "hooks/useHasFeature";
import { TransactionRecordEnumType } from "generated/graphql";

interface Props {
  transactionId: string | undefined;
}

export const TransactionForm = ({ transactionId }: Props) => {
  const isNewTransaction = !transactionId;
  const [isEditMode, setIsEditMode] = useState(!!isNewTransaction);
  const { methods, isLoadingDefaultFormValues } = useTransactionForm(transactionId);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const { setShouldDeleteTransactionOnRouteChange, ...confirmNavigationModalProps } =
    useConfirmNavigationModal(isNewTransaction);
  const { onSubmit, isSubmittingTransaction } = useHandleFormSubmission(setShouldDeleteTransactionOnRouteChange);

  const isExpense = methods.watch("transactionType") === TransactionRecordEnumType.Expense;
  const isOshkosh = useHasFeature("OSHKOSH_ASSOCIATE_GROUP_WITH_TRANSACTION");
  const hideAnimalsTable = isExpense && isOshkosh;

  if (isLoadingDefaultFormValues) {
    return <LoadingOverlay />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="max-w-[100%]">
        <div className="px-[10px]">
          <div className="flex justify-between items-center">
            <Text className="font-semibold text-xl">Transaction</Text>
            <FormActions
              setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              isNewTransaction={isNewTransaction}
              isSubmittingTransaction={isSubmittingTransaction}
            />
          </div>
          <FormFields isEditMode={isEditMode} />
        </div>

        {hideAnimalsTable ? null : (
          <TransactionAnimalsTable
            isEditMode={isEditMode}
            isNewTransaction={isNewTransaction}
            transactionId={transactionId}
          />
        )}
      </form>

      <DeleteConfirmationModal
        setShouldDeleteTransactionOnRouteChange={setShouldDeleteTransactionOnRouteChange}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
      />
      <NavigationConfirmationModal
        setShouldDeleteTransactionOnRouteChange={setShouldDeleteTransactionOnRouteChange}
        {...confirmNavigationModalProps}
      />
    </FormProvider>
  );
};
