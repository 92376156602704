import { AnimalHistoryQueryVariables } from "generated/graphql";
import { Icon } from "components";
import { Button } from "twComponents/Button";
import { DateTime } from "luxon";
import { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "twComponents/Popover";
import { CalendarDays } from "lucide-react";
import { Check } from "lucide-react";
import { cn } from "utils/misc";

export const DateFilter = ({
  setAnimalHistoryQueryVariables,
  animalHistoryQueryVariables,
}: {
  animalHistoryQueryVariables: AnimalHistoryQueryVariables;
  setAnimalHistoryQueryVariables: React.Dispatch<React.SetStateAction<AnimalHistoryQueryVariables>>;
}) => {
  const [daysAgoFilter, setDaysAgoFilter] = useState<7 | 14 | 30 | 90 | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleFilter = (days: 7 | 14 | 30 | 90, isSelected: boolean) => {
    setDaysAgoFilter(isSelected ? undefined : days);
    setAnimalHistoryQueryVariables({
      ...animalHistoryQueryVariables,
      afterDatetime: isSelected ? undefined : DateTime.local().minus({ days }),
    });
  };

  return (
    <Popover onOpenChange={setIsOpen}>
      <Button variant="outline" size="md" className="px-4" asChild>
        <PopoverTrigger>
          <CalendarDays size={16} className="mr-2" />
          <span className="">{daysAgoFilter ? `Last ${daysAgoFilter} days` : "All"}</span>
          <Icon className={`ml-2 ${isOpen ? "rotate-90" : "-rotate-90"}`} name="chevron" size="tiny" />
        </PopoverTrigger>
      </Button>
      <PopoverContent>
        <div className="grid">
          {([7, 14, 30, 90] as const).map((days) => {
            const isSelected = days === daysAgoFilter;

            return (
              <button
                className={cn(
                  "text-left px-3 text-sm h-11",
                  isSelected ? "bg-gray-100" : "bg-white",
                  days === 7 ? "rounded-t-md" : "",
                  days === 90 ? "rounded-b-md" : "",
                )}
                key={days}
                onClick={() => handleToggleFilter(days, isSelected)}
              >
                Last {days} days
                {isSelected ? <Check color="green" strokeWidth="3" className="float-right" /> : null}
              </button>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};
