import { FC, useState, useContext, useEffect } from "react";
import { CommonContext } from "config/commonProvider";
import { useGetCurrentBusinessUnit, useHasFeature, useStateSpecies } from "hooks";
import { Modal, Text } from "components";
import { TagSelect } from "components/Common/TagSelect";
import { MergeAnimalTagsInput, useGetAnimalsWithTagsLazyQuery, useMergeAnimalTagsMutation } from "generated/graphql";
import { ANIMAL_TAGS } from "tags/api/queries";
import { GET_MY_ANIMALS_LIST } from "api/MyLivestock/Animal/queries";
import { removeNothings } from "helpers/general";
import { AnimalsWithTags } from "tags/types";
import { animalsWithoutTags } from "tags/helpers";
import { DocumentNode } from "graphql";
import { OperationVariables } from "@apollo/client";
import { useTags } from "tags/hooks/useTagsHook";
import styles from "./styles.module.scss";

interface AddSelectedAnimalsToGroupProps {
  animalIds: string[];
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export const AddSelectedAnimalsToTag: FC<AddSelectedAnimalsToGroupProps> = ({ setShowModal, showModal, animalIds }) => {
  const [mergeAnimalTags, { loading }] = useMergeAnimalTagsMutation();
  const { id } = useGetCurrentBusinessUnit();
  const { activeSpecies } = useStateSpecies();
  const [getAnimals, { data }] = useGetAnimalsWithTagsLazyQuery();
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalsWithTags>([]);
  const isServerPaginatedAnimalsList = useHasFeature("SERVER_SIDE_PAGINATION_FOR_ANIMALS_LIST");
  const { getTagById } = useTags();
  const [isTagRemovable, setIsTagRemovable] = useState<boolean>(true);

  useEffect(() => {
    if (animalIds.length > 0) {
      getAnimals({ variables: { businessUnitId: Number(id), animalIds } });
    }
  }, [animalIds, id, getAnimals]);

  useEffect(() => {
    if (data?.animals?.edges != null) {
      const animals = removeNothings(data?.animals?.edges);
      setSelectedAnimals(animals);
    }
  }, [data]);

  const [tagId, setTagId] = useState<string>("");
  const { showNotification } = useContext(CommonContext);

  const handleShowError = (errorMessage: string): void => {
    showNotification({
      variant: "error",
      message: errorMessage,
    });

    setShowModal(false);
    setTagId("");
  };

  const handleConfirmClick = async (): Promise<void> => {
    const animalIdsToAdd = animalsWithoutTags(selectedAnimals, tagId)?.map((animal) => animal?.node?.id);

    try {
      const input: MergeAnimalTagsInput = {
        animals: animalIdsToAdd,
        tagValues: [{ tag: tagId, isPresent: true }],
      };

      const previousVariables = {
        businessUnitId: id,
        animalTypeIds: activeSpecies?.id ? [Number(activeSpecies?.id)] : undefined,
      };

      const queriesToRefetch: [{ query: DocumentNode; variables: OperationVariables }] = [
        { query: ANIMAL_TAGS, variables: { buId: id } },
      ];
      if (!isServerPaginatedAnimalsList) {
        queriesToRefetch.push({ query: GET_MY_ANIMALS_LIST, variables: { ...previousVariables } });
      }

      await mergeAnimalTags({
        variables: { input },
        refetchQueries: queriesToRefetch,
        update: (_, { data: mergeAnimalTagsData }) => {
          if (mergeAnimalTagsData?.mergeAnimalTags?.errors == null) {
            showNotification({
              message: "Tag added successfully",
            });

            setShowModal(false);
            setTagId("");
          } else {
            const errorMessages = mergeAnimalTagsData?.mergeAnimalTags?.errors.reduce<string[]>(
              (accumulator, error) => {
                if (error?.message) accumulator.push(error.message);
                return accumulator;
              },
              [],
            );

            handleShowError(errorMessages.join("; "));
          }
        },
      });
    } catch (error) {
      handleShowError("Error adding tag");
    }
  };

  const handleSetTags = (tag: string): void => {
    setTagId(tag);
  };

  useEffect(() => {
    const tag = getTagById(tagId);

    if (tag?.node?.schema?.isRemovable === false) {
      setIsTagRemovable(false);
    } else {
      setIsTagRemovable(true);
    }
  }, [tagId, getTagById]);

  return (
    <>
      <Modal
        showCloseButton
        active={showModal}
        handleClose={() => setShowModal(false)}
        title="Add tag"
        subTitle={`Please select a tag for these ${animalIds.length} animals. This will add the tags to all of the selected animals.`}
        actions={{
          secondary: {
            onClick: () => setShowModal(false),
            caption: "Cancel",
            "data-testid": "add-tag-cancel-btn",
          },
          primary: {
            onClick: handleConfirmClick,
            caption: "Confirm",
            requesting: loading,
            disabled: loading || !tagId,
            "data-testid": "add-tag-confirm-btn",
          },
        }}
      >
        <TagSelect value={tagId} animalIds={animalIds} onSelect={handleSetTags} />
        {!isTagRemovable ? (
          <aside className={styles.warning}>
            <Text smallest className={styles.warningText}>
              Caution: Once applied, this tag can only be removed by Breedr.
            </Text>
          </aside>
        ) : null}
      </Modal>
    </>
  );
};
